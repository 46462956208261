/* eslint-disable */

import React from "react";
import backarrow from "../../common/images/back-arrow-front.svg";
import { useHistory, useNavigate } from "react-router-dom";

const SubHeader = (props) => {
  const {
    allowedSection,
    triggerAction,
    stateValues,
    finalPage,
    handleApproval,
  } = props;
  // console.log(props);

  const history = useHistory();
  // const navigate = useNavigate();

  const pathname = history.location.pathname;

  const goBack = () => {
    history.goBack();
  };

  const pageRouting = (path) => {
    if (allowedSection.includes(path.slice(10))) {
      history.push({
        pathname: `/${path}`,
        state: { ...stateValues, allowedSection: allowedSection },
      });
    }
  };

  return (
    <>
      <div className="standfirst">
        <h2 className="color-header">
          <a href={void 0} className="arrow-big" onClick={() => goBack()}>
            {" "}
            <img src={backarrow} />{" "}
          </a>
          Add your vacation <span>package</span>
        </h2>
        <div>
          <button
            href={void 0}
            className="button btn-scoi"
            onClick={() => triggerAction()}
            // disabled={!allFieldsFilled}
          >
            {finalPage ? "Save Draft" : "Save & continue"}
          </button>
          &nbsp;&nbsp;&nbsp;
          {finalPage && (
            <button
              href={void 0}
              className="button btn-scoi"
              onClick={() => handleApproval()}
              // disabled={!allFieldsFilled}
            >
              Submit for approval
            </button>
          )}
        </div>
      </div>
      <div className="tabloid">
        <ul>
          <li
            className={`${pathname?.includes("/general-info") ? "active" : ""}`}
          >
            <a onClick={() => pageRouting("myaccount/general-info")}>
              General information
            </a>
          </li>
          <li
            className={`${pathname?.includes("/add-photos") ? "active" : ""}`}
          >
            <a onClick={() => pageRouting("myaccount/add-photos")}>
              Photos & Videos
            </a>
          </li>
          <li
            className={`${
              pathname?.includes("/itinerary-list") ? "active" : ""
            }`}
          >
            <a onClick={() => pageRouting("myaccount/itinerary-list")}>
              Itinerary
            </a>
          </li>
          <li
            className={`${pathname?.includes("/availability") ? "active" : ""}`}
          >
            <a onClick={() => pageRouting("myaccount/availability")}>
              Availability
            </a>
          </li>
          {/* <li className={`${pathname?.includes("/hours") ? "active" : ""}`}>
            <a onClick={() => pageRouting("myaccount/hours")}>Working hours</a>
          </li> */}
          <li className={`${pathname?.includes("/pricing") ? "active" : ""}`}>
            <a onClick={() => pageRouting("myaccount/pricing")}>Pricing</a>
          </li>
          <li className={`${pathname?.includes("/terms") ? "active" : ""}`}>
            <a onClick={() => pageRouting("myaccount/terms")}>Terms and Conditions</a>
          </li>
          {/* <li className={`${pathname === "/" ? "active" : ""}`}>
            <a>Submit</a>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default SubHeader;
