import { SET_MYSTIFLYCHEAPESTREVALIDATE } from "../actions";

const mystiflycheapestrevalidate = (state = [], action) => {
  switch (action.type) {
    case SET_MYSTIFLYCHEAPESTREVALIDATE:
      return [...action.value];
    default:
      return state;
  }
};

export default mystiflycheapestrevalidate;
