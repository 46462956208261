/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";

import information from "../../common/images/info-black.svg";
import commision from "../../common/images/commision.svg";
import dashboard from "../../common/images/dashboard.svg";
import heartb from "../../common/images/heart-dark.svg";
import actinfo1 from "../../common/images/act-info1.jpg";

import bprofile from "../../common/images/banner-profile.svg";
import bcal from "../../common/images/calender-banner.svg";
import searchlight from "../../common/images/search-light.svg";
import flightright from "../../common/images/material-flight.svg";
import dtime from "../../common/images/depature-time.svg";
import chair from "../../common/images/class-cat.svg";
import stopicon from "../../common/images/stop-icon.svg";
import swift from "../../common/images/swift.jpg";
import roundtick from "../../common/images/rounded-tick-icon.svg";

import logo2 from "../../common/images/scoot-logo.svg";
import logo1 from "../../common/images/singapore-airlines-logo.svg";
import greensmall from "../../common/images/green-tick-small.svg";
import wifiicon from "../../common/images/wifi-icon.svg";
import swimicon from "../../common/images/swim-icon.svg";
import tvicon from "../../common/images/tv-icon.svg";
import parkicon from "../../common/images/park-icon.svg";
import gymicon from "../../common/images/gym-icon.svg";
import baricon from "../../common/images/bar-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import car from "../../common/images/car-icon.svg";
import ticketact from "../../common/images/ticket-activity.svg";
import hotel from "../../common/images/hotel-icon.svg";
import dotloader from "../../common/images/dot-loader.gif";
import flighttop from "../../common/images/flight-path.svg";
import thingsblue from "../../common/images/things-blue.svg";
import staybed from "../../common/images/stay-bed.svg";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { GET_CUSTOMER_DETAIL } from "../../actions";

class Listpromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerdetail: "",
    };
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "customer_id=" + cookie.load("UserId");
      this.props.getCustomerDetail(params);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
    }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />

        <section className="innersection">
          <div className="container">
            <div className="booking-screen">
              <div className="booking-gif">
                <img src={dotloader} alt="dotloader" />
              </div>

              <div className="booking-tpt textcenter">
                <h2 className="color-header">
                  Now we are processing your <span>flight!</span>
                </h2>
                <p>Please don't refresh or go back.</p>
              </div>

              <div className="booking-steps">
                <ul>
                  <li>
                    <div className="left-ic flight-ic">
                      <img src={flighttop} alt="flighttop" />
                    </div>
                    Flight
                    <div className="load-success">
                      <span className="normal-loader"></span>
                      <img className="done-img" src={greensmall} alt="greentick" />
                    </div>
                  </li>
                  <li>
                    <div className="left-ic transfer-ic">
                      <img src={car} alt="car" />
                    </div>
                    Transfer
                    <div className="load-success">
                      <span className="normal-loader"></span>
                      <img className="done-img" src={greensmall} alt="greentick" />
                    </div>
                  </li>
                  <li>
                    <div className="left-ic stay-ic">
                      <img src={staybed} alt="staybed" />
                    </div>
                    Stay
                    <div className="load-success success">
                      <span className="normal-loader"></span>
                      <img className="done-img" src={greensmall} alt="greentick" />
                    </div>
                  </li>
                  <li>
                    <div className="left-ic things-ic">
                      <img src={thingsblue} alt="greentick" />
                    </div>
                    Things to do
                    <div className="load-success success">
                      <span className="normal-loader"></span>
                      <img className="done-img" src={greensmall} alt="greentick" />
                    </div>
                  </li>
                </ul>
              </div>

              <div className="flight-step1">
                <div className="main-step-inner flight-step-inner">
                  <div className="flight-step-lhs step-inner-lhs">
                    <ul className="fsl-top">
                      <li>
                        <div className="fsl-logo">
                          <img src={logo1} alt="singapore-airlines" />
                          <span>Singapore airlines</span>
                        </div>
                        <div className="fsl-path">
                          <div className="fsl-img">
                            <img src={flightright} alt="flightright" />
                          </div>
                          <div className="fsl-direct">Direct</div>
                          <div className="fsl-time">1h 40m</div>
                          <div className="fsl-from">
                            19:05 <br />
                            SIN
                          </div>
                          <div className="fsl-to">
                            20:15
                            <br />
                            CDG
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul className="fsl-btm">
                      <li>
                        <div className="fsl-logo">
                          <img src={logo2} alt="scoot-logo" />
                          <span>Scoot</span>
                        </div>
                        <div className="fsl-path">
                          <div className="fsl-img">
                            <img src={flightright} alt="flightright" />
                          </div>
                          <div className="fsl-direct">Direct</div>
                          <div className="fsl-time">1h 40m</div>
                          <div className="fsl-from">
                            19:05 <br />
                            SIN
                          </div>
                          <div className="fsl-to">
                            20:15
                            <br />
                            CDG
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="flight-step-rhs step-inner-rhs">
                    <h4>Flight details</h4>
                    <ul>
                      <li>
                        <strong>PNR</strong>
                        <span>QT459F9</span>
                      </li>
                      <li>
                        <strong>Passenger</strong>
                        <span>2x Adult</span>
                      </li>
                      <li>
                        <strong>Booking status</strong>
                        <span className="green-color">Confirmed!</span>
                      </li>
                    </ul>
                    <p>
                      <img src={information} alt="" />
                      Please check the full details before departure.
                    </p>
                  </div>
                </div>

                <div className="step-success-msg color-header">
                  Your flight tickets are booked <span> successfully</span>{" "}
                  <img src={greensmall} alt="" />
                </div>
              </div>

              <div className="flight-step2">
                <div className="main-step-inner transport-step-inner">
                  <div className="hsr-lhs step-inner-lhs">
                    <div className="hsr-lhs-img textcenter">
                      <img src={swift} alt="" />
                    </div>
                    <div className="hsr-lhs-desc">
                      <h4>
                        Changi airport to stay to Changi airport 1 x Private
                      </h4>
                      <ul className="mainpoints">
                        <li>
                          <img src={roundtick} alt="" />
                          Estimated journey 22 min
                        </li>
                        <li>
                          <img src={roundtick} alt="" />1 - 3 passengers
                        </li>
                        <li>
                          <img src={roundtick} alt="" />2 suitcases & 2 Small
                          bag per vehicle
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="transport-step-rhs step-inner-rhs">
                    <h4>Transfer details</h4>
                    <ul>
                      <li>
                        <strong>Ref.</strong>
                        <span>WE45YT3</span>
                      </li>
                      <li>
                        <strong>Passenger</strong>
                        <span>2x Adult</span>
                      </li>
                      <li>
                        <strong>Booking status</strong>
                        <span className="green-color">Confirmed!</span>
                      </li>
                    </ul>
                    <p>
                      <img src={information} alt="" />
                      Please check the full details before departure.
                    </p>
                  </div>
                </div>

                <div className="step-success-msg color-header">
                  Your transfer was booked <span> successfully</span>{" "}
                  <img src={greensmall} alt="" />
                </div>
              </div>

              <div className="flight-step3">
                <div className="main-step-inner stay-step-inner">
                  <div className="hsr-lhs step-inner-lhs">
                    <div className="hsr-lhs-img">
                      <img src={actinfo1} alt="" />
                    </div>
                    <div className="hsr-lhs-desc">
                      <div className="star-rating-review">
                        <div className="star-rating">
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-half-o" aria-hidden="true"></i>
                        </div>
                        <div className="sr-review">
                          <strong>4.0</strong>
                          <span>152 Reviews</span>
                        </div>
                      </div>
                      <h4>Plush Penthouse With Private Plunge Pool</h4>
                      <span>Nerul, Goa</span>
                      <ul>
                        <li>
                          <img src={wifiicon} alt="" />
                        </li>
                        <li>
                          <img src={swimicon} alt="" />
                        </li>
                        <li>
                          <img src={tvicon} alt="" />
                        </li>
                        <li>
                          <img src={parkicon} alt="" />
                        </li>
                        <li>
                          <img src={gymicon} alt="" />
                        </li>
                        <li>
                          <img src={baricon} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="stay-step-rhs step-inner-rhs">
                    <h4>Stay details</h4>
                    <ul>
                      <li>
                        <strong>Ref.</strong>
                        <span>T198YT37</span>
                      </li>
                      <li>
                        <strong>Guest</strong>
                        <span>2x Adult</span>
                      </li>
                      <li>
                        <strong>Booking status</strong>
                        <span className="green-color">Confirmed!</span>
                      </li>
                    </ul>
                    <p>
                      <img src={information} alt="" />
                      Please check the full details before departure.
                    </p>
                  </div>
                </div>
                <div className="step-success-msg color-header">
                  Your Stay was booked <span> successfully</span>{" "}
                  <img src={greensmall} alt="" />
                </div>
              </div>

              <div className="flight-step4">
                <div className="main-step-inner stay-step-inner">
                  <div className="hsr-lhs step-inner-lhs">
                    <div className="hsr-lhs-img">
                      <img src={actinfo1} alt="" />
                    </div>
                    <div className="hsr-lhs-desc">
                      <div className="star-rating-review">
                        <div className="star-rating">
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-half-o" aria-hidden="true"></i>
                        </div>
                        <div className="sr-review">
                          <strong>4.0</strong>
                        </div>
                      </div>
                      <h4>Universal studios Singapore </h4>
                      <span>Open dated admission</span>
                      <p>Sentosa, Singapore</p>
                      <p>Activity 1- Day 1</p>
                    </div>
                  </div>
                  <div className="stay-step-rhs step-inner-rhs">
                    <h4>Activity details</h4>
                    <ul>
                      <li>
                        <strong>Ref.</strong>
                        <span>T198YT37</span>
                      </li>
                      <li>
                        <strong>Travelers</strong>
                        <span>2x Adult</span>
                      </li>
                      <li>
                        <strong>Booking status</strong>
                        <span className="green-color">Confirmed!</span>
                      </li>
                    </ul>
                    <p>
                      <img src={information} alt="" /> Please check the full
                      details before departure.
                    </p>
                  </div>
                </div>
                <div className="step-success-msg color-header">
                  Your things to do tickets are booked{" "}
                  <span> successfully</span> <img src={greensmall} alt="" />
                </div>
              </div>

              <div className="booked-ticket">
                <div className="booked-ticket-inner">
                  <div className="booked-ticket-top">
                    <div className="btt-lhs">
                      <h5>Explore fantastic Singapore for couples</h5>
                      <div className="sites-content">
                        <ul>
                          <li className="sc1">
                            <figure>
                              <img src={flight} alt="flight" />
                            </figure>
                            <figcaption>2 flights</figcaption>
                          </li>
                          <li className="sc2">
                            <figure>
                              <img src={hotel} alt="" />
                            </figure>
                            <figcaption>4 hotels</figcaption>
                          </li>
                          <li className="sc3">
                            <figure>
                              <img src={ticketact} alt="" />
                            </figure>
                            <figcaption>9 activities</figcaption>
                          </li>
                          <li className="sc4">
                            <figure>
                              <img src={car} alt="" />
                            </figure>
                            <figcaption>2 transport</figcaption>
                          </li>
                        </ul>
                      </div>
                      <div className="tickt-content">
                        <ul>
                          <li>
                            <span>Booking ref ID </span>
                            <strong>QJS395688</strong>
                          </li>
                          <li>
                            <span>Payment ref ID</span>
                            <strong>MDF7656458</strong>
                          </li>
                          <li>
                            <span>No of travelers</span>
                            <strong>2 x adult, 1 x child</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="btt-rhs">
                      <img src={actinfo1} alt="" />
                    </div>
                  </div>
                  <div className="booked-ticket-btm">
                    <div className="booked-ticket-btm-lhs">
                      <span>Total paid</span>
                      <p>
                        SGD <strong>$1029.23</strong>
                      </p>
                    </div>
                    <div className="booked-ticket-btm-rhs">
                      <a href="#" className="button">
                        Download ticket
                      </a>
                      <a href="#" className="button ghost-button">
                        Manage booking
                      </a>
                    </div>
                  </div>
                </div>
                <div className="booked-ticket-info">
                  <img src={information} alt="" />
                  You can download the ticket from your given email. Present the
                  QR code/bar code /booking reference number at the time of
                  entering the attraction/activities premises. For more
                  information download the ticket and check terms and
                  conditions.
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
