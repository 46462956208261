/* eslint-disable */

import React from "react";
import validator from "validator";
import { validated } from "react-custom-validation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import cookie from "react-cookies";

const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";
const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (phone) =>
  phone.match(phonenumberPattern) &&
  phone !== null &&
  typeof phone !== undefined &&
  typeof phone !== "undefined"
    ? null
    : "This is not a valid phone number.";

function validationConfig(props) {
  const {
    customer_name,
    lastname,
    email,
    phone,
    birthdate,
    phone_country,
    postal,
  } = props.fields;

  return {
    fields: [
      "customer_name",
      "lastname",
      "email",
      "phone",
      "phone_country",
      "birthdate",
    ],

    validations: {
      customer_name: [[isEmpty, customer_name]],
      phone: [
        [isEmpty, phone],
        /* [isMobile, phone], */
      ],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
      birthdate: [[isEmpty, birthdate]],
    },
  };
}

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "Loading",
      changeEamil: true,
      changePhone: true,
      countryCode: this.props.fields.countryCode,
    };
  }
  allowChangeedit(field) {
    if (field === "email") {
      this.setState({ changeEamil: !this.state.changeEamil });
    } else if (field === "phone") {
      this.setState({ changePhone: !this.state.changePhone });
    }
  }
  setPhone(phone, data) {
    this.props.onChange("phone", phone);
    console.log(data, "datadatadata");
    this.setState({ countryCode: data.countryCode });
    // $("#profilecountryCode").val(data.countryCode);
  }

  render() {
    const {
      fields,
      activityPoints,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgCustomername, errMsgEmail, errMsgPhone;

    if ($validation.customer_name.error.reason !== undefined) {
      errMsgCustomername = $validation.customer_name.show && (
        <div className="error">{$validation.customer_name.error.reason}</div>
      );
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <div className="error">{$validation.email.error.reason}</div>
      );
    }
    if ($validation.phone.error.reason !== undefined) {
      errMsgPhone = $validation.phone.show && (
        <div className="error">{$validation.phone.error.reason}</div>
      );
    }
    if (typeof this.props.customerdetail !== undefined) {
      return (
        <div className="cp-form">
          <div className="cp-form-inner">
            <div className="form-group">
              <label className="control-label">Name</label>
              <input
                type="text"
                placeholder="Enter your full name"
                value={fields.customer_name || ""}
                {...$field("customer_name", (e) =>
                  onChange("customer_name", e.target.value)
                )}
              />
              {errMsgCustomername}
            </div>
            <div className="form-group pwd-group">
              <label className="control-label">E-mail ID</label>
              <div className="change-group">
                <input
                  type="text"
                  placeholder="Enter your full name"
                  readOnly={this.state.changeEamil}
                  value={fields.email || ""}
                  {...$field("email", (e) => onChange("email", e.target.value))}
                />
                {errMsgEmail}
                <a
                  href={void 0}
                  onClick={this.allowChangeedit.bind(this, "email")}
                  className="change-txt"
                >
                  {this.state.changeEamil === true ? "Change" : "Cancel"}
                </a>
              </div>
            </div>
            <div className="form-group pwd-group">
              <label className="control-label">Phone</label>
              <div className="change-group">
                <input
                  type="hidden"
                  id="profilecountryCode"
                  value={this.state.countryCode}
                />
                {this.state.changePhone === true ? (
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    readOnly={this.state.changePhone}
                    value={fields.phone || ""}
                    {...$field("phone", (e) =>
                      onChange("phone", e.target.value)
                    )}
                  />
                ) : (
                  <PhoneInput
                    value={fields.phone}
                    country={
                      fields.phone_country !== "" &&
                      fields.phone_country !== null
                        ? fields.phone_country
                        : "sg"
                    }
                    onChange={(phone, data, event, formattedValue) => {
                      this.setPhone(formattedValue, data);
                    }}
                  />
                )}

                {errMsgPhone}
                <a
                  href={void 0}
                  onClick={this.allowChangeedit.bind(this, "phone")}
                  className="change-txt"
                >
                  {this.state.changePhone === true ? "Change" : "Cancel"}
                </a>
              </div>
            </div>
            <div className="form-group pwd-group">
              <label className="control-label">Gender</label>
              <div className="change-group">
                <Select
                  value={fields.gender}
                  selectMultiple={true}
                  placeholder="Select Genter"
                  onChange={onChange.bind(this, "gender")}
                  options={[
                    { value: "M", label: "Male" },
                    { value: "F", label: "Female" },
                    { value: "O", label: "Others" },
                  ]}
                />
              </div>
            </div>
            <div className="form-button no-padd-btm">
              <button
                type="button"
                className="myaccount_update button"
                onClick={(e) => {
                  e.preventDefault();
                  this.allowChangeedit("phone");
                  this.props.$submit(onValid, onInvalid);
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return <div id="dvLoading"></div>;
    }
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  var secondaryArr = Array();

  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
Form.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default connect(mapStateToProps, mapDispatchToProps)(Form);
