/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "recharts";
import blueplus from "../../common/images/plus.svg";
import umberla from "../../common/images/umberrla.svg";
import cookie from "react-cookies";

import { GET_CUSTOMER_DETAIL } from "../../actions";

class Listpromo extends Component {
  constructor(props) {
    super(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }

    this.state = {
      CUserId: CUserId,
      isLoading: true,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  handlePath = (type) => {
    this.props.history.push({
      pathname: "/myaccount/general-info",
      state: { productType: type },
    });
  };

  render() {
    return (
      <>
        {this.state.isLoading === false ? (
          <>
            <section className="main-blue-bg">
              <div className="container-full">
                <div className="creator-board">
                  <div className="creator-board-rhs">
                    <div className="title-with-description">
                      <h2>
                        Add <span>products & services</span>
                      </h2>
                      <p>Distribute and manage your products & services</p>
                    </div>
                    <div className="pro-ser-list">
                      <ul>
                        <li>
                          <a onClick={() => this.handlePath("normal")}>
                            <figure className="glyphum">
                              <img src={umberla} />{" "}
                            </figure>
                            <div className="dek">
                              <h4>
                                Vacation <br />
                                packages
                              </h4>
                              <figure className="glyp-plus">
                                <img src={blueplus} />{" "}
                              </figure>
                            </div>
                          </a>
                        </li>
                        {/* <li>
                          <a onClick={() => this.handlePath("dayTour")}>
                            <figure className="glyphum">
                              <img src={umberla} />{" "}
                            </figure>
                            <div className="dek">
                              <h4>Day Tour</h4>
                              <figure className="glyp-plus">
                                <img src={blueplus} />{" "}
                              </figure>
                            </div>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
