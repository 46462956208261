/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import pplaceholder from "../../common/images/product-noimage.jpg";
import tvicon from "../../common/images/tv-icon.svg";
import parkicon from "../../common/images/park-icon.svg";
import baricon from "../../common/images/bar-icon.svg";
import wpen from "../../common/images/white-pen.svg";
import { apiUrl, apiglobaltixMediaUrl, fizoheader } from "../Helpers/Config";
import { showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Topmenu from "../Layout/Topmenu";
var qs = require("qs");
class Packagestep extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    var updateActivity = [];
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      if (
        this.props.location.state.updateActivity !== "" &&
        typeof this.props.location.state.updateActivity !== undefined &&
        typeof this.props.location.state.updateActivity !== "undefined"
      ) {
        updateActivity = this.props.location.state.updateActivity;
      }
    }

    var packageID = "";
    if (
      this.props.match.params.packageID !== "" &&
      typeof this.props.match.params.packageID !== undefined &&
      typeof this.props.match.params.packageID !== "undefined"
    ) {
      packageID = this.props.match.params.packageID;
    } else {
      this.props.history.push("/");
    }

    var newPackage = true;
    if (
      this.props.location.state.newPackage !== "" &&
      typeof this.props.location.state.newPackage !== undefined &&
      typeof this.props.location.state.newPackage !== "undefined"
    ) {
      newPackage = this.props.location.state.newPackage;
    }
    this.state = {
      CUserId: CUserId,
      packageID: packageID,
      updateActivity: updateActivity,
      packageDetails: [],
      included: "",
      activities: updateActivity,
      activeTab: [1],
      hotels: [],
      removeday: "",
      removedayIndex: "",
      newPackage: newPackage,
    };
    this.loadPackageDetails();
  }
  loadPackageDetails() {
    if (this.state.packageID !== "") {
      axios
        .get(
          apiUrl +
            "package/packageInfo?package_id=" +
            this.state.packageID +
            "&creator_id=" +
            this.state.CUserId,
          fizoheader
        )
        .then((res) => {
          if (res.data.status === "ok") {
            var result = res.data.result_set;
            var packages = result.package;
            var activities =
              this.state.activities.length > 0
                ? this.state.activities
                : result.activities;
            var hotels = result.stay;
            var included = packages.creator_package_included.split(",");
            // var updated = this.state.activities.flatMap((item) =>
            //   item.activities.map((item) => item.updated)
            // );
            this.setState({
              packageDetails: packages,
              included: included,
              activities: activities,
              hotels: hotels,
            });
          }
        });
    }
  }

  loadRatingstar(points) {
    if (
      points !== "" &&
      points !== null &&
      typeof points !== undefined &&
      typeof points !== "undefined"
    ) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        }
      });
    }
  }
  openTab(day) {
    var activeTab = this.state.activeTab;
    if (activeTab.indexOf(day) >= 0) {
      activeTab.splice(activeTab.indexOf(day));
    } else {
      activeTab.push(day);
    }
    this.setState({ activeTab: activeTab });
  }
  sendApproval() {
    if (this.state.packageDetails.creator_package_approval === "0") {
      showLoader("submitapproval", "class");
      var postObject = {
        package_id: this.state.packageID,
        creator_id: this.state.CUserId,
      };

      axios
        .post(
          apiUrl + "package/sendtoapproval",
          qs.stringify(postObject),
          fizoheader
        )
        .then((res) => {
          hideLoader("submitapproval", "class");
          if (res.data.status === "ok") {
            showAlert(
              "Success",
              "Your package has been submitted successfully. It will take upto 24hrs for the package to show."
            );
            if (this.state.newPackage === true) {
              this.updateItineraries();
            }

            // this.loadPackageDetails();
            this.props.history.push({
              pathname: "/myaccount/creator-myproducts",
            });
          } else {
            showAlert("Error", res.data.form_error);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });
    }
  }
  goToStay(e) {
    e.preventDefault();
    let packageDetails = this.props?.location?.state || {};
    packageDetails["webbeds_city_code"] =
      this.state.packageDetails.webbeds_city_code;

    this.props.history.push({
      pathname:
        "/creator-stay/" +
        this.state.packageDetails.country_name.toLowerCase() +
        "/" +
        this.state.packageDetails.creator_package_city.toLowerCase() +
        "/" +
        this.state.packageID,
      state: packageDetails,
    });
  }

  gotoActivity(day, activityindex) {
    let packageDetails = this.props?.location?.state || {};
    packageDetails["existActivity"] = this.state.activities;

    this.props.history.push({
      pathname:
        "/activities/" +
        this.state.packageDetails.country_name.toLowerCase() +
        "/" +
        this.state.packageDetails.creator_package_city.toLowerCase() +
        "/" +
        this.state.packageID +
        "/day" +
        day +
        "/" +
        activityindex,
      state: packageDetails,
    });
  }

  updateItineraries() {
    var updatedItinerary = [];
    if (this.state.activities.length > 0) {
      this.state.activities.map((item) => {
        var activities = [];
        if (item.activities.length > 0) {
          item.activities.map((activity) => {
            if (activity !== "") {
              activities.push({
                productID: activity.activity_activity_product,
                packageID: activity.activity_activity_package_id,
                day_type: activity.creator_activity_day_type,
              });
            } else {
              activities.push("");
            }
          });
        }
        updatedItinerary.push({ day: item.day, activities: activities });
      });
    }
    if (updatedItinerary.length > 0) {
      showLoader("update_itineraries", "class");
      var postObject = {
        package_id: this.state.packageID,
        creator_id: this.state.CUserId,
        activities: JSON.stringify(updatedItinerary),
      };
      axios
        .post(
          apiUrl + "package/updateItineraries",
          qs.stringify(postObject),
          fizoheader
        )
        .then((res) => {
          if (!this.state.newPackage) {
            hideLoader("update_itineraries", "class");
            if (res.data.status === "ok") {
              showAlert("Success", res.data.message);
              this.props.history.push({
                pathname: "/myaccount/creator-myproducts",
              });
            } else {
              showAlert("Error", res.data.form_error);
            }
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        });
    }
  }
  removeActivityPopup(day, dayIndex) {
    this.setState({ removeday: day, removedayIndex: dayIndex });
    $.magnificPopup.open({
      items: {
        src: "#remove_confirm_popup",
      },
      type: "inline",
    });
  }
  handleDeleteActivity = () => {
    var updatedItinerary = [];
    if (this.state.activities.length > 0) {
      this.state.activities.map((item) => {
        if (this.state.removeday === item.day) {
          var activities = [];
          if (item.activities.length > 0) {
            item.activities.map((activity, index) => {
              if (index === this.state.removedayIndex) {
                activities.push("");
              } else {
                activities.push(activity);
              }
            });
          }
          updatedItinerary.push({ day: item.day, activities: activities });
        } else {
          updatedItinerary.push(item);
        }
      });
    }
    this.setState(
      {
        activities: updatedItinerary,
      },
      function () {
        $.magnificPopup.close();
      }
    );
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div>
          <section className="main-blue-bg">
            <div className="container-full">
              <div className="creator-board">
                <div className="creator-board-lhs">
                  <Topmenu {...this.props} currentpage="package" />
                </div>
                <div className="creator-board-rhs cx-box">
                  {Object.keys(this.state.packageDetails).length > 0 ? (
                    <section className="innersection">
                      <div className="container">
                        <div className="title-with-nav">
                          <div className="title-with-description">
                            <h2>
                              Create unique<span> packages</span>
                            </h2>
                            <p>Itineraries of the package</p>
                          </div>
                          <div className="pt-button">
                            {(this.state.packageDetails
                              .creator_package_approval === "0" ||
                              this.state.packageDetails
                                .creator_package_approval === "1") && (
                              <a
                                href={void 0}
                                className={
                                  this.state.packageDetails
                                    .creator_package_approval === "1"
                                    ? "button submitapproval disable"
                                    : "button submitapproval"
                                }
                                onClick={this.sendApproval.bind(this)}
                              >
                                {this.state.packageDetails
                                  .creator_package_approval === "0"
                                  ? "Submit for apporval"
                                  : "Waiting for apporval"}
                              </a>
                            )}
                          </div>
                          {!this.state.newPackage && (
                            <a
                              href={void 0}
                              onClick={this.updateItineraries.bind(this)}
                              className="button update_itineraries"
                            >
                              Update
                            </a>
                          )}
                        </div>

                        <div className="unique-package-list">
                          <div className="sample-edit ">
                            <div className="sample-edit-lhs">
                              <h4>
                                {this.state.packageDetails.creator_package_name}
                              </h4>
                              <p>
                                {
                                  this.state.packageDetails
                                    .creator_package_total_days
                                }
                                D |{" "}
                                {this.state.packageDetails.creator_package_city}
                              </p>
                              <span>
                                {
                                  this.state.packageDetails
                                    .creator_package_travelling
                                }
                              </span>

                              <Link
                                to={"/edit-package/" + this.state.packageID}
                                className="button"
                              >
                                <img src={wpen} alt="bar" />
                                Edit
                              </Link>
                            </div>
                          </div>

                          <div className="unique-package-stay">
                            <div className="hotel-search-result">
                              {this.state.included.indexOf("Stay") >= 0 && (
                                <>
                                  <div className="flag-stay">
                                    <span>Stay</span>
                                  </div>
                                  {this.state.hotels.length > 0 ? (
                                    <ul>
                                      {this.state.hotels.map((item, index) => {
                                        var hotel_amenities =
                                          item.hotel_amenities
                                            ? item.hotel_amenities.split("#@#")
                                            : [];
                                        return (
                                          <li key={index}>
                                            <div className="hsr-lhs">
                                              <div className="hsr-lhs-img">
                                                <img
                                                  src={
                                                    item.thumbnail_image !==
                                                      "" &&
                                                    item.thumbnail_image !==
                                                      null
                                                      ? item.thumbnail_image
                                                      : pplaceholder
                                                  }
                                                  alt={item.hotel_name}
                                                />
                                              </div>
                                              <div className="hsr-lhs-desc">
                                                <div className="star-rating-review">
                                                  <div className="star-rating">
                                                    {this.loadRatingstar("2.5")}
                                                  </div>
                                                  <div className="sr-review">
                                                    <strong>4.0</strong>
                                                    <span>
                                                      {item.rating} Reviews
                                                    </span>
                                                  </div>
                                                </div>
                                                <h4>{item.hotel_name}</h4>
                                                <span>{item.address}</span>
                                                <ul>
                                                  {hotel_amenities
                                                    .map((amenity) => {
                                                      let icon = null;
                                                      let icon_limit = 0;
                                                      switch (amenity) {
                                                        case "High Speed Internet":
                                                          icon = (
                                                            <img
                                                              src={tvicon}
                                                              alt="wifi"
                                                            />
                                                          );
                                                          break;
                                                        case "Car Parking - Onsite Paid":
                                                        case "Car Parking - Onsite Free":
                                                          icon = (
                                                            <img
                                                              src={parkicon}
                                                              alt="park"
                                                            />
                                                          );
                                                          break;
                                                        case "Bar":
                                                          icon = (
                                                            <img
                                                              src={baricon}
                                                              alt="bar"
                                                            />
                                                          );
                                                          break;
                                                        default:
                                                          break;
                                                      }
                                                      return icon;
                                                    })
                                                    .filter(
                                                      (icon, index) => index < 8
                                                    )
                                                    .map(
                                                      (icon, index) =>
                                                        icon && (
                                                          <li key={index}>
                                                            {icon}
                                                          </li>
                                                        )
                                                    )}
                                                </ul>
                                                {/* <div className="free-box">
                                        Free Cancellation till check-in
                                      </div> */}
                                              </div>
                                            </div>
                                            <div className="hsr-rhs">
                                              <a
                                                href={void 0}
                                                onClick={this.goToStay.bind(
                                                  this
                                                )}
                                                className="button"
                                              >
                                                Change
                                              </a>
                                            </div>
                                            <div className="stp-info">
                                              Stay for the package
                                            </div>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    <div className="add-new-box">
                                      <div className="add-new-box-lhs">
                                        <p>
                                          Add stay to your <span>package</span>
                                        </p>
                                      </div>
                                      <div className="add-new-box-rhs">
                                        <a
                                          href={void 0}
                                          className="button"
                                          onClick={this.goToStay.bind(this)}
                                        >
                                          Add Stay
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          {this.state.included.indexOf("Things to do") >= 0 && (
                            <div className="unique-package-thing three-activity">
                              <div className="flag-stay">
                                <span>Things to do</span>
                              </div>
                              <div className="up-accordion">
                                {this.state.activities.length > 0 &&
                                  this.state.activities.map((item, index) => {
                                    return (
                                      <div
                                        className={
                                          this.state.activeTab.indexOf(
                                            parseInt(item.day)
                                          ) >= 0
                                            ? "up-accordion-parent active"
                                            : "up-accordion-parent"
                                        }
                                        key={index}
                                      >
                                        <div
                                          className="up-accordion-header"
                                          onClick={this.openTab.bind(
                                            this,
                                            item.day
                                          )}
                                        >
                                          Things to do for Day {item.day}
                                        </div>
                                        {item.activities.length > 0 ? (
                                          <div className="up-accordion-content">
                                            <div className="stp-info">
                                              Transfer for the package
                                            </div>
                                            <div className="days-text">
                                              Day <span> {item.day}</span>
                                            </div>
                                            <ul className="package-card-row">
                                              {item.activities.length > 0 &&
                                                item.activities.map(
                                                  (
                                                    activityitem,
                                                    activityindex
                                                  ) => {
                                                    var dayType = "";
                                                    if (activityindex === 0) {
                                                      dayType = "Morning";
                                                    } else if (
                                                      activityindex === 1
                                                    ) {
                                                      dayType = "Afternoon";
                                                    } else if (
                                                      activityindex === 2
                                                    ) {
                                                      dayType = "Evening";
                                                    }
                                                    if (activityitem !== "") {
                                                      return (
                                                        <li key={activityindex}>
                                                          <div className="hsr-lhs">
                                                            <div className="hsr-lhs-img">
                                                              <img
                                                                src={
                                                                  activityitem.product_thumbnail !==
                                                                    "" &&
                                                                  activityitem.product_thumbnail !==
                                                                    null
                                                                    ? apiglobaltixMediaUrl +
                                                                      activityitem.product_thumbnail
                                                                    : pplaceholder
                                                                }
                                                                alt=""
                                                              />
                                                              <div className="time-tag">
                                                                {dayType}
                                                              </div>
                                                            </div>
                                                            <div className="hsr-lhs-desc">
                                                              <h5>
                                                                {
                                                                  activityitem.products_name
                                                                }
                                                              </h5>
                                                              {activityitem.product_category_name !==
                                                                "" &&
                                                                activityitem.product_category_name !==
                                                                  null && (
                                                                  <div className="product-description-tag">
                                                                    <span>
                                                                      {" "}
                                                                      {
                                                                        activityitem.product_category_name
                                                                      }{" "}
                                                                    </span>
                                                                  </div>
                                                                )}
                                                            </div>
                                                          </div>
                                                          <div className="hsr-rhs">
                                                            <a
                                                              className="button ghost-button change-btn"
                                                              onClick={this.gotoActivity.bind(
                                                                this,
                                                                item.day,
                                                                activityindex
                                                              )}
                                                            >
                                                              Change
                                                            </a>
                                                            <a
                                                              onClick={this.removeActivityPopup.bind(
                                                                this,
                                                                item.day,
                                                                activityindex
                                                              )}
                                                              className="button ghost-button remove-btn"
                                                            >
                                                              Remove
                                                            </a>
                                                          </div>
                                                        </li>
                                                        // <li key={activityindex}>
                                                        //   <div className="product-list-parent">
                                                        //     <div className="product-img">
                                                        //       <a className="pt-img">
                                                        //         <img
                                                        //           src={
                                                        //             activityitem.product_thumbnail !==
                                                        //               "" &&
                                                        //             activityitem.product_thumbnail !==
                                                        //               null
                                                        //               ? apiglobaltixMediaUrl +
                                                        //                 activityitem.product_thumbnail
                                                        //               : pplaceholder
                                                        //           }
                                                        //           alt=""
                                                        //         />
                                                        //       </a>
                                                        //       <div className="time-tag">
                                                        //         {dayType}
                                                        //       </div>
                                                        //     </div>
                                                        //     <div className="product-description">
                                                        //       <div className="product-description-title">
                                                        //         <h5>
                                                        //           <a href="#">
                                                        //             {
                                                        //               activityitem.products_name
                                                        //             }
                                                        //           </a>{" "}
                                                        //         </h5>
                                                        //       </div>
                                                        //       {activityitem.product_category_name !==
                                                        //         "" &&
                                                        //         activityitem.product_category_name !==
                                                        //           null && (
                                                        //           <div className="product-description-tag">
                                                        //             <span>
                                                        //               {
                                                        //                 activityitem.product_category_name
                                                        //               }
                                                        //             </span>
                                                        //           </div>
                                                        //         )}

                                                        //       <div className="product-description-review">
                                                        //         <div className="pdr-lhs">
                                                        //           {activityitem.product_rating !==
                                                        //             "" &&
                                                        //             activityitem.product_rating !==
                                                        //               null && (
                                                        //               <span>
                                                        //                 <strong>
                                                        //                   {
                                                        //                     activityitem.product_rating
                                                        //                   }
                                                        //                 </strong>{" "}
                                                        //                 (
                                                        //                 {
                                                        //                   activityitem.product_total_review
                                                        //                 }
                                                        //                 ) Reviews
                                                        //               </span>
                                                        //             )}
                                                        //           <div className="star-rating">
                                                        //             {this.loadRatingstar(
                                                        //               activityitem.product_rating
                                                        //             )}
                                                        //           </div>
                                                        //           <a
                                                        //             onClick={this.gotoActivity.bind(
                                                        //               this,
                                                        //               item.day,
                                                        //               activityindex
                                                        //             )}
                                                        //           >
                                                        //             Change
                                                        //           </a>
                                                        //           <a
                                                        //             onClick={this.removeActivityPopup.bind(
                                                        //               this,
                                                        //               item.day,
                                                        //               activityindex
                                                        //             )}
                                                        //           >
                                                        //             Remove
                                                        //           </a>
                                                        //         </div>
                                                        //       </div>
                                                        //     </div>
                                                        //   </div>
                                                        // </li>
                                                      );
                                                    } else {
                                                      return (
                                                        <li
                                                          key={activityindex}
                                                          className="add-pk"
                                                          onClick={this.gotoActivity.bind(
                                                            this,
                                                            item.day,
                                                            activityindex
                                                          )}
                                                        >
                                                          <div className="time-tag">
                                                            {dayType}
                                                          </div>
                                                          <AddCircleOutlineIcon htmlColor="#4258BF" />
                                                          Add Things to do
                                                        </li>
                                                      );
                                                    }
                                                  }
                                                )}
                                              {/* <div className="activities-add-btn">
                                      <Link
                                        to={
                                          "/activities/" +
                                          this.state.packageDetails.country_name.toLowerCase() +
                                          "/" +
                                          this.state.packageDetails.creator_package_city.toLowerCase() +
                                          "/" +
                                          this.state.packageID +
                                          "/day" +
                                          "/" +
                                          item.day
                                        }
                                        className="button"
                                      >
                                        {item.activities.length > 0
                                          ? "Add"
                                          : "Add New"}
                                      </Link>
                                    </div> */}
                                            </ul>
                                          </div>
                                        ) : (
                                          <div className="up-accordion-content">
                                            <div className="add-new-box">
                                              <div className="add-new-box-lhs">
                                                <p>
                                                  Add Things to do for your{" "}
                                                  <span>package</span>
                                                </p>
                                              </div>
                                              <div className="add-new-box-rhs">
                                                <Link
                                                  to={
                                                    "/activities/" +
                                                    this.state.packageDetails.country_name.toLowerCase() +
                                                    "/" +
                                                    this.state.packageDetails.creator_package_city.toLowerCase() +
                                                    "/" +
                                                    this.state.packageID +
                                                    "/day" +
                                                    item.day
                                                  }
                                                  className="button"
                                                >
                                                  Add Things to do
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>
                  ) : (
                    <div id="dvLoading">
                      <div className="loader triangle">
                        <svg viewBox="0 0 100 100">
                          <circle
                            cx="50"
                            cy="50"
                            r="40"
                            stroke="black"
                            strokeWidth="3"
                            fill="transparent"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                  <div
                    id="remove_confirm_popup"
                    className="mfp-hide common-popup"
                  >
                    <div className="custom_alert">
                      <div className="custom_alertin">
                        <div className="alert_height">
                          <div className="alert_body">
                            <h2 className="text-uppercase">Warning</h2>
                            <p>Are you sure want to Delete</p>
                            <div
                              className="alt_btns"
                              style={{ gap: "10px", display: "inline-flex" }}
                            >
                              <a
                                className="button confirm-button"
                                onClick={this.handleDeleteActivity.bind(this)}
                              >
                                Yes
                              </a>
                              <a
                                href={void 0}
                                className="button popup-modal-dismiss disbl_href_action"
                              >
                                No
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

export default Packagestep;
