import { SET_CREATOR_REGISTRATION } from "../actions";

const creatorregistration = (state = [], action) => {
  switch (action.type) {
    case SET_CREATOR_REGISTRATION:
      return [...action.value];
    default:
      return state;
  }
};

export default creatorregistration;
