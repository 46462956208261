import { SET_COUNTRY } from "../actions";

const country = (state = [], action) => {
  switch (action.type) {
    case SET_COUNTRY:
      return [...action.value];
    default:
      return state;
  }
};

export default country;
