/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";

import information from "../../common/images/info-black.svg";
import commision from "../../common/images/commision.svg";
import dashboard from "../../common/images/dashboard.svg";
import heartb from "../../common/images/heart-dark.svg";
import profilemen from "../../common/images/profile_men.svg";
import logout from "../../common/images/log-out.svg";
import anav1 from "../../common/images/adminnav1.svg";
import anav2 from "../../common/images/adminnav2.svg";
import anav3 from "../../common/images/adminnav3.svg";
import anav4 from "../../common/images/adminnav4.svg";
import anav5 from "../../common/images/adminnav5.svg";
import anav6 from "../../common/images/adminnav6.svg";
import anav7 from "../../common/images/adminnav7.svg";

class Topmenu extends Component {
  constructor(props) {
    super(props);

    this.state = { currentpage: "" };
    this.logoutPopup = this.logoutPopup.bind(this);
    this.confirmLogout = this.confirmLogout.bind(this);
    this.onRouteClick = this.onRouteClick.bind(this);
    this.inactivityTimeout = null;
    this.resetLogoutTimeout();
  }
  componentDidMount() {
    window.addEventListener("mousemove", this.handleUserActivity);
    window.addEventListener("keydown", this.handleUserActivity);
    if (localStorage.getItem("currentpage") !== null) {
      this.setState({ currentpage: localStorage.getItem("currentpage") });
    } else if (localStorage.getItem("currentpage") === null) {
      var currentpage = "";
      if (this.props.location.pathname === "/myaccount/creator-myproducts") {
        currentpage = "creator-myproducts";
      } else if (this.props.location.pathname === "/myaccount/dashboard") {
        currentpage = "dashboard";
      } else if (this.props.location.pathname === "/myaccount/bookings") {
        currentpage = "bookings";
      } else if (this.props.location.pathname === "/myaccount/commission") {
        currentpage = "commission";
      } else if (this.props.location.pathname === "/myaccount/profilecreator") {
        currentpage = "creatorprofile";
      }

      this.setState({
        currentpage: currentpage,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.handleUserActivity);
    window.removeEventListener("keydown", this.handleUserActivity);
    clearTimeout(this.inactivityTimeout);
  }

  logoutPopup() {
    localStorage.removeItem("currentpage");
    $.magnificPopup.open({
      items: {
        src: "#remove_popup",
      },
      type: "inline",
    });
  }
  confirmLogout() {
    $.magnificPopup.close();
    cookie.remove("CUserId", { path: "/" });
    this.props.history.push("/");
  }

  onRouteClick(routeName) {
    this.setState({ currentpage: routeName });
    var currentpage = routeName;
    localStorage.setItem("currentpage", currentpage);
  }
  autoLogout = () => {
    cookie.remove("CUserId", { path: "/" });
    this.props.history.push("/");
  };
  handleUserActivity = () => {
    this.resetLogoutTimeout();
  };
  resetLogoutTimeout = () => {
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = setTimeout(this.autoLogout, 3 * 60 * 60 * 1000);
  };
  render() {
    return (
      <>
        <div className="creator-board-nav">
          <ul>
            <li
              className={this.state.currentpage === "dashboard" ? "active" : ""}
            >
              <Link
                to={"/myaccount/dashboard"}
                className="pronav"
                onClick={() => this.onRouteClick("dashboard")}
              >
                <img src={anav4} alt="" />

                <span>Dashboard</span>
              </Link>
            </li>

            <li
              className={
                this.state.currentpage === "creator-myproducts" ? "active" : ""
              }
            >
              <Link
                to={"/myaccount/creator-myproducts"}
                className="pronav"
                onClick={() => this.onRouteClick("creator-myproducts")}
              >
                <img src={anav2} alt="" />
                <span>My Products</span>
              </Link>
            </li>
            <li
              className={this.state.currentpage === "bookings" ? "active" : ""}
            >
              <Link
                to={"/myaccount/bookings"}
                className="pronav"
                onClick={() => this.onRouteClick("bookings")}
              >
                <img src={anav4} alt="" />
                <span>Bookings</span>
              </Link>
            </li>
            <li
              className={
                this.state.currentpage === "commission" ? "active" : ""
              }
            >
              <Link
                to={"/myaccount/commission"}
                className="pronav"
                onClick={() => this.onRouteClick("commission")}
              >
                <img src={anav4} alt="" />
                <span>Earnings</span>
              </Link>
            </li>
            <li
              className={
                this.state.currentpage === "helpcenter" ? "active" : ""
              }
            >
              <Link
                to={"/helpcenter"}
                target={"_blank"}
                className="pronav"
                onClick={() => this.onRouteClick("helpcenter")}
              >
                <img src={anav5} alt="" />
                <span>Help center</span>
              </Link>
            </li>
            <li
              className={
                this.state.currentpage === "creatorprofile" ? "active" : ""
              }
            >
              <Link
                to={"/myaccount/profilecreator"}
                className="pronav"
                onClick={() => this.onRouteClick("creatorprofile")}
              >
                <img src={anav6} alt="" />
                <span>Profile</span>
              </Link>
            </li>
            <li>
              <a onClick={this.logoutPopup}>
                <img src={anav7} alt="" />
                <span>Log out</span>
              </a>
            </li>
          </ul>
        </div>
        <div id="remove_popup" className="mfp-hide common-popup">
          <div className="custom_alert textcenter">
            <h2 className="text-uppercase">Warning</h2>
            <p>Are you sure want to logout </p>
            <div
              className="alt_btns"
              style={{ gap: "10px", display: "inline-flex" }}
            >
              <a
                className="button confirm-button ghost-button"
                onClick={this.confirmLogout}
              >
                Yes
              </a>
              <a
                href={void 0}
                className="button popup-modal-dismiss disbl_href_action"
                onClick={this.resetLogoutTimeout}
              >
                No
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Topmenu;
