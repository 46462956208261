/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { GET_BOOKINGHISTORY } from "../../actions";
import ContentLoader from "react-content-loader";
import {
  showLoader,
  hideLoader,
  showPriceValue,
} from "../Helpers/SettingHelper";
import actinfo1 from "../../common/images/act-info1.jpg";
import ticketact from "../../common/images/ticket-activity.svg";
import car from "../../common/images/car-icon.svg";
import hotel from "../../common/images/hotel-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';
var qs = require("qs");
const filterOptions = [
  {
    label: "latest Cancellations",
    value: "latestBookings",
  },
  {
    label: "Last Day",
    value: "lastDay",
  },
  {
    label: "Last Week",
    value: "lastWeek",
  },
  {
    label: "Last Month",
    value: "lastMonth",
  },
  {
    label: "Last 3 Months",
    value: "last3Months",
  },
  {
    label: "Last 6 Months",
    value: "last6Months",
  },
  {
    label: "Last Year",
    value: "lastYear",
  },
];
class CancelBooking extends Component {
  constructor(props) {
    super(props);
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    this.state = {
      UserId: UserId,
      existbookingList: [],
      filteredBookings: [],
      selectedFilter: "",
      bookinglist: [],
      displaybooking: "",
      page: 1,
      showLoadMore: false,
      isMobile: window.innerWidth <= 480,
      openFilterSheet: false,
      total_records: "",
      loading: true,
    };
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "page=" + this.state.page;
      if (UserId !== "") {
        params += "&customer_id=" + UserId + "&type=CANCELLED";
      }
      this.props.getBookingHistory(params);
    }
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.bookinglist !== this.state.existbookingList) {
      this.setState({ loading: true });
      if (this.state.bookinglist.length > 0) {
        var bookinglist = this.state.bookinglist.concat(PropsData.bookinglist);
      } else {
        var bookinglist = PropsData.bookinglist;
      }
      hideLoader("loadmore", "class");
      var showLoadMore = PropsData.bookinglist.length > 0 ? true : false;
      this.setState(
        {
          bookinglist: bookinglist,
          filterBookings: bookinglist,
          total_records: bookinglist.length,
          showLoadMore: showLoadMore,
          existbookingList: PropsData.bookinglist,
        },
        function () {
          this.displayBookingList();
          this.setState({ loading: false });
        }
      );
    }
  }
  loadMore() {
    this.setState({ page: parseInt(this.state.page) + 1 }, function () {
      var params = "page=" + this.state.page;
      if (this.state.UserId !== "") {
        params += "&customer_id=" + this.state.UserId + "&type=CANCELLED";
      }
      showLoader("loadmore", "class");
      this.props.getBookingHistory(params);
    });
  }
  displayBookingList() {
    this.setState({ loading: true });
    var displaybooking = "";
    if (this.state.bookinglist.length > 0) {
      displaybooking = this.state.bookinglist.map((item, index) => {
        return (
          <div className="mybooking-inner-main" key={index}>
            <div className="booked-ticket">
              <div className="booked-ticket-inner">
                <div className="booked-ticket-top">
                  <div className="btt-lhs">
                    <h5>
                      {item.packageName !== ""
                        ? item.packageName
                        : "Own Package"}
                    </h5>
                    <div className="sites-content">
                      <ul>
                        {item.flight === "Yes" && (
                          <li className="sc2">
                            <figure>
                              <img src={flight} alt="flight" />
                            </figure>
                            <figcaption>Round trip</figcaption>
                          </li>
                        )}
                        {item.hotel === "Yes" && (
                          <li className="sc2">
                            <figure>
                              <img src={hotel} alt="hotel" />
                            </figure>
                            <figcaption>hotel</figcaption>
                          </li>
                        )}
                        {item.transfer === "Yes" && (
                          <li className="sc2">
                            <figure>
                              <img src={car} alt="hotel" />
                            </figure>
                            <figcaption>Round trip</figcaption>
                          </li>
                        )}
                        {item.totalActivity > 0 && (
                          <li className="sc3">
                            <figure>
                              <img src={ticketact} alt="ticketa" />
                            </figure>
                            <figcaption>
                              {item.totalActivity} activities
                            </figcaption>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="tickt-content">
                      {item.booking_created_on}
                    </div>
                    <div className="tickt-content">
                      <ul>
                        <li>
                          <span>Booking ref ID </span>
                          <strong>{item.booking_reference_number}</strong>
                        </li>
                        <li>
                          <span>Payment ref ID</span>
                          <strong>
                            {item.booking_payment_reference !== "" &&
                            item.booking_payment_reference !== null
                              ? item.booking_payment_reference
                              : item.booking_reference_number}
                          </strong>
                        </li>
                        <li>
                          <span>No of travelers</span>
                          <strong>
                            {item.adults > 0 && <>{item.adults} x adult</>}
                            {item.child > 0 && <>, {item.child} x child</>}
                            {item.infant > 0 && <>, {item.infant} x infant</>}
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="btt-rhs">
                    <img
                      src={item.thumbnail !== "" ? item.thumbnail : actinfo1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="booked-ticket-btm">
                  <div className="booked-ticket-btm-lhs">
                    <span>Total paid</span>
                    <p>
                      SGD{" "}
                      <strong>
                        {showPriceValue(item.booking_total_amount)}
                      </strong>
                    </p>
                  </div>
                  {/*   <div className="booked-ticket-btm-rhs">
                    <a href="#" className="button">
                      Download ticket
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    this.setState({ displaybooking: displaybooking, loading: false });
  }
  handleFilterChange = (event) => {
    /*  this.setState({ loading: true }); */
    var selectedFilter = event.target.value;
    console.log(selectedFilter, "selected filter");
    this.setState({ loading: true });
    var { filterBookings } = this.state;
    console.log(filterBookings, "bookinglist");
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    var lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);
    var lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);
    var lastThreeMonths = new Date(today);
    lastThreeMonths.setMonth(today.getMonth() - 3);
    var lastSixMonths = new Date(today);
    lastSixMonths.setMonth(today.getMonth() - 6);
    var lastYear = new Date(today);
    lastYear.setFullYear(today.getFullYear() - 1);
    let filteredBookings = [];
    setTimeout(() => {
      if (selectedFilter === "latestBookings") {
        filteredBookings = filterBookings.slice().sort((a, b) => {
          var dateA = new Date(a.booking_created_on);
          var dateB = new Date(b.booking_created_on);
          return dateB - dateA;
        });
      } else if (selectedFilter === "lastDay") {
        filteredBookings = filterBookings.filter((item) => {
          var bookingDate = new Date(item.booking_created_on);
          return bookingDate >= yesterday && bookingDate <= today;
        });
      } else if (selectedFilter === "lastWeek") {
        filteredBookings = filterBookings.filter((item) => {
          var bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastWeek && bookingDate <= today;
        });
      } else if (selectedFilter === "lastMonth") {
        filteredBookings = filterBookings.filter((item) => {
          var bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastMonth && bookingDate <= today;
        });
      } else if (selectedFilter === "last3Months") {
        filteredBookings = filterBookings.filter((item) => {
          var bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastThreeMonths && bookingDate <= today;
        });
      } else if (selectedFilter === "last6Months") {
        filteredBookings = filterBookings.filter((item) => {
          var bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastSixMonths && bookingDate <= today;
        });
      } else if (selectedFilter === "lastYear") {
        filteredBookings = filterBookings.filter((item) => {
          var bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastYear && bookingDate <= today;
        });
      } else {
        filteredBookings = filterBookings;
      }
      console.log(filteredBookings, "first filtered");
      this.setState(
        {
          selectedFilter: selectedFilter,
          bookinglist: filteredBookings,
          loading: false,
        },
        () => {
          console.log(
            this.state.bookinglist,
            selectedFilter,
            "filteredBookings"
          );
          this.displayBookingList();
          this.setState({ loading: false });
        }
      );
    }, 1000);
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };

  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div className="bread-crumbs-other bread-crumbs">
          <div className="container">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Cancellations</li>
            </ul>
          </div>
        </div>
        <section className="innersection">
          <div className="container">
            <div className="admin-profile">
              <Sidebar pageName="cancellation" />
              <div className="admin-profile-rhs mybooking-rhs">
                <div className="wishlisth-header-filter">
                  <div className="wishlisth-header">
                    <h5>
                      Your <span>Cancellations</span>{" "}
                      <strong>
                        {" "}
                        ({this.state.bookinglist.length}of{" "}
                        {this.state.total_records})
                      </strong>
                    </h5>
                  </div>
                  {this.state.isMobile ? (
                    <div className="ptf-options">
                      <ul>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.setState({ openFilterSheet: true })
                            }
                          >
                            <span>Filter By</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="filter-ge">
                      <select id="filter" onChange={this.handleFilterChange}>
                        <option value="latestBookings">
                          latest Cancellations
                        </option>
                        <option value="lastDay">Last Day</option>
                        <option value="lastWeek">Last Week</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="last3Months">Last 3 Months</option>
                        <option value="last6Months">Last 6 Months</option>
                        <option value="lastYear">Last Year</option>
                      </select>
                    </div>
                  )}
                </div>
                {/*   {this.state.displaybooking} */}
                {this.state.loading
                  ? Array(1, 2, 3).map((item) => {
                      return (
                        <div className="mybooking-inner-main" key={item}>
                          <ContentLoader
                            height={140}
                            speed={1}
                            viewBox="0 0 380 70"
                          >
                            <rect
                              x="0"
                              y="0"
                              rx="5"
                              ry="5"
                              width="70"
                              height="70"
                            />
                            <rect
                              x="80"
                              y="17"
                              rx="4"
                              ry="4"
                              width="300"
                              height="13"
                            />
                            <rect
                              x="80"
                              y="40"
                              rx="3"
                              ry="3"
                              width="250"
                              height="10"
                            />
                          </ContentLoader>
                        </div>
                      );
                    })
                  : this.state.displaybooking}

                {this.state.showLoadMore && (
                  <a
                    href={void 0}
                    onClick={this.loadMore.bind(this)}
                    className="button loadmore"
                  >
                    Load More
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openFilterSheet: false })}
        >
          <div className="searchContainer__bottomSheetMain__container">
            <div className="searchContainer__bottomSheetMain__container__sourceAir">
              {filterOptions?.map((option, index) => (
                <div
                  key={index}
                  className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                    this.state.selectedFilter === option?.value
                      ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                      : ""
                  }`}
                  onClick={() => {
                    this.handleFilterChange({
                      target: { value: option?.value },
                    });
                    this.setState({ openFilterSheet: false });
                  }}
                >
                  {option?.label}
                </div>
              ))}
            </div>
          </div>
        </BottomSheet>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var bookinglistArr = Array();
  if (Object.keys(state.bookinghistory).length > 0) {
    if (state.bookinghistory[0].status === "ok") {
      bookinglistArr = state.bookinghistory[0].result_set;
    }
  }
  return {
    bookinglist: bookinglistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBookingHistory: (params) => {
      dispatch({ type: GET_BOOKINGHISTORY, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(CancelBooking);
