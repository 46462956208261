/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
/* import cookie from "react-cookies"; */
import {} from "recharts";
import { UopUrl, apiUrl, fizoheader } from "../Helpers/Config";
import editpen from "../../common/images/edit-pen.svg";
import Avatar from "react-avatar";
import bpin from "../../common/images/map-pin.svg";
import bprofile from "../../common/images/banner-profile.svg";
import addplusimg from "../../common/images/add-imgplus.svg";
import backarrow from "../../common/images/back-arrow-front.svg";

import bcalender from "../../common/images/calender-banner.svg";

import Select from "react-select";
import axios from "axios";
import cookie from "react-cookies";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Topmenu from "../Layout/Topmenu";
import { GET_CUSTOMER_DETAIL } from "../../actions";

class Listpromo extends Component {
  constructor(props) {
    super(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }

    this.state = {
      CUserId: CUserId,
      customerdetail: "",
      isLoading: true,
      airport: [],
      address: "",
      packagecountry: "",
      packageCity: "",
      packageLoading: true,
      cityList: [],
      selectedpackagecity: [],
      creator: [],
      creator_package_id: "",
      stay_name: "",
      stay_address: "",
      stay_amenities: "",
      check_in_time: "",
      check_out_time: "",
      stay_terms_and_conditions: "",
      stay_room_image: [],
      room_types: [],
    };
    /*  if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "customer_id=" + cookie.load("UserId");
      this.props.getCustomerDetail(params);
    } */
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    this.fetchData();
    // this.chartData();
  }
  Roomtype = (event) => {
    $.magnificPopup.open({
      items: {
        src: "#room-type",
      },
      type: "inline",
      midClick: true,
      closeOnBgClick: false,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOption !== this.state.selectedOption) {
      this.fetchData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
    }
  }
  openPopup() {
    $.magnificPopup.open({
      items: {
        src: "#createpackage",
      },
      type: "inline",
      midClick: true,
    });
  }
  loadAirport = async () => {
    try {
      await axios
        .get(
          apiUrl + "settings/getAirport?country=" + this.state.packagecountry,
          fizoheader
        )
        .then((res) => {
          var airport = [];
          if (res.data.status === "ok") {
            res.data.result_set.map((item) => {
              airport.push({
                value: item.airport_id,
                label: item.airport_name + " - " + item.airport_code,
              });
            });
            this.setState({ airport: airport });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  sateValChange = (field, value) => {
    try {
      if (field === "address") {
        if (value !== "") {
          var address = $("#pac-input").val();
          var addressdetails = value.split("~~");
          this.setState(
            {
              address: address,
              packagecountry: addressdetails[0],
              pacakgeLat: addressdetails[1],
              packageLon: addressdetails[2],
              packageCity: addressdetails[3],
            },
            function () {
              if (this.state.packagecountry !== "") {
                // this.loadAirport();
              }
            }
          );
        }
      }
      if (field === "cityList" && value !== "") {
        var cityList = [];
        if (Object.keys(value.result_set).length > 0) {
          Object.keys(value.result_set).map((item) => {
            var selectedCountry = value.result_set[item];
            selectedCountry.city.map((city) => {
              cityList.push({
                label: city.city_name + ", " + city.country_name,
                value:
                  city.airport_address +
                  "~" +
                  city.airport_code +
                  "~" +
                  city.airport_id +
                  "~" +
                  city.airport_latitude +
                  "~" +
                  city.airport_longitude +
                  "~" +
                  city.airport_name +
                  "~" +
                  city.city_code +
                  "~" +
                  city.city_id +
                  "~" +
                  city.city_name +
                  "~" +
                  city.country_code +
                  "~" +
                  city.country_name +
                  "~" +
                  city.webbeds_city_code,
              });
            });
          });
        }
        this.setState(
          {
            cityList: cityList,
          },
          function () {}
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  getFormattedDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}`;
  };
  fetchData = async () => {
    try {
      await axios
        .get(
          apiUrl +
            "dashboard/activity?creator_id=" +
            this.state.CUserId +
            "&days=",
          fizoheader
        )
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data, "creator");
            this.setState({
              creator: response.data,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  loadRatingstar(points) {
    try {
      if (points === "" || points === null) {
        points = "0.0";
      }
      if (points !== "" && points !== null) {
        var splitrating = points.split(".");
        return [...Array(5)].map((star, index) => {
          var currentRate = parseInt(index) + 1;
          if (parseFloat(points) >= currentRate) {
            if (
              parseInt(splitrating[0]) === parseInt(currentRate) &&
              parseInt(splitrating[1]) >= 5
            ) {
              return (
                <i
                  className="fa fa-star-half-o"
                  aria-hidden="true"
                  key={index}
                ></i>
              );
            } else {
              return (
                <i className="fa fa-star" aria-hidden="true" key={index}></i>
              );
            }
          } else {
            return (
              <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
            );
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleChange = (name, event) => {
    try {
      if (name === "stay_room_image") {
        var uploadImage = this.state.stay_room_image;
        uploadImage.push(event.target.files[0]);
        this.setState({ [name]: uploadImage });
      } else {
        this.setState({ [name]: event.target.value });
      }
    } catch (err) {
      console.log(err);
    }
  };

  uploadStayDetails = async () => {
    try {
      var stayDetails = {
        creator_id: this.state.CUserId,
        creator_package_id: this.state.creator_package_id,
        stay_name: this.state.stay_name,
        stay_address: this.state.stay_address,
        stay_amenities: this.state.stay_amenities,
        check_in_time: this.state.check_in_time,
        check_out_time: this.state.check_out_time,
        stay_terms_and_conditions: this.state.stay_terms_and_conditions,
        stay_room_image: this.state.stay_room_image,
        room_types: this.state.room_types,
      };
      await axios
        .post(`${UopUrl}/itinerary/stay`, stayDetails, fizoheader)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            // this.props.history.push("/itinerary-list");
          }
        })
        .catch((err) => {
          console.log(er);
        });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        {this.state.isLoading === false ? (
          <>
            {this.state.creator !== "" && (
              <>
                <section className="main-blue-bg">
                  <div className="container-full">
                    <div className="creator-board">
                      <div className="creator-board-lhs">
                        <div className="creator-box">
                          <div className="creator-img">
                            {/* <img
                          src={this.state.creator.creator_details.creator_image}
                        />{" "} */}
                            {this.state.creator?.creator_details
                              ?.creator_image !== "" &&
                            this.state.creator?.creator_details
                              ?.creator_image !== null ? (
                              <img
                                src={
                                  this.state.creator?.creator_details
                                    ?.creator_image
                                }
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={
                                  this.state.creator?.creator_details
                                    ?.creator_handle_name
                                }
                                size="50"
                                round={true}
                                color="#4258bf"
                              />
                            )}
                          </div>
                          <div className="creator-def">
                            <a
                              href={void 0}
                              onClick={() => {
                                var cid =
                                  this.state.creator?.creator_details
                                    ?.creator_id;
                                this.props.history.push("/public_profile", cid);
                              }}
                            >
                              {" "}
                              {
                                this.state.creator?.creator_details
                                  ?.creator_handle_name
                              }
                            </a>
                            <p> Level 1 creator</p>
                            <span>
                              <strong>
                                {
                                  this.state.creator?.creator_details
                                    ?.creator_total_rating
                                }
                              </strong>{" "}
                              (1) Reviews
                            </span>
                            <div className="star-rating">
                              {/* <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star-half-o" aria-hidden="true"></i> */}

                              {this.loadRatingstar(
                                this.state.creator?.creator_details
                                  ?.creator_rating
                              )}
                            </div>
                          </div>
                        </div>
                        <Topmenu
                          {...this.props}
                          currentpage="creator-myproducts"
                        />
                      </div>
                      <div className="creator-board-rhs">
                        <div className="standfirst">
                          <h2 className="color-header">
                            <a href="#" className="arrow-big">
                              {" "}
                              <img src={backarrow} />{" "}
                            </a>
                            Add your vacation <span>package</span>
                          </h2>
                          <a
                            href={void 0}
                            onClick={this.uploadStayDetails}
                            className="button btn-scoi"
                          >
                            Save & continue
                          </a>
                        </div>
                        <div className="tabloid">
                          <ul>
                            <li>
                              <a href="#">General information</a>
                            </li>
                            <li>
                              <a href="#">Photos & Videos</a>
                            </li>
                            <li className="active">
                              <a href="#">Itinerary</a>
                            </li>
                            <li>
                              <a href="#">Availability</a>
                            </li>
                            <li>
                              <a href="#">Pricing</a>
                            </li>
                            <li>
                              <a href="#">Submit</a>
                            </li>
                          </ul>
                        </div>
                        <div className="tabloid sub-tabloid">
                          <ul>
                            <li className="active">
                              <a href="#">Stay 1</a>
                            </li>
                            <li>
                              <a href="#">Stay 2</a>
                            </li>
                            <li>
                              <a href="#">Stay 3</a>
                            </li>
                            <li className="add-stay-plus">
                              <a href="#">+ Add stay</a>
                            </li>
                          </ul>
                        </div>
                        <div className="billboard billboard-with-nav">
                          <div className="billboard-nav">
                            <div className="billboard-navlhs">
                              <a href="/myaccount/itinerary-list">
                                <i
                                  class="fa fa-angle-left"
                                  aria-hidden="true"
                                ></i>{" "}
                                Back to itinerary
                              </a>
                            </div>
                            <div className="billboard-navrhs">
                              <select name="selectedGender">
                                <option value="">Select stay city</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="U">Others</option>
                              </select>
                            </div>
                          </div>
                          <div className="billboard-lhs">
                            <div className="form-group">
                              <label className="control-label">
                                Stay name <em>*</em>
                              </label>
                              <input
                                placeholder="York hotel Singapore"
                                type="text"
                                value={this.state.stay_name}
                                onChange={(e) =>
                                  this.handleChange("stay_name", e)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Stay amenities <em>*</em>
                              </label>
                              <select
                                name="selectedAmenities"
                                onChange={(e) =>
                                  this.handleChange("stay_amenities", e)
                                }
                              >
                                <option value="">Select Amenities</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="U">Others</option>
                              </select>
                            </div>
                            <div className="form-group room-options">
                              <label className="control-label">
                                Room options <em>*</em>
                              </label>
                              <div className="broadsheet">
                                <h4>Deluxe superior Room</h4>

                                <div className="bs-merge">
                                  <div className="bs-option">
                                    <ul className="bs-option-one">
                                      <li className="breakfast">Breakfast</li>
                                      <li className="lunch">Lunch</li>
                                      <li className="diner">Dinner</li>
                                    </ul>
                                    <ul className="bs-option-two">
                                      <li>Complimentary drinks</li>
                                      <li>Snacks</li>
                                      <li>Dinner</li>
                                    </ul>
                                  </div>
                                  <div className="bs-people">
                                    <span>2 adult</span> <span>2 child</span>
                                  </div>
                                  <a href="#" className="room-editn">
                                    <img src={editpen} /> Edit
                                  </a>
                                </div>
                              </div>
                              <div className="broadsheet">
                                <h4>Grand deluxe twin bed Room</h4>

                                <div className="bs-merge">
                                  <div className="bs-option">
                                    <ul className="bs-option-two">
                                      <li>Complimentary drinks</li>
                                      <li>Snacks</li>
                                      <li>Dinner</li>
                                    </ul>
                                  </div>
                                  <div className="bs-people">
                                    <span>2 adult</span> <span>2 child</span>
                                  </div>
                                  <a href="#" className="room-editn">
                                    <img src={editpen} /> Edit
                                  </a>
                                </div>
                              </div>
                              <div className="add-r-type">
                                <a href="#" onClick={this.Roomtype.bind(this)}>
                                  <figure>
                                    <img src={addplusimg} />
                                    <figcaption>
                                      Add room type options
                                    </figcaption>
                                  </figure>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="billboard-rhs">
                            <div className="billboard-top">
                              <div className="form-group">
                                <label className="control-label">
                                  Stay address <em>*</em>
                                </label>
                                <input
                                  placeholder="No 123, York hotel Singapore"
                                  type="text"
                                  value={this.state.stay_address}
                                  onChange={(e) =>
                                    this.handleChange("stay_address", e)
                                  }
                                />
                              </div>
                              <div className="fg-half">
                                <div className="form-group">
                                  <label className="control-label">
                                    Check-In time <em>*</em>
                                  </label>
                                  <select
                                    name="selectedGender"
                                    onChange={(e) =>
                                      this.handleChange("check_in_time", e)
                                    }
                                  >
                                    <option value="">Select gender</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                    <option value="U">Others</option>
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label className="control-label">
                                    Check-Out time <em>*</em>
                                  </label>
                                  <select
                                    name="selectedGender"
                                    onChange={(e) =>
                                      this.handleChange("check_out_time", e)
                                    }
                                  >
                                    <option value="">Select gender</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                    <option value="U">Others</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group big-textarea">
                                <label className="control-label">
                                  Stay terms and conditions <em>*</em>
                                </label>
                                <textarea
                                  id="textarea"
                                  name="description"
                                  placeholder="Write about the hotel terms and conditions here.."
                                  value={this.state.stay_terms_and_conditions}
                                  onChange={(e) =>
                                    this.handleChange(
                                      "stay_terms_and_conditions",
                                      e
                                    )
                                  }
                                ></textarea>
                              </div>

                              <div className="form-group fgp-add-img">
                                <label className="control-label">
                                  Stay & Room image <em>*</em>
                                </label>
                                <p>
                                  Please add images with min resolution of
                                  1080x1080.
                                </p>
                                <div className="fgp-add-row">
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <figure>
                                          <img src={addplusimg} />
                                          <figcaption>Add image</figcaption>
                                        </figure>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <figure>
                                          <img src={addplusimg} />
                                          <figcaption>Add image</figcaption>
                                        </figure>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <figure>
                                          <img src={addplusimg} />
                                          <figcaption>Add image</figcaption>
                                        </figure>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  id="room-type"
                  className="mfp-hide common-popup iteinery-stay-popup"
                >
                  <div className="isp-header textcenter">
                    <h3>Room type</h3>
                  </div>
                  <div className="isp-body">
                    <div className="form-group">
                      <label className="control-label">
                        Room type <em>*</em>
                      </label>
                      <input
                        className="input-border"
                        placeholder="eg: Deluxe superior"
                        type="text"
                        value=""
                      />
                    </div>
                    <div className="fg-half">
                      <div className="form-group">
                        <label className="control-label">
                          Max capacity adult <em>*</em>
                        </label>
                        <select name="selectedGender">
                          <option value="">Select gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="U">Others</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          Max capacity child <em>*</em>
                        </label>
                        <select name="selectedGender">
                          <option value="">Select gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="U">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group fb-box-chk">
                      <label className="control-label">
                        Food & Beverage <em>*</em>
                      </label>
                      <div className="inline-checkbox">
                        <ul>
                          <li>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span>Breakfast</span>
                          </li>
                          <li>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span>Lunch</span>
                          </li>
                          <li>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span>Dinner</span>
                          </li>
                          <li>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span>Snacks</span>
                          </li>
                          <li>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span>Complimentary drinks</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="isp-footer textcenter">
                    <a href="#" className="button">
                      Add room
                    </a>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
