import { Box, IconButton, Modal } from "@mui/material";
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselModal.scss";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80%",
  maxWidth: "80%",
  minHeight: "80%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
};

const CarouselModal = (props) => {
  const { open, handleClose, data, type } = props;

  let carouselRef = useRef(null);

  const LeftNavButton = () => {
    return (
      <div className="prevBtn" onClick={() => carouselRef.current.slickPrev()}>
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </div>
    );
  };

  const RightNavButton = () => {
    return (
      <div className="nextBtn" onClick={() => carouselRef.current.slickNext()}>
        <i className="fa fa-angle-right" aria-hidden="true"></i>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <RightNavButton />,
    prevArrow: <LeftNavButton />,
  };
  // console.log(data);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="carousel-modal">
        <IconButton onClick={handleClose} className="close-btn">
          <CloseIcon />
        </IconButton>
        <Slider {...settings} ref={carouselRef}>
          {typeof data !== "string" &&
            data?.map((item, index) =>
              item ? (
                <div key={index}>
                  <div className="carousel-modal">
                    {type === "img" ? (
                      <img
                        src={
                          typeof item !== Object
                            ? item
                            : URL.createObjectURL(item)
                        }
                        alt=""
                      />
                    ) : (
                      <video
                        src={
                          item?.includes("https")
                            ? item
                            : URL.createObjectURL(item)
                        }
                        width="100%"
                        // height="50vh"
                        controls
                      ></video>
                    )}
                  </div>
                </div>
              ) : null
            )}
        </Slider>
        {/* <div
          className="prevBtn"
          onClick={() => carouselRef.current.slickPrev()}
        >
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </div> */}
        {/* <div className="nextBtn" onClick={() => carouselRef.current.slickNext()}>
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </div> */}
      </Box>
    </Modal>
  );
};

export default CarouselModal;
