import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useContext, useRef, useState } from "react";
// import { AppContext } from "@/context/appContext";
// import MyUploadAdapter from "./SimpleUploadAdapter";

const CustomEditor = ({ value, onChange, onReady, innerRef, onBlur }) => {
  const [content, setContent] = useState("");

//   const { state, dispatch } = useContext(AppContext);

  const handleChange = (event, editor) => {
    // dispatch({
    //   type: "CREATE_BLOG",
    //   payload: content,
    // });
    // const data = editor.getData();
    // // onChange(data);
    // setContent(data);
  };

  const editorConfiguration = {
    // extraPlugins: [MyCustomUploadAdapterPlugin],
    htmlEmbed: {
      showPreviews: true,
    },
    // heading: {
    //   options: [
    //     {
    //       model: "paragraph",
    //       title: "Paragraph",
    //       class: "ck-heading_paragraph",
    //     },
    //     {
    //       model: "heading1",
    //       view: "h1",
    //       title: "Heading 1",
    //       class: "ck-heading_heading1",
    //     },
    //     {
    //       model: "heading2",
    //       view: "h2",
    //       title: "Heading 2",
    //       class: "ck-heading_heading2",
    //     },
    //     {
    //       model: "heading3",
    //       view: "h3",
    //       title: "Heading 3",
    //       class: "ck-heading_heading3",
    //     },
    //     {
    //       model: "heading4",
    //       view: "h4",
    //       title: "Heading 4",
    //       class: "ck-heading_heading4",
    //     },
    //     {
    //       model: "heading5",
    //       view: "h5",
    //       title: "Heading 5",
    //       class: "ck-heading_heading5",
    //     },
    //     {
    //       model: "heading6",
    //       view: "h6",
    //       title: "Heading 6",
    //       class: "ck-heading_heading6",
    //     },
    //   ],
    // },
  };

  return (
    <CKEditor
      editor={Editor}
      ref={innerRef}
      config={editorConfiguration}
      data={value}
      onChange={onChange}
      onReady={onReady}
      onBlur={onBlur}
      // onReady={onReady(editor: any) => {
      //   ref.current = editor;
      //   // editor.ui.view.editable.element.style.minHeight = `calc(100vh - 400px)`;
      // }}
      onFocus={(event, editor) => {
        console.log("event: onFocus", { event, editor });
      }}
    />
  );
};

export default React.forwardRef(CustomEditor);
