/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
    GET_UPLOAD_FILES,
    SET_UPLOAD_FILES,
} from "../actions";
import { userapiUrl, fizoheader, mediaUrlDev } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetGetUploadFiles = function* () {
    yield takeEvery(GET_UPLOAD_FILES, workerGetUploadFiles);
};

function* workerGetUploadFiles({ formPayload }) {
    try {
        const result = yield call(GetUploadFiles, formPayload);
        console.log(result, "result")
        var resultArr = [];
        resultArr.push(result.data);
        yield put({ type: SET_UPLOAD_FILES, value: resultArr });
    } catch {
        console.log("upload failed");
    }
}

function GetUploadFiles(formPayload) {
    return Axios.post(mediaUrlDev + "image/upload", formPayload, fizoheader);
}
