/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CREATOR_REGISTRATION, SET_CREATOR_REGISTRATION } from "../actions";
import { userapiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCreatorRegistration = function* () {
  yield takeEvery(GET_CREATOR_REGISTRATION, workerGetCreatorRegistration);
};

function* workerGetCreatorRegistration({ formPayload }) {
  try {
    const result = yield call(getCreatorRegistration, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CREATOR_REGISTRATION, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getCreatorRegistration(formPayload) {
  return Axios.post(
    userapiUrl + "creators/registration",
    formPayload,
    fizoheader
  );
}
