/* eslint-disable */
import React, { Component } from "react";
import minusb from "../../../common/images/minus-b.svg";
import plusb from "../../../common/images/plus-b.svg";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { UopUrl, apiUrl, fizoheader } from "../../Helpers/Config";
import Axios from "axios";
import SubHeader from "../SubHeader";
import "./Availability.scss";
import { Button, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import cookie from "react-cookies";
import moment from "moment/moment";
import ModalPopup from "../ModalPopup/ModalPopup";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import Select from "react-select";
import useUnsavedChangesWarning from "../../Help/UnsavedPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const deafultDateRange = [
  {
    startDate: null,
    endDate: new Date(""),
    key: "selection",
  },
];

export default class Availability extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    let CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    let packageID = "";
    if (this.props?.location?.state?.packageID !== undefined) {
      packageID = this.props?.location?.state?.packageID;
    }
    if (this.props?.location?.state?.creator_package_id !== undefined) {
      packageID = this.props?.location?.state?.creator_package_id;
    }
    let allowedSection = [];
    if (this.props?.location?.state?.allowedSection !== undefined) {
      allowedSection = this.props.location.state.allowedSection;
    }
    if (!allowedSection?.includes("availability")) {
      this.props.history.push("/myaccount/general-info");
    }
    let postedSection = [];
    if (this.props?.location?.state?.postedSection !== undefined) {
      postedSection = this.props?.location?.state?.postedSection;
    }
    let newPackage = true;
    if (this.props?.location?.state?.newPackage !== undefined) {
      newPackage = this.props?.location?.state?.newPackage;
    } else if (postedSection.includes("availability")) {
      newPackage = false;
    }

    this.state = {
      CUserId: CUserId,
      creator_package_id: packageID,
      packSlot: [],
      slotStartsDay: [],
      selectedIndex: null,
      tempPackSlot: [
        {
          season: "Peak",
          start_date: new Date(),
          end_date: new Date(),
          guest_capacity: 1,
          start_day: [],
          start_dates: [],
        },
      ],
      slotIndex: 0,
      editIndex: null,
      dateRange: deafultDateRange,
      selectionRange: [],
      packageID: packageID,
      allowedSection: allowedSection,
      postedSection: postedSection,
      newPackage: newPackage,
      dateArray: [],
      dateError: "",
      includedSeason: [],
      selectedTab: 1,
      openModal: false,
      popupTitle: "",
      popupMsg: "",
      startMonth: 0,
      endMonth: 5,
      isFormEdited: false,
      selectedDays: [],
    };
  }

  componentDidMount() {
    let posted = this.state.postedSection.includes("availability");
    if (!this.state.newPackage || posted) {
      this.getItineraryData();
    }
    $(document).on("click", ".rdrWeekDay", (evt) => {
      const el = evt.currentTarget;
      const monthEle = $(el).closest(".rdrMonth").children(".rdrMonthName");
      this.getDatesbyDay(el?.innerText, monthEle[0]?.innerText);
    });
    // $(document).on("click", ".rdrMonthName", (evt) => {
    //   const el = evt.currentTarget;
    //   this.getDatesbyMonth(el?.innerText);
    // });
    this.showOrHideMonth();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.startMonth !== this.state.startMonth ||
      prevState.selectedTab !== this.state.selectedTab
    ) {
      this.showOrHideMonth();
    }
  }

  showOrHideMonth = () => {
    setTimeout(() => {
      $(".rdrMonth").each((index, element) => {
        if (this.state.startMonth === 0) {
          if (this.state.endMonth < index) {
            $(element).addClass("hide");
          }
          if (this.state.endMonth + 1 > index) {
            $(element).removeClass("hide");
          }
        }
        if (this.state.startMonth === 6) {
          if (this.state.startMonth > index) {
            $(element).addClass("hide");
          }
          if (this.state.startMonth - 1 < index) {
            $(element).removeClass("hide");
          }
        }
      });
    }, 500);
  };

  getDatesbyMonth = (selected) => {
    const monthDate = moment(selected, "MMM YYYY").startOf("month");
    const month = monthDate.month();
    let daysInMonth = monthDate.daysInMonth();
    let dates = [];
    while (daysInMonth) {
      dates.push({
        startDate: monthDate.toDate(),
        endDate: monthDate.toDate(),
        key: `selection`,
      });
      monthDate.add(1, "day");
      daysInMonth--;
    }
    const temp = [...this.state.selectionRange, ...dates];

    const startDate =
      this.state.dateRange?.length && this.state.selectionRange?.length
        ? this.state.dateRange[0].startDate &&
          new Date(this.state.dateRange[0].startDate).getMonth() < month
          ? this.state.dateRange[0].startDate
          : moment(selected, "MMM YYYY").startOf("month").format("YYYY-MM-DD")
        : moment(selected, "MMM YYYY").startOf("month").format("YYYY-MM-DD");

    const endDate =
      this.state.dateRange?.length && this.state.selectionRange?.length
        ? moment(this.state.dateRange[0].endDate).isValid() &&
          new Date(this.state.dateRange[0].endDate).getMonth() >= month
          ? this.state.dateRange[0].endDate
          : moment(selected, "MMM YYYY").endOf("month").format("YYYY-MM-DD")
        : moment(selected, "MMM YYYY").endOf("month").format("YYYY-MM-DD");

    if (
      this.state.dateArray?.some(
        (val) =>
          moment(val).isSameOrAfter(moment(startDate)) &&
          moment(val).isSameOrBefore(moment(endDate))
      )
    ) {
      return;
    }

    let tempPackSlot = [...this.state.tempPackSlot];
    tempPackSlot[this.state.packSlot?.length].start_date = startDate;
    tempPackSlot[this.state.packSlot?.length].end_date = endDate;

    this.setState({
      selectionRange: temp,
      dateRange: [
        {
          startDate,
          endDate,
          key: "selection",
        },
      ],
      tempPackSlot: tempPackSlot,
    });
  };

  getDatesbyDay = (selected, mon) => {
    const day = moment(mon, "MMM YYYY").startOf("month").day(selected);
    let tempPackSlot = [...this.state.tempPackSlot];
    if (day.date() > 7) day.add(7, "d");
    const currentMonth = day.format("MM");

    const month = day.month();
    let dates = [...this.state.selectionRange];
    let isExist = false;
    while (month === day.month()) {
      if (dates?.length) {
        const index = dates
          .map((val) => moment(val?.startDate).format("DD-MM-YYYY"))
          .indexOf(moment(day.toDate()).format("DD-MM-YYYY"));
        if (index > -1) {
          dates.splice(index, 1);
        } else {
          dates.push({
            startDate: day.toDate(),
            endDate: day.toDate(),
            key: `selection`,
          });
        }
      } else {
        dates.push({
          startDate: day.toDate(),
          endDate: day.toDate(),
          key: `selection`,
        });
      }

      if (
        this.state.dateArray
          ?.map((item) => moment(item).format("YYYY-MM-DD"))
          .includes(moment(day).format("YYYY-MM-DD"))
      ) {
        isExist = true;
      }
      day.add(7, "d");
    }
    if (isExist) {
      return;
    }

    const index =
      this.state.editIndex !== null
        ? this.state.editIndex
        : this.state.tempPackSlot?.length - 1;

    console.log(
      "this.state.dateRange",
      this.state.dateRange,
      tempPackSlot[index]
    );

    const start = moment(mon, "MMM YYYY").startOf("month").format("YYYY-MM-DD");
    const end = moment(mon, "MMM YYYY").endOf("month").format("YYYY-MM-DD");

    const startDate = tempPackSlot[index].start_date
      ? moment(tempPackSlot[index].start_date).isBefore(start)
        ? tempPackSlot[index].start_date
        : start
      : moment(mon, "MMM YYYY").startOf("month").format("YYYY-MM-DD");
    const endDate = tempPackSlot[index].end_date
      ? moment(tempPackSlot[index].end_date).isAfter(end)
        ? tempPackSlot[index].end_date
        : end
      : moment(mon, "MMM YYYY").endOf("month").format("YYYY-MM-DD");

    const ind = tempPackSlot[index].start_day
      ?.map(({ value }) => value)
      .indexOf(day.format("dddd").toLowerCase());

    if (ind > -1) {
      const months = tempPackSlot[index].start_dates?.map(({ startDate }) =>
        moment(startDate).format("MM")
      );
      if (months.includes(currentMonth)) {
        tempPackSlot[index].start_day.splice(ind, 1);
      }
    } else {
      tempPackSlot[index].start_day.push({
        value: day.format("dddd").toLowerCase(),
        label: day.format("dddd"),
      });
    }

    if (dates?.length) {
      tempPackSlot[index].start_date = startDate;
      tempPackSlot[index].end_date = endDate;
    } else {
      tempPackSlot[index].start_date = null;
      tempPackSlot[index].end_date = new Date("");
    }

    tempPackSlot[index].start_dates = [...dates];

    this.setState({
      selectionRange: [...dates],
      tempPackSlot: tempPackSlot,
    });
  };

  monthNavigation = (nav) => {
    this.setState({
      startMonth: nav === "prev" ? 0 : 6,
      endMonth: nav === "prev" ? 5 : 11,
    });
  };

  getItineraryData = async () => {
    try {
      const { packageID } = this.state;
      await Axios.get(`${UopUrl}/availability?id=${packageID}`, fizoheader)
        .then((res) => {
          if (res.data !== undefined && res.data.length > 0) {
            this.getDateArray(res.data);
            // let dateRange = [...this.state.dateRange];
            // dateRange[0].startDate = new Date(res.data[0].start_date);
            // dateRange[0].endDate = new Date(res.data[0].end_date);
            this.setState(
              {
                packSlot: res.data,
                tempPackSlot: res.data,
                // dateRange: dateRange,
                allowedSection: [...this.state.allowedSection, "pricing"],
              },
              () => {
                let includedSeason = [];
                var packIndex = this.state.tempPackSlot.length - 1;
                this.savePackageSlot(packIndex, 1);
                this.state.packSlot.map((slot) => {
                  if (!includedSeason.includes(slot.season)) {
                    includedSeason.push(slot.season);
                  }
                });
                this.setState({ includedSeason: includedSeason });
              }
            );
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  convertToDateFormat = (inputDatetimeStr) => {
    const inputDatetime = moment(inputDatetimeStr);

    return inputDatetime.format("YYYY-MM-DD");
  };

  localString = (inputDate) => {
    return new Date(inputDate).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  };

  getDateArray = (packSlot) => {
    let dateArray = [];
    packSlot.map((slot) => {
      for (
        let currentDate = new Date(slot.start_date);
        currentDate <= new Date(slot.end_date);
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        dateArray.push(new Date(currentDate));
      }
    });
    this.setState({ dateArray: [...new Set(dateArray)] });
  };

  addDatesToDateArray = (index) => {
    let selected = [];
    for (
      let currentDate = new Date(
        this.state.tempPackSlot[this.state.slotIndex].start_date
      );
      currentDate <=
      new Date(this.state.tempPackSlot[this.state.slotIndex].end_date);
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      selected.push(new Date(currentDate));
    }
    const temp = [...this.state.dateArray, ...selected];
    $.magnificPopup.close();
    this.setState({
      dateArray: [...new Set(temp)],
      selectedTab: 2,
      isFormEdited: false,
      dateRange: [
        {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      ],
      selectionRange: [],
      editIndex: null,
      slotIndex: this.state.tempPackSlot.length - 1,
    });
  };

  removeDatesFromDateArray = (index) => {
    let selected = [];
    // packSlot.map((slot) => {
    for (
      let currentDate = new Date(this.state.tempPackSlot[index].start_date);
      currentDate <= new Date(this.state.tempPackSlot[index].end_date);
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      selected.push(moment(new Date(currentDate)).format("DD-MM-YYYY"));
    }
    const temp = this.state.dateArray.filter(
      (item) => !selected.includes(moment(item).format("DD-MM-YYYY"))
    );
    // });
    this.setState({ dateArray: [...new Set(temp)] });
  };

  handleDate = (ranges, index) => {
    try {
      const { packSlot, dateRange } = this.state;
      let tempPackSlot = [...this.state.tempPackSlot];
      let dateArray = [...this.state.dateArray];
      let start_date = this.convertToDateFormat(ranges.selection.startDate);
      let end_date = this.convertToDateFormat(ranges.selection.endDate);

      if (!dateArray.includes(start_date) && !dateArray.includes(end_date)) {
        // If the start_date of the current slot is not already in dateArray
        tempPackSlot[index].start_date = start_date;
        tempPackSlot[index].end_date = end_date;
        tempPackSlot[index].start_dates = [];

        // Add start_date to dateArray
        dateArray.push(start_date);
        this.setState({
          selectionRange: [],
          tempPackSlot,
          dateRange: [ranges.selection],
          dateError: "",
          isFormEdited: true,
        });
        if (
          !moment(ranges?.selection.startDate).isSame(ranges?.selection.endDate)
        ) {
          let elem = document.getElementById("slot");
          elem.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        // If the start_date of the current slot is already in dateArray
        tempPackSlot[index].start_date = new Date();
        tempPackSlot[index].end_date = new Date();
        this.setState({
          tempPackSlot,
          dateRange,
          dateError: "Choose Different date",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  addTravellers = (traveller, act, index) => {
    try {
      // Create a copy of the passengers array
      var tempPackSlot = [...this.state.tempPackSlot];
      var count = tempPackSlot[index][traveller] || 1;
      if (act === "add") {
        count += 1;
      } else {
        count !== 0 ? (count -= 1) : 0;
      }
      tempPackSlot[index][traveller] = count;
      this.setState({ tempPackSlot: tempPackSlot, isFormEdited: true });
    } catch (err) {
      console.log(err);
    }
  };

  savePackageSlot = (index, tab) => {
    try {
      const { dateRange } = this.state;
      let tempPackSlot = this.state.tempPackSlot.filter(
        (slot) =>
          !slot.start_date?.toString().includes("GMT") && slot.season !== ""
      );
      console.log(tempPackSlot);
      if (tempPackSlot[index]) {
        const { season, guest_capacity, start_date, end_date, start_day } =
          tempPackSlot[index];
        console.log(season, guest_capacity, start_date, end_date, start_day);
        const isFilled =
          season !== "" &&
          guest_capacity !== 0 &&
          !start_date.toString().includes("GMT") &&
          !end_date.toString().includes("GMT") &&
          start_date !== end_date &&
          Array.isArray(start_day) &&
          start_day.length > 0;

        if (!season) {
          toast.error("Season is not selected");
        } else if (guest_capacity < 2) {
          toast.error("Guest capacity must be greater than 1");
        } else if (!start_date || start_date.toString().includes("GMT")) {
          toast.error("Invalid start date");
        } else if (!end_date || end_date.toString().includes("GMT")) {
          toast.error("Invalid end date");
        } else if (start_date === end_date) {
          toast.error("Start date and end date should not be the same");
        } else if (!Array.isArray(start_day) || start_day.length === 0) {
          toast.error("Start day is not selected");
        } else if (isFilled) {
          const newSlot = {
            season: "",
            start_date: new Date(),
            end_date: new Date(),
            guest_capacity: 1,
            start_day: [],
            start_dates: [],
            timing: this.state.dayTourTiming,
          };

          this.setState(
            (prevState) => ({
              selectedTab: tab,
              packSlot: tempPackSlot,
              dateRange,
              tempPackSlot: [...tempPackSlot, newSlot],
            }),
            () => {
              const packIndex = this.state.tempPackSlot.length - 1;
              this.getDateArray(this.state.packSlot);
              this.currentSlot(packIndex, tab);
            }
          );

          $("html, body").animate(
            {
              scrollTop: parseFloat($("#availability").position().top) - 100,
            },
            500
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (event, index, key) => {
    try {
      var tempPackSlot = [...this.state.tempPackSlot];
      tempPackSlot[index][key] =
        key === "start_day" ? event : event.target.value;
      this.setState({ tempPackSlot: tempPackSlot, isFormEdited: true });
      // select the day into array to show the bottom of input
      const selectedDay = this.state.tempPackSlot[index][key][0].value;
      this.setState((prevState) => {
        if (prevState.selectedDays.includes(selectedDay)) {
          return prevState;
        }
        return {
          selectedDays: [...prevState.selectedDays, selectedDay],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  editPackage = () => {};

  deletePackage = (index) => {
    try {
      var slotLength = this.state.packSlot.length;
      if (slotLength > 1) {
        this.setState(
          (prevState) => {
            const newSlot = {
              season: "",
              start_date: null,
              end_date: null,
              guest_capacity: 1,
              start_day: [],
              start_dates: [],
            };
            const packSlot = [...prevState.packSlot];
            const updatedPackSlot = packSlot.filter((_, idx) => idx !== index);
            console.log(updatedPackSlot);
            return {
              packSlot: updatedPackSlot,
              tempPackSlot: [...updatedPackSlot, newSlot],
              isFormEdited: true,
            };
          },
          () => {
            var packIndex = this.state.tempPackSlot.length - 1;
            this.setState({ slotIndex: packIndex });
          }
        );
        this.removeDatesFromDateArray(index);
      }
    } catch (err) {
      console.log(err);
    }
  };

  currentSlot = (index, tab, action) => {
    try {
      const { tempPackSlot } = this.state;
      let dateRange = [...this.state.dateRange];
      this.setState(
        { slotIndex: index, editIndex: action ? index : null },
        () => {
          var slotIndex = this.state.slotIndex;
          if (action === "edit") {
            this.removeDatesFromDateArray(index);
            if (tempPackSlot[index]?.start_dates?.length) {
              const startDates =
                typeof tempPackSlot[index]?.start_dates[0].startDate ===
                "string"
                  ? tempPackSlot[index]?.start_dates?.map((item) => {
                      return {
                        ...item,
                        startDate: new Date(item?.startDate),
                        endDate: new Date(item?.endDate),
                      };
                    })
                  : tempPackSlot[index]?.start_dates;

              this.setState({
                selectionRange: startDates,
                selectedTab: tab,
                dateRange: [
                  {
                    startDate: null,
                    endDate: new Date(""),
                    key: "selection",
                  },
                ],
                isFormEdited: true,
              });
            } else {
              dateRange[0].startDate = new Date(
                tempPackSlot[slotIndex].start_date
              );
              dateRange[0].endDate = new Date(tempPackSlot[slotIndex].end_date);
              this.setState({
                dateRange,
                selectedTab: tab,
                selectionRange: [],
                isFormEdited: true,
              });
            }
          } else {
            this.setState({ selectedTab: tab });
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  uploadAvailability = async () => {
    try {
      const { newPackage } = this.state;
      this.setState({ isFormEdited: false });
      const slots = this.state.packSlot?.map((slot) => {
        return {
          ...slot,
          start_dates: slot?.start_dates?.length
            ? slot?.start_dates?.map((item) =>
                typeof item?.startDate === "string" &&
                item?.startDate === moment(item?.startDate).format("YYYY-MM-DD")
                  ? item
                  : moment(item?.startDate).format("YYYY-MM-DD")
              )
            : [],
          start_day: slot?.start_day?.map((item) =>
            typeof item === "string" ? item : item?.value
          ),
        };
      });
      var packageDetails = {
        creator_id: this.state.CUserId,
        creator_package_id: this.state.creator_package_id,
        slots: slots,
      };
      let res;
      if (newPackage) {
        res = await Axios.post(
          `${UopUrl}/availability`,
          packageDetails,
          fizoheader
        );
      } else {
        res = await Axios.put(
          `${UopUrl}/availability/update`,
          packageDetails,
          fizoheader
        );
      }

      console.log(res);
      if (res?.data?.message === "success") {
        let allowedSection = [...this.state.allowedSection];
        let postedSection = [...this.state.postedSection];
        let includedSeason = [];
        this.state.packSlot.map((slot) => {
          if (!includedSeason.includes(slot.season)) {
            includedSeason.push(slot.season);
          }
        });
        if (!allowedSection.includes("pricing")) {
          allowedSection.push("pricing");
        }
        if (!postedSection.includes("availability")) {
          postedSection.push("availability");
        }
        let packageDetails = this.props.location.state;
        packageDetails["allowedSection"] = allowedSection;
        packageDetails["postedSection"] = postedSection;
        packageDetails["includedSeason"] = includedSeason;
        packageDetails["newPackage"] = newPackage;
        this.props.history.push({
          pathname: "/myaccount/pricing",
          state: packageDetails,
        });
      } else {
        this.setState({
          openModal: true,
          popupTitle: "Failed",
          popupMsg: "Failed to save",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  addMoreSlot = () => {
    this.setState({ selectedTab: 1 });
  };

  showPopup() {
    $.magnificPopup.open({
      items: {
        src: "#confirm_popup",
      },
      type: "inline",
    });
  }

  handleTabChange(val) {
    if (this.state.isFormEdited && this.state.editIndex !== null) {
      this.showPopup();
      return;
    }
    this.setState({
      selectedTab: val,
      selectionRange: [],
      dateRange: [
        {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      ],
    });
  }

  render() {
    const { allowedSection, includedSeason } = this.state;
    console.log("thiss", this.state.tempPackSlot);
    let stateValues = this.props?.location?.state || {};
    stateValues["includedSeason"] = includedSeason;
    return (
      <>
        <section className="main-blue-bg" id="availability">
          <div className="container-full">
            <SubHeader
              triggerAction={this.uploadAvailability}
              allowedSection={allowedSection}
              stateValues={stateValues}
            />

            <ToastContainer />
            <div className="availability-main">
              <div className="availability-main__tabRow">
                <div
                  className={`availability-main__tabRow__tabCol ${
                    this.state.selectedTab === 1 ? "active" : ""
                  }`}
                  onClick={() => this.handleTabChange(1)}
                >
                  Calendar
                </div>
                <div
                  className={`availability-main__tabRow__tabCol ${
                    this.state.selectedTab === 2 ? "active" : ""
                  }`}
                  onClick={() => this.handleTabChange(2)}
                >
                  Slot
                  <div className="slot-count">
                    <span>
                      {this.state.packSlot ? this.state.packSlot.length : "0"}
                    </span>
                  </div>
                </div>
              </div>
              {this.state.selectedTab === 1 ? (
                <>
                  {this.state.tempPackSlot.map((slot, index) => {
                    return (
                      <>
                        {this.state.slotIndex === index && (
                          <div
                            className="availability-main__col step-content-calender"
                            key={index}
                          >
                            <div className="availability-main__col__header">
                              <div className="availability-main__col__title">
                                Calendar & Capacity
                                <div className="availability-main__col__sub">
                                  Select available days
                                </div>
                              </div>

                              <div className="availability-main__col__header__monthNav">
                                <ArrowBackIosIcon
                                  onClick={() =>
                                    this.state.startMonth !== 0 &&
                                    this.monthNavigation("prev")
                                  }
                                />
                                {moment()
                                  .add(this.state.startMonth, "month")
                                  .format("MMM YYYY")}{" "}
                                -{" "}
                                {moment()
                                  .add(this.state.endMonth, "month")
                                  .format("MMM YYYY")}
                                <ArrowForwardIosIcon
                                  onClick={() =>
                                    this.state.endMonth === 5 &&
                                    this.monthNavigation("next")
                                  }
                                />
                              </div>
                            </div>
                            <br />
                            <div className="availability-main__col__datepicker">
                              {/* <div className="availability-main__col__datepicker__peak">
                            <select
                              name="selectedGender"
                              value={slot?.season}
                              onChange={(e) => this.handleChange(e, index)}
                            >
                              <option value="">Select</option>{" "}
                              <option value="peak">Peak</option>{" "}
                              <option value="non-peak">Non-peak</option>
                            </select>
                          </div> */}
                              <DateRange
                                // showMonthAndYearPickers={false}
                                // showDateDisplay={false}
                                showMonthAndYearPickers={false}
                                editableDateInputs={true}
                                startDatePlaceholder={"Start Date"}
                                endDatePlaceholder={"End Date"}
                                onChange={(range) =>
                                  this.handleDate(range, index)
                                }
                                moveRangeOnFirstSelection={false}
                                // retainEndDateOnFirstSelection={false}
                                onShownDateChange={(range) =>
                                  console.log("range", range)
                                }
                                ranges={
                                  this.state.selectionRange?.length
                                    ? this.state.selectionRange
                                    : this.state.dateRange
                                }
                                disabledDates={this.state.dateArray}
                                minDate={new Date()}
                                months={12}
                                direction="horizontal"
                                // deafultDateRange={{
                                //   startDate: null,
                                //   endDate: new Date(""),
                                //   key: "selection",
                                // }}
                                // initialFocusedRange={ this.state.selectionRange?.length
                                //   ? this.state.selectionRange
                                //   : this.state.dateRange}
                                // focusedRange={[1]}
                                rangeColors={["#4258bf"]}
                                showPreview={false}
                                preventSnapRefocus
                              />
                            </div>
                            {/* ))} */}
                            {this.state.dateError !== "" && (
                              <p>{this.state.dateError}</p>
                            )}
                            <Divider />
                            <br />
                            <div id="slot">
                              <ul>
                                <li>
                                  <div className="addcart_row">
                                    <div className="sel-quality">
                                      No of guest capacity
                                      <div>Per day</div>
                                    </div>
                                    <div className="qty-bx">
                                      <span
                                        className="qty-minus"
                                        onClick={() =>
                                          this.addTravellers(
                                            "guest_capacity",
                                            "sub",
                                            index
                                          )
                                        }
                                      >
                                        <img
                                          className="minus-ic"
                                          src={minusb}
                                          alt="Minus"
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="qty-input"
                                        readOnly={true}
                                        value={slot?.guest_capacity || 1}
                                      />
                                      <span
                                        className="qty-plus"
                                        onClick={() =>
                                          this.addTravellers(
                                            "guest_capacity",
                                            "add",
                                            index
                                          )
                                        }
                                      >
                                        <img
                                          className="plus-ic"
                                          src={plusb}
                                          alt="Plus"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="addcart_row">
                                    <div className="sel-quality">
                                      Tour start day{" "}
                                      <div>
                                        Select if your tour start only on
                                        particular day
                                      </div>
                                    </div>
                                    <div className="availability-main__col__datepicker__start">
                                      <Select
                                        value=""
                                        selectMultiple={true}
                                        placeholder="Select day"
                                        isMulti
                                        onChange={(e) =>
                                          this.handleChange(
                                            e,
                                            index,
                                            "start_day"
                                          )
                                        }
                                        options={[
                                          { value: "sunday", label: "Sunday" },
                                          { value: "monday", label: "Monday" },
                                          {
                                            value: "tuesday",
                                            label: "Tuesday",
                                          },
                                          {
                                            value: "wednesday",
                                            label: "Wednesday",
                                          },
                                          {
                                            value: "thursday",
                                            label: "Thursday",
                                          },
                                          { value: "friday", label: "Friday" },
                                          {
                                            value: "saturday",
                                            label: "Saturday",
                                          },
                                        ]}
                                      />
                                      {this.state.selectedDays.length > 0 && (
                                        <div className="availability-main__col__datepicker__start__days">
                                          {this.state.selectedDays &&
                                            this.state.selectedDays?.map(
                                              (val, i) => {
                                                return <p key={i}>{val}</p>;
                                              }
                                            )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="addcart_row">
                                    <div className="sel-quality">
                                      Slot season{" "}
                                      <div>Select your slot season</div>
                                    </div>
                                    <div className="availability-main__col__datepicker__peak">
                                      <select
                                        name="selectedGender"
                                        value={slot?.season}
                                        onChange={(e) =>
                                          this.handleChange(e, index, "season")
                                        }
                                      >
                                        <option value="">Select</option>{" "}
                                        <option value="peak">Peak</option>{" "}
                                        <option value="non-peak">
                                          Non-peak
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </li>
                                {/* <li>
                              <div className="addcart_row">
                                <div className="sel-quality">
                                  Child
                                  <div>Per day</div>
                                </div>
                                <div className="qty-bx">
                                  <span
                                    className="qty-minus"
                                    onClick={() =>
                                      this.addTravellers("child", "sub", index)
                                    }
                                  >
                                    <img
                                      className="minus-ic"
                                      src={minusb}
                                      alt="Minus"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    className="qty-input"
                                    readOnly={true}
                                    value={slot?.child}
                                  />
                                  <span
                                    className="qty-plus"
                                    onClick={() =>
                                      this.addTravellers("child", "add", index)
                                    }
                                  >
                                    <img
                                      className="plus-ic"
                                      src={plusb}
                                      alt="Plus"
                                    />
                                  </span>
                                </div>
                              </div>
                            </li> */}
                                {/* <li>
                              <div className="addcart_row">
                                <div className="sel-quality">
                                  Senior
                                  <div>Per day</div>
                                </div>
                                <div className="qty-bx">
                                  <span
                                    className="qty-minus"
                                    onClick={() =>
                                      this.addTravellers("senior", "sub", index)
                                    }
                                  >
                                    <img
                                      className="minus-ic"
                                      src={minusb}
                                      alt="Minus"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    className="qty-input"
                                    readOnly={true}
                                    value={slot?.senior}
                                  />
                                  <span
                                    className="qty-plus"
                                    onClick={() =>
                                      this.addTravellers("senior", "add", index)
                                    }
                                  >
                                    <img
                                      className="plus-ic"
                                      src={plusb}
                                      alt="Plus"
                                    />
                                  </span>
                                </div>
                              </div>
                            </li> */}
                              </ul>
                            </div>
                            <div className="save-btn">
                              <button
                                onClick={() => this.savePackageSlot(index, 2)}
                              >
                                Save slot
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <div className="availability-main__col2">
                  <div className="availability-main__col2__title">
                    Package availability slots
                  </div>
                  <div className="availability-main__col2__sub">
                    Please edit necessary information to proceed with your
                    package submission.
                  </div>
                  <br />
                  <ul>
                    {this.state.packSlot.length > 0 &&
                      this.state.packSlot.map((slot, index) => {
                        return (
                          <>
                            <li
                              id={index}
                              className={`availability-main__col2__box ${
                                index === this.state.editIndex
                                  ? "availability-main__col2__active"
                                  : ""
                              }`}
                            >
                              <div>
                                <div>
                                  {" "}
                                  <p className="title">{slot?.season}</p>
                                  <div className="row">
                                    <div>
                                      <p className="date">
                                        {this.localString(slot?.start_date)} to{" "}
                                        {this.localString(slot?.end_date)}
                                      </p>
                                      <p className="date">
                                        {slot?.guest_capacity} guestCapacity,
                                      </p>
                                    </div>
                                    <div>
                                      {index !== 0 && (
                                        <Button
                                          variant="text"
                                          startIcon={<DeleteIcon />}
                                          onClick={() =>
                                            this.deletePackage(index)
                                          }
                                        >
                                          Delete
                                        </Button>
                                      )}
                                      <Button
                                        variant="text"
                                        onClick={() =>
                                          this.currentSlot(index, 1, "edit")
                                        }
                                      >
                                        Edit
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                  <div className="availability-main__col2__addBtn">
                    <div className="save-btn">
                      <button onClick={this.addMoreSlot}>Add more slots</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div id="confirm_popup" className="mfp-hide common-popup">
            <div className="custom_alert textcenter">
              <h2 className="text-uppercase">Warning</h2>
              <p>You have unsaved changes. Are you sure you want to leave? </p>
              <div
                className="alt_btns"
                style={{ gap: "10px", display: "inline-flex" }}
              >
                <a
                  className="button confirm-button ghost-button"
                  onClick={this.addDatesToDateArray}
                >
                  Yes
                </a>
                <a
                  href={void 0}
                  className="button popup-modal-dismiss disbl_href_action"
                >
                  No
                </a>
              </div>
            </div>
          </div>
          <ModalPopup
            type="success"
            title={this.state.popupTitle}
            message={this.state.popupMsg}
            open={this.state.openModal}
            handleClose={() => this.setState({ openModal: false })}
            // handleChange={handleChange}
            // handleSubmit={handleAuth}
          />
          <UnsavedChangesWarning
            message="You have unsaved changes. Are you sure you want to leave?"
            isFormEdited={this.state.isFormEdited}
          />
        </section>
      </>
    );
  }
}

const UnsavedChangesWarning = ({ message, isFormEdited }) => {
  useUnsavedChangesWarning(message, isFormEdited);
  return null;
};

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}
