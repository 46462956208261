/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import "./Dashboard.scss";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {
  apiUrl,
  fizoheader,
  headerconfig,
  apiglobaltixUrl,
  apiglobaltixMediaUrl,
} from "../Helpers/Config";
import actinfo1 from "../../common/images/act-info1.jpg";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heart-fill.svg";
import car from "../../common/images/car-icon.svg";
import ticketact from "../../common/images/ticket-activity.svg";
import hotel from "../../common/images/hotel-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import plus from "../../common/images/add-plus.svg";
// import plus from "../../common/images/plus.svg";
import bcal from "../../common/images/calender-banner.svg";
import bpin from "../../common/images/map-pin.svg";
import bprofile from "../../common/images/banner-profile.svg";
import mergeb from "../../common/images/merge.png";
import logo from "../../common/images/logo.svg";

import yellowcard from "../../common/images/yellow-card.svg";
import bluecard from "../../common/images/blue-card.svg";

import larrow from "../../common/images/link-arrow.svg";
import uparrow from "../../common/images/arrow-up.svg";
import bcalender from "../../common/images/calender-banner.svg";

import Autocomplete from "../Layout/Autocomplete";
import Select from "react-select";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Topmenu from "../Layout/Topmenu";
import { GET_CUSTOMER_DETAIL } from "../../actions";
import CreateNewPackage from "../Package/createNewPackage";
import { Stack, Typography } from "@mui/material";
import ModalPopup from "./ModalPopup/ModalPopup";
var trendoption = {
  items: 3,
  loop: true,
  dots: true,
  nav: false,
  margin: 25,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      margin: 10,
      stagePadding: 20,
    },
    580: {
      items: 2,
      margin: 20,
      stagePadding: 30,
    },
    980: {
      items: 3,
      stagePadding: 40,
    },
  },
};

const data = [
  { name: "Jan", uv: 400, YAxis: "" },
  { name: "Feb", uv: 300, YAxis: "" },
  { name: "Mar", uv: 200, YAxis: "" },
  { name: "Apr", uv: 278, YAxis: "" },
  { name: "May", uv: 189, YAxis: "" },
  { name: "Jun", uv: 239, YAxis: "" },
  { name: "Jul", uv: 339, YAxis: "" },
  { name: "Aug", uv: 639, YAxis: "" },
  { name: "Sep", uv: 139, YAxis: "" },
  { name: "Oct", uv: 239, YAxis: "" },
  { name: "Nov", uv: 39, YAxis: "" },
  { name: "Dec", uv: 439, YAxis: "" },
];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "&.MuiSwitch-input": {
    position: "absolute",
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#4258bf",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

class Listpromo extends Component {
  constructor(props) {
    super(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    this.logoutPopup = this.logoutPopup.bind(this);
    this.confirmLogout = this.confirmLogout.bind(this);

    this.state = {
      CUserId: CUserId,
      customerdetail: "",
      isLoading: true,
      airport: [],
      selectedairport: [],
      numberofdays: "",
      whomgoing: [],
      address: "",
      packagecountry: "",
      packageCity: "",
      pacakgeLat: "",
      triggercontinue: false,
      packageLon: "",
      error_address: false,
      error_numberofdays: false,
      error_whomgoing: false,
      error_selectedairport: false,
      packageLoading: true,
      triggerAddproduct: false,
      triggerPackCreation: false,
      cityList: [],
      selectedpackagecity: [],
      airport_id: "",
      selectedOption: "7days",
      creator: [],
      chartData: [],
      X_Axis: [],
      Y_Axis: "",
      YAxisDataKey: "",
      xAxisDataKey: "",
      thanDays: "than last 7 days",
      active: "Clicks",
      totalclicks: "",
      totalbooking: "",
      totalrevenue: "",
      bookingrate: "",
      dashboardType: "normal",
    };
    /*  if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "customer_id=" + cookie.load("UserId");
      this.props.getCustomerDetail(params);
    } */
  }
  componentDidMount() {
    this.loadCity();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    this.fetchData();
    // this.chartData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOption !== this.state.selectedOption) {
      if (this.state.dashboardType === "normal") {
        this.fetchData();
      } else {
        this.getUopPackageDetails();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
    }
  }
  openPopup() {
    $.magnificPopup.open({
      items: {
        src: "#createpackage",
      },
      type: "inline",
      midClick: true,
    });
  }
  loadAirport() {
    axios
      .get(apiUrl + "settings/getAirport?country=" + this.state.packagecountry)
      .then((res) => {
        var airport = [];
        if (res.data.status === "ok") {
          res.data.result_set.map((item) => {
            airport.push({
              value: item.airport_id,
              label: item.airport_name + " - " + item.airport_code,
            });
          });
          this.setState({ airport: airport });
        }
      });
  }

  loadCity() {
    axios.get(apiUrl + "settings/getCityList", fizoheader).then((res) => {
      if (res.data.status === "ok") {
        var cityList = [];
        if (Object.keys(res.data.result_set).length > 0) {
          Object.keys(res.data.result_set).map((item) => {
            var selectedCountry = res.data.result_set[item];
            selectedCountry.city.map((city) => {
              cityList.push({
                label: city.city_name + ", " + city.country_name,
                value:
                  city.airport_address +
                  "~" +
                  city.airport_code +
                  "~" +
                  city.airport_id +
                  "~" +
                  city.airport_latitude +
                  "~" +
                  city.airport_longitude +
                  "~" +
                  city.airport_name +
                  "~" +
                  city.city_code +
                  "~" +
                  city.city_id +
                  "~" +
                  city.city_name +
                  "~" +
                  city.country_code +
                  "~" +
                  city.country_name +
                  "~" +
                  city.webbeds_city_code,
              });
            });
          });
        }
        this.setState(
          {
            cityList: cityList,
          },
          function () {
            console.log(cityList, "citylist");
          }
        );
      }
    });
  }
  /* var trendoption = {
    items: 2,
    loop: true,
    dots: false,
    nav: false,
    margin: 40,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      580: {
        items: 2,
        margin: 30,
      },
      980: {
        margin: 40,
      },
    },
  }; */

  getFormattedDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}`;
  };
  fetchData() {
    axios
      .get(
        apiUrl +
          "dashboard/activity?creator_id=" +
          this.state.CUserId +
          "&days=" +
          this.state.selectedOption,
        fizoheader
      )
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data, "creator");
          this.setState({
            creator: response.data,
            chartData: response.data.package_click_history,
            totalclicks:
              response.data.statistics_result.packageclick_percentage_diff,

            totalbooking:
              response.data.statistics_result.bookingclick_percentage_diff,
            totalrevenue:
              response.data.statistics_result.commission_percentage_diff,
            bookingrate:
              response.data.statistics_result.bookingrate_percentage_diff,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  graphData(Total) {
    console.log(Total, "total");
    var x_Axis = "";
    if (
      (Total === "Clicks" && this.state.selectedOption === "7days") ||
      (Total === "Clicks" && this.state.selectedOption === "4weeks") ||
      (Total === "Clicks" && this.state.selectedOption === "12months")
    ) {
      var commission = this.state.creator.package_click_history;
      console.log(commission, "booking");
      this.setState({
        chartData: commission,
      });
      console.log(this.state.chartData, "chart");
    } else if (
      (Total === "Bookings" && this.state.selectedOption === "7days") ||
      (Total === "Bookings" && this.state.selectedOption === "4weeks") ||
      (Total === "Bookings" && this.state.selectedOption === "12months")
    ) {
      var commission = this.state.creator.package_booking;
      console.log(commission, "booking");
      this.setState({ chartData: commission });
    } else if (
      (Total === "Commissions" && this.state.selectedOption === "7days") ||
      (Total === "Commissions" && this.state.selectedOption === "4weeks") ||
      (Total === "Commissions" && this.state.selectedOption === "12months")
    ) {
      var commission = this.state.creator.creator_commission;
      console.log(commission, "booking");
      console.log(commission, "creator");
      this.setState({
        chartData: commission,
      });
    }
    this.setState({
      active: Total,
    });
  }
  handleButtonClick(option) {
    this.setState({ selectedOption: option });
    if (option === "7days") {
      this.setState({
        thanDays: "than last 7 days",
      });
    } else if (option === "4weeks") {
      this.setState({
        thanDays: "than last 30 days",
      });
    } else if (option === "12months") {
      this.setState({
        thanDays: "than last 12 months",
      });
    }
  }
  getLast7DatesFromToday() {
    const today = new Date();
    const last7Dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      last7Dates.push(date.toISOString().split("T")[0]);
    }
    return last7Dates;
  }
  loadRatingstar(points) {
    if (points === "" || points === null) {
      points = "0.0";
    }
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) >= 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        } else {
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }
  onChange(name, value) {
    console.log(name, value, "valuevalue");
    if (name === "selectedpackagecity") {
      var splitValue = value.value.split("~");
      console.log(splitValue, "splitValuesplitValue");
      this.setState({
        [name]: value,
        address: splitValue[0],
        packagecountry: splitValue[10],
        pacakgeLat: splitValue[3],
        packageLon: splitValue[4],
        packageCity: splitValue[8],
        airport_id: splitValue[2],
      });
    } else {
      this.setState({ [name]: value });
    }
  }
  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value, [name + "_error"]: "" });
  }
  createPackage() {
    var error = 0;
    if (this.state.address === "") {
      error++;
      this.setState({ error_address: true });
    }
    if (
      this.state.numberofdays === "" ||
      !/^[0-9]*$/.test(this.state.numberofdays)
    ) {
      error++;
      this.setState({ error_numberofdays: true });
    } else {
      const numberOfDays = parseInt(this.state.numberofdays, 10);

      if (numberOfDays < 2 || numberOfDays > 15) {
        error++;
        this.setState({ error_numberofdays: true });
      } else {
        this.setState({ error_numberofdays: false });
      }
    }

    if (Object.keys(this.state.whomgoing).length === 0) {
      error++;
      this.setState({ error_whomgoing: true });
    }
    if (error === 0) {
      var packagedetails = {
        address: this.state.address,
        packagecountry: this.state.packagecountry,
        packageCity: this.state.packageCity,
        pacakgeLat: this.state.pacakgeLat,
        packageLon: this.state.packageLon,
        numberofdays: this.state.numberofdays,
        whomgoing: this.state.whomgoing,
        selectedairport: { value: this.state.airport_id },
        newpackage: "newpackage",
      };
      console.log(packagedetails, "pakageddetauks");
      $.magnificPopup.close();
      this.props.history.push({
        pathname: "/myaccount/create-package",
        state: packagedetails,
      });
    }
  }
  logoutPopup() {
    $.magnificPopup.open({
      items: {
        src: "#remove_popup",
      },
      type: "inline",
    });
  }
  confirmLogout() {
    $.magnificPopup.close();
    cookie.remove("CUserId", { path: "/" });
    this.props.history.push("/");
  }

  handlePopup = (action) => {
    this.setState({ triggerAddproduct: action });
  };
  openPackage = () => {
    this.setState({ triggerPackCreation: true });
  };
  continuePackage = () => {
    this.setState(
      {
        triggerAddproduct: false,
      },
      () => {
        this.setState({ triggerPackCreation: false, triggercontinue: true });
      }
    );
  };

  getUopPackageDetails = async () => {
    axios
      .get(
        apiUrl +
          "dashboard/uopactivity?creator_id=" +
          this.state.CUserId +
          "&days=" +
          this.state.selectedOption,
        fizoheader
      )
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data, "creator");
          this.setState({
            creator: response.data,
            chartData: response.data.package_click_history,
            totalclicks:
              response.data.statistics_result.packageclick_percentage_diff,

            totalbooking:
              response.data.statistics_result.bookingclick_percentage_diff,
            totalrevenue:
              response.data.statistics_result.commission_percentage_diff,
            bookingrate:
              response.data.statistics_result.bookingrate_percentage_diff,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  handlePackageType = (event) => {
    const { checked } = event.target;
    this.setState(
      {
        dashboardType: checked ? "uop" : "normal",
      },
      () => {
        if (this.state.dashboardType === "uop") {
          this.getUopPackageDetails();
        } else {
          this.fetchData();
        }
      }
    );
  };

  render() {
    const PackagePerct =
      this.state.creator.statistics_result &&
      this.state.creator.statistics_result.packageclick_percentage_diff > 0;
    const Bookingperct =
      this.state.creator.statistics_result &&
      this.state.creator.statistics_result.bookingclick_percentage_diff > 0;
    const commisionperct =
      this.state.creator.statistics_result &&
      this.state.creator.statistics_result.commission_percentage_diff > 0;
    const bookingrateperct =
      this.state.creator.statistics_result &&
      this.state.creator.statistics_result.bookingrate_percentage_diff > 0;
    console.log(this.state.creator.creator_details, "this.state.creator");
    return (
      <>
        {this.state.isLoading === false ? (
          <div className="creator-dashboard-main">
            <div className="creator-dashboard-main__header">
              <div className="package-tools">
                <div className="title-with-description">
                  <h2>
                    Creator <span>dashboard</span>
                  </h2>
                  <p>Get insights about your creations</p>
                </div>
                <div className="three-merge-btn">
                  <button
                    className={`${
                      this.state.selectedOption === "7days" ? "active" : ""
                    }`}
                    onClick={() => this.handleButtonClick("7days")}
                  >
                    Week
                  </button>
                  <button
                    className={`${
                      this.state.selectedOption === "4weeks" ? "active" : ""
                    }`}
                    onClick={() => this.handleButtonClick("4weeks")}
                  >
                    Month
                  </button>
                  <button
                    className={`${
                      this.state.selectedOption === "12months" ? "active" : ""
                    }`}
                    onClick={() => this.handleButtonClick("12months")}
                  >
                    Year
                  </button>
                </div>
                <div className="pt-button">
                  <a
                    href={void 0}
                    onClick={() => this.handlePopup(true)}
                    className="button ghost-button"
                  >
                    <img src={plus} alt="" /> Add product
                  </a>
                </div>
              </div>

              <Stack direction="row" spacing={1} alignItems="center">
                <Typography className="creator-dashboard-main__header__label">
                  Normal
                </Typography>
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={this.state.dashboardType === "uop"}
                  onChange={this.handlePackageType}
                />
                <Typography className="creator-dashboard-main__header__label">
                  UOP
                </Typography>
              </Stack>
              <br />
              <div className="box-graph-actives">
                <div className="box-graph-merge">
                  <div className="four-box-graph">
                    <div
                      onClick={() => this.graphData("Clicks")}
                      className={`fbg-inner ${
                        PackagePerct ? "up-box" : "down-bx"
                      } ${this.state.active === "Clicks" ? "active" : ""}`}
                    >
                      <h6>Total Clicks</h6>
                      <p>
                        {
                          this.state.creator?.statistics_result
                            ?.package_click_total_count
                        }
                      </p>
                      <span>
                        <strong>
                          {Math.abs(
                            parseFloat(this.state.totalclicks).toFixed(2)
                          )}
                          %{" "}
                          {Math.abs(
                            parseFloat(this.state.totalclicks).toFixed(2)
                          ) === 0 ? (
                            ""
                          ) : (
                            <img src={uparrow} alt="Arrow" />
                          )}
                        </strong>{" "}
                        {this.state.thanDays}
                      </span>
                    </div>{" "}
                    <div
                      onClick={() => this.graphData("Bookings")}
                      className={`fbg-inner
                        ${Bookingperct ? "up-box" : "down-bx"}  ${
                        this.state.active === "Bookings" ? "active" : ""
                      }`}
                    >
                      <h6>Total Booking</h6>
                      <p>
                        {" "}
                        {
                          this.state.creator?.statistics_result
                            ?.package_booking_total_count
                        }
                      </p>
                      <span>
                        <strong
                          className={
                            this.state.totalbooking === 0
                              ? "strong"
                              : "dast-strong"
                          }
                        >
                          {Math.abs(
                            parseFloat(this.state.totalbooking).toFixed(2)
                          )}
                          %{" "}
                          {Math.abs(
                            parseFloat(this.state.totalbooking).toFixed(2)
                          ) === 0 ? (
                            ""
                          ) : (
                            <img src={uparrow} alt="Arrow" />
                          )}
                        </strong>{" "}
                        {this.state.thanDays}
                      </span>
                    </div>{" "}
                    <div
                      onClick={() => this.graphData("Commissions")}
                      className={`fbg-inner ${
                        commisionperct ? "up-box" : "down-bx"
                      }  ${
                        this.state.active === "Commissions" ? "active" : ""
                      }`}
                    >
                      <h6>Total Revenue</h6>
                      <p>
                        ${" "}
                        {
                          this.state.creator?.statistics_result
                            ?.commission_total_amount
                        }
                      </p>
                      <span>
                        <strong>
                          {Math.abs(
                            parseFloat(this.state.totalrevenue).toFixed(2)
                          )}
                          %{" "}
                          {Math.abs(
                            parseFloat(this.state.totalrevenue).toFixed(2)
                          ) === 0 ? (
                            ""
                          ) : (
                            <img src={uparrow} alt="Arrow" />
                          )}
                        </strong>{" "}
                        {this.state.thanDays}
                      </span>
                    </div>{" "}
                    <div
                      className={`fbg-inner  ${
                        bookingrateperct ? "up-box" : "down-bx"
                      }`}
                    >
                      <h6>Booking Rate</h6>
                      <p>
                        {
                          this.state.creator?.statistics_result
                            ?.package_booking_rate
                        }
                        %
                      </p>
                      <span>
                        <strong>
                          {Math.abs(
                            parseFloat(this.state.bookingrate).toFixed(2)
                          )}
                          %{" "}
                          {Math.abs(
                            parseFloat(this.state.bookingrate).toFixed(2)
                          ) === 0 ? (
                            ""
                          ) : (
                            <img src={uparrow} alt="Arrow" />
                          )}
                        </strong>{" "}
                        {this.state.thanDays}
                      </span>
                    </div>{" "}
                  </div>
                  <div className="chart-parent">
                    <div className="chart-resposive">
                      <ResponsiveContainer>
                        <LineChart
                          margin={{
                            top: 10,
                            right: 10,
                            left: 0,
                            bottom: 0,
                          }}
                          data={this.state.chartData.slice().reverse()}
                        >
                          <CartesianGrid strokeDasharray="3" />

                          <XAxis dataKey={"label"} />
                          <YAxis domain={[0, 400]} dataKey="value" />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#4258bf"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>

                <div className="yellblue-actives">
                  <div className="creator-package cx-box">
                    <ul>
                      <li className="oe-box-yellow">
                        <img
                          src={yellowcard}
                          alt="Arrow"
                          className="acard-bg"
                        />
                        <h2>
                          {this.state.creator?.package_stat?.package_count}
                        </h2>
                        <p>
                          {" "}
                          Active <br />
                          packages{" "}
                        </p>
                        <span>
                          {" "}
                          <strong>
                            4.3% <img src={uparrow} alt="Arrow" />
                          </strong>
                          than last 30 days
                        </span>
                      </li>
                      <li className="oe-box-blue">
                        <img src={bluecard} alt="Arrow" className="acard-bg" />
                        <h2>
                          {this.state.creator?.package_stat?.total_destination}
                        </h2>
                        <p>
                          {" "}
                          Total <br />
                          destination{" "}
                        </p>
                        <span>
                          {" "}
                          <strong>
                            4.3% <img src={uparrow} alt="Arrow" />
                          </strong>
                          than last 30 days
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}

        <ModalPopup
          type="addProduct"
          state={this.state}
          open={this.state.triggerAddproduct}
          handleClose={() => this.handlePopup(false)}
          openPackage={this.openPackage}
          continuePackage={this.continuePackage}
          handleChange={this.handleChange}
          onChange={(name, value) => this.onChange(name, value)}
          createPackage={this.createPackage.bind(this)}
        />
        <ModalPopup
          state={this.state}
          type="packCreation"
          open={this.state.triggercontinue}
          handleClose={() => this.setState({ triggercontinue: false })}
          continuePackage={this.continuePackage}
          onChange={(name, value) => this.onChange(name, value)}
          cityList={this.state.cityList}
          handleChange={this.handleChange.bind(this)}
          createPackage={this.createPackage.bind(this)}
        />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
