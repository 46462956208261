import { SET_GOOGLECREATORLOGINDATA } from "../actions";

const googlelogincreator = (state = [], action) => {
    switch (action.type) {
        case SET_GOOGLECREATORLOGINDATA:
            return [...action.value];
        default:
            return state;
    }
};

export default googlelogincreator;
