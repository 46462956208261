/* eslint-disable */
import React, { useEffect, useState } from "react";
import { showPriceValue } from "../Helpers/SettingHelper";

const StayFilterSheet = (props) => {
  const {
    showFiltersOption,
    closeFiltersOption,
    amenities_List,
    amenitiesFilter,
    state,
    handleApplyFilter,
    filterByRating,
    resetFilter,
    currentThis,
  } = props;

  const [amenities, setAmenities] = useState(amenitiesFilter);

  const handleChangeAmenities = (type, value) => {
    if (type === "amenities") {
      const list = [...amenities];
      var index = list.indexOf(value);
      if (index > -1) {
        list.splice(index, 1);
      } else {
        list.push(value);
      }
      setAmenities(list);
    }
  };

  useEffect(() => {
    $("#rang-mobile").ionRangeSlider({
      min: state.startPrice,
      max: state.endPrice,
      from: state.startPrice,
      to: state.endPrice,
      type: "double",
      grid_num: 10,
      onFinish: function (data) {
        currentThis.setState(
          { startPrice: data.from, endPrice: data.to },
          function () {
            currentThis.displayHotelList();
          }
        );
      },
    });
  }, [state]);

  const applyFilter = () => {
    if (showFiltersOption === "amenities") {
      handleApplyFilter(showFiltersOption, amenities);
    }
    closeFiltersOption();
  };

  return (
    <div className="container">
      {amenities_List.length > 0 && showFiltersOption === "amenities" && (
        <>
          {amenities_List.map((item, index) => {
            return (
              <div
                className={`option ${amenities.includes(item) ? "active" : ""}`}
                key={index}
                onClick={() => {
                  handleChangeAmenities("amenities", item);
                }}
              >
                {item}
              </div>
            );
          })}
        </>
      )}
      {showFiltersOption === "pricerange" && (
        <div className="filter-option pricerange-option">
          <p>
            {showPriceValue(state.startPrice)} to{" "}
            {showPriceValue(state.endPrice)}+
          </p>
          <div>
            <input
              type="text"
              id="rang-mobile"
              name="rang"
              value=""
              data-min={Math.round(state.startPrice)}
              data-max={Math.round(state.endPrice)}
              data-from={state.startPrice}
              data-to={state.endPrice}
              data-type="double"
              data-prefix="SGD$"
              data-grid="false"
              data-grid-num="10"
            />
            {/* <a
                      href={void 0}
                    //   onClick={this.resetFilter.bind(this, "price")}
                      className="button ghost-button"
                    >
                      Reset
                    </a> */}
          </div>
        </div>
      )}
      {showFiltersOption === "rating" && (
        <div className="filter-option rating-option no-margin-btm">
          <ul>
            <li
              className={
                state.selectedrating === "Luxury *****" ? "active" : ""
              }
            >
              <a
                href={void 0}
                onClick={() => {
                  filterByRating("Luxury *****");
                  closeFiltersOption();
                }}
              >
                5 Excellent (28)
              </a>
            </li>
            <li
              className={
                state.selectedrating === "Superior ****" ? "active" : ""
              }
            >
              <a
                href={void 0}
                onClick={() => {
                  filterByRating("Superior ****");
                  closeFiltersOption();
                }}
              >
                4 Good (48)
              </a>
            </li>
            <li
              className={
                state.selectedrating === "Standard ***" ? "active" : ""
              }
            >
              <a
                href={void 0}
                onClick={() => {
                  filterByRating("Standard ***");
                  closeFiltersOption();
                }}
              >
                3 Okay (79)
              </a>
            </li>
            <li
              className={state.selectedrating === "Budget **" ? "active" : ""}
            >
              <a
                href={void 0}
                onClick={() => {
                  filterByRating("Budget **");
                  closeFiltersOption();
                }}
              >
                2 Poor (79)
              </a>
            </li>
            <li className={state.selectedrating === "Economy*" ? "active" : ""}>
              <a
                href={void 0}
                onClick={() => {
                  filterByRating("Economy*");
                  closeFiltersOption();
                }}
              >
                1 Terrible (79)
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="filter-action actionMain">
        {showFiltersOption === "amenities" && (
          <a
            href={void 0}
            className="button"
            onClick={() => {
              applyFilter();
            }}
          >
            Apply
          </a>
        )}
        <a
          href={void 0}
          className="button ghost-button"
          onClick={() => {
            resetFilter(
              showFiltersOption === "pricerange"
                ? "price"
                : showFiltersOption === "rating"
                ? "rating"
                : "amenities"
            );
            closeFiltersOption();
          }}
        >
          Clear
        </a>
      </div>
    </div>
  );
};

export default StayFilterSheet;
