/* eslint-disable */
import $ from "jquery";
import axios from "axios";
import cookie from "react-cookies";
import {
  apiglobaltixUrl,
  loginuser,
  loginpwd,
  headerconfig,
  cookieConfig,
  googleMapsKey,
} from "../Helpers/Config";
var qs = require("qs");
/* stripslashes  */
export const stripslashes = function (str) {
  if (
    str !== "" &&
    str !== null &&
    typeof str !== undefined &&
    typeof str !== "undefined"
  ) {
    str = str.replace(/\\'/g, "'");
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, "\0");
    str = str.replace(/\\\\/g, "\\");
    return str;
  }
};

/* Random ID  */
export const randomId = function () {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 50; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};
/* show Alert */
export const showAlert = function (
  header,
  message,
  autoClose = "No",
  addedClass = ""
) {
  $(".alert_popup").remove();
  if (autoClose === "No") {
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls ' +
        addedClass +
        '" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:;" class="popup-modal-dismiss button">OK</a></div></div></div></div></div></div>'
    );
  } else {
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls ' +
        addedClass +
        '" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"></div></div></div></div></div></div>'
    );
  }
};

/* show Custom Alert */
export const showCustomAlert = function (type, message) {
  var clsIdTxt = type === "success" ? "jquery-success-msg" : "jquery-error-msg";
  $(".custom_alertcls").hide();
  $("." + clsIdTxt).html(message);
  $("#" + clsIdTxt).fadeIn();
  setTimeout(function () {
    $(".custom_alertcls").hide();
  }, 6000);
};

/* show Custom Center Alert */
export const showCustomCenterAlert = function (type, message) {
  var clsIdTxt =
    type === "success"
      ? "jquery-common-success-msg"
      : "jquery-common-error-msg";
  $(".custom_center_alertcls").hide();
  $("." + clsIdTxt).html(message);
  $("#" + clsIdTxt).fadeIn();
  setTimeout(function () {
    $(".custom_center_alertcls").hide();
  }, 6000);
};

/* show Loader */
export const showLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>');
  } else {
    $("#" + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>');
  }
};

/* hide Loader */
export const hideLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef).removeClass("loader-main-cls");
    $("." + divRef)
      .find(".loader-sub-div")
      .remove();
  } else {
    $("#" + divRef).removeClass("loader-main-cls");
    $("#" + divRef)
      .find(".loader-sub-div")
      .remove();
  }
};

/* sample funtion */
export const showPriceValue = function (
  price,
  currentCurrency = "",
  currencyRate = ""
) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = "";
  if (currentCurrency == "SGD") {
    priceTxt += "SGD $";
  } else if (currentCurrency == "USD") {
    priceTxt += "USD $";
    if (currencyRate?.[currentCurrency]) {
      price = price * currencyRate?.[currentCurrency];
    }
  } else if (currentCurrency == "INR") {
    priceTxt += "INR ₹";
    if (currencyRate?.[currentCurrency]) {
      price = price * currencyRate?.[currentCurrency];
    }
  } else {
    priceTxt += "SGD $";
  }

  priceTxt += price.toFixed(2);
  return priceTxt;
};

export const showCurrency = function (currentCurrency = "", currencyRate = "") {
  var priceTxt = "";
  if (currentCurrency == "SGD") {
    priceTxt += "SGD $";
  } else if (currentCurrency == "USD") {
    priceTxt += "USD $";
  } else if (currentCurrency == "INR") {
    priceTxt += "INR ₹";
  } else {
    priceTxt += "SGD $";
  }
  return priceTxt;
};

export const showAmount = function (
  price,
  currentCurrency = "",
  currencyRate = ""
) {
  price = price !== "" ? parseFloat(price) : 0.0;
  if (currentCurrency == "USD") {
    if (currencyRate?.[currentCurrency]) {
      price = price * currencyRate?.[currentCurrency];
    }
  } else if (currentCurrency == "INR") {
    if (currencyRate?.[currentCurrency]) {
      price = price * currencyRate?.[currentCurrency];
    }
  }

  var priceTxt = price.toFixed(2);
  return priceTxt;
};

export const showPercentage = function (percentage) {
  if (percentage !== "" && percentage !== null) {
    var splitprice = percentage.split(".");
    var result = splitprice[0];
    if (splitprice[1] != "00") {
      result += ".".splitprice[1];
    }
    return result;
  }
};

/* sample funtion */
export const validateEmailFun = function (mailIdVal) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mailIdVal)) {
    return true;
  }

  return false;
};

/* Uc first funtion */
export const jsUcfirstFun = function (string) {
  if (string !== "" && string !== null) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  } else {
    return "";
  }
};

export const generateToken = function () {
  var qs = require("qs");
  var postObject = {
    username: loginuser,
    password: loginpwd,
  };

  axios
    .post(apiglobaltixUrl + "auth/login", postObject, headerconfig)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success === true) {
          cookie.save("access_token", res.data.data.access_token, cookieConfig);
        }
      }
    });
};

export const calculateAircoins = function (totalAmount, pointpercentage) {
  var earnPoints = 0;
  if (parseFloat(totalAmount) > 0 && parseFloat(pointpercentage) > 0) {
    var fixedAmt = totalAmount * (pointpercentage / 100);
    var aircoins = fixedAmt * 10;
    earnPoints = aircoins.toFixed(2);
  }
  return earnPoints;
};
export const calculateReddemAircoins = function (totalAmount) {
  var reddem = 0;
  if (parseFloat(totalAmount) > 0) {
    reddem = (parseFloat(totalAmount) / 10).toFixed(2);
  }
  return reddem;
};

export const checkingDiscount = function (discountDetails, subTotal) {
  try {
    var discount = "";
    if (discountDetails !== "" && parseFloat(subTotal) > 0) {
      var discount_type = discountDetails?.discount_type;
      var discount_value = discountDetails?.discount_value;
      var discount_title = discountDetails?.discount_title;
      var discount_max_amount = discountDetails?.discount_max_amount;
      var discount_id = discountDetails?.discount_id;

      var discountDisply = "";
      var discountAmount = 0;
      if (discount_type === "P") {
        discountDisply = discount_value + "%";
        discountAmount = (
          parseFloat(subTotal) *
          (discount_value / 100)
        ).toFixed(2);
        if (discountAmount < discount_max_amount) {
          discountAmount = discount_max_amount;
        }
      } else {
        discountAmount = discount_value;
      }
      discount = {
        title: discount_title,
        discountDisply: discountDisply,
        amount: discountAmount,
        discount_id: discount_id,
      };
    }
    return discount;
  } catch (err) {
    console.log(err);
  }
};
export const showDays = function (startDate, endDate) {
  if (
    startDate !== "" &&
    startDate !== null &&
    endDate !== "" &&
    endDate !== null
  ) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(startDate);
    const secondDate = new Date(endDate);

    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays;
  }
};
export const loadRatingNumber = function (points) {
  if (
    points !== "" &&
    points !== null &&
    typeof points !== "undefined" &&
    typeof points !== undefined
  ) {
    if (points === "Economy*") {
      points = "1.0";
    } else if (points === "Budget **") {
      points = "2.0";
    } else if (points === "Standard ***") {
      points = "3.0";
    } else if (points === "Superior ****") {
      points = "4.0";
    } else if (points === "Luxury *****") {
      points = "5.0";
    } else if (points === "Serviced Apartment") {
      points = "0.0";
    } else if (points === "Unrated") {
      points = "0.0";
    }
    return points;
  }
};
export const loadDistance = function () {
  var src = axios
    .get(
      "https://maps.googleapis.com/maps/api/distancematrix/json?destinations=-8.394923,115.621393&origins=-8.654820,115.131493&units=km&key=" +
        googleMapsKey
    )
    .then(function (response) {
      return response.data; // the response.data is string of src
    })
    .catch(function (response) {
      console.log(response);
    });
  // doing other something
  return src;
};

export const subtractHours = function (date, hours) {
  date.setHours(date.getHours() - hours);
  return date;
};
