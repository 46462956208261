/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_UPDATECREATORPROFILE, SET_UPDATECREATORPROFILE } from "../actions";
import { apiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetUpdateCreatorProfile = function* () {
  yield takeEvery(GET_UPDATECREATORPROFILE, workerGetUpdateCreatorProfile);
};

function* workerGetUpdateCreatorProfile({ formPayload }) {
  try {
    const result = yield call(getUpdateCreatorProfile, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_UPDATECREATORPROFILE, value: resultArr });
  } catch {
    console.log("Update failed");
  }
}

function getUpdateCreatorProfile(formPayload) {
  return Axios.post(apiUrl + "creators/updateprofile", formPayload, fizoheader);
}
