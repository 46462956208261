/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfig(props) {
  const { email, password } = props.fields;

  return {
    fields: ["email", "password"],
    validations: {
      email: [[isEmpty, email]],
      password: [[isEmpty, password]],
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordType: "password",
    };
  }

  showhidePWD() {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  }
  openPopup(popupID) {
    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
      midClick: true,
    });
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgEmail, errMsgPassword;

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <div className="error">{$validation.email.error.reason}</div>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <div className="error">{$validation.password.error.reason}</div>
      );
    }

    return (
      <div className="cp-form-inner">
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter your email"
            value={fields.email}
            {...$field("email", (e) => onChange("email", e.target.value))}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                this.props.$submit(onValid, onInvalid);
              }
            }}
          />
          {errMsgEmail}
        </div>
        <div className="form-group pwd-group no-margin-btm">
          <input
            type={this.state.passwordType}
            placeholder="Password"
            value={fields.password}
            {...$field("password", (e) => onChange("password", e.target.value))}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                this.props.$submit(onValid, onInvalid);
              }
            }}
          />
          {errMsgPassword}
          <span className={this.state.passwordType === "text" ? "active" : ""}>
            <i
              className="fa fa-eye-slash"
              aria-hidden="true"
              onClick={this.showhidePWD.bind(this)}
            ></i>
            <i
              className="fa fa-eye"
              aria-hidden="true"
              onClick={this.showhidePWD.bind(this)}
            ></i>
          </span>
        </div>
        <div className="forgot-link textright">
          <a
            href={void 0}
            onClick={this.openPopup.bind(this, "#customer-forget-popup")}
          >
            Forgot password?
          </a>
        </div>
        <div className="form-button">
          <button
            className="button login_submit"
            onClick={(e) => {
              e.preventDefault();
              this.props.$submit(onValid, onInvalid);
            }}
          >
            Login
          </button>
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
