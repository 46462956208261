import React, { Component } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

export default class PrivacyPolicy extends Component {
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <section className="innersection">
          <div className="container">
            {" "}
            <h1>Privacy Policy</h1>
            <p>
              {" "}
              Airvays Technologies Pte Ltd (“Fizota”) is committed to protecting
              our customer's privacy. Please take the time to read this Privacy
              Policy which explains what information we collect about you, how
              we use it, and your rights. Airvays Technologies Pte Ltd (“Fizota“
              “we” “us” “our”) is the data controller of the personal data
              collected via or in connection with{" "}
              <a
                href={"https://www.fizota.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.fizota.com
              </a>
              (the “Site”). <br />
              <br />
              The individuals who book travel packages are referred to as
              consumers, users, customers, or travellers ("Users" or
              "Customers"), including the individuals who create, advertise, and
              earn commissions from the sale of travel packages ("Travel
              Creators"). These policies apply to all Users of the platform,
              including both Customers and Travel Creators.
            </p>
            <p>
              <b>What personal data do we collect about you?</b>
              <br />
              We collect personal data from you when you provide it to us
              directly and through your use of the Site. This information may
              include:
              <ul>
                <li>
                  Information you provide to us when you book packages or any
                  individual itinerary (e.g., your name, passport number, expiry
                  date,);
                </li>
                <li>
                  Information you provide to us when you sign up for our
                  Services (e.g., your full name, email address, phone number,);
                </li>
                <li>
                  Records of your interactions with us (e.g., if you contact our
                  customer service team, interact with us on social media);
                </li>
                <li>
                  Information you provide us when you enter a
                  competition/promotion or participate in a survey;
                </li>
                <li>
                  Information is collected automatically, using cookies and
                  other tracking technologies (e.g., which pages you viewed and
                  whether you clicked on a link in one of our email updates). We
                  may also collect information about the device you use to
                  access our Site; and
                </li>
                <li>
                  Other information is necessary to provide the Site, for
                  example, we may access your location to provide a better user
                  experience, if you give us your consent.
                </li>
              </ul>
            </p>
            <p>
              <b>What do we use this personal data for?</b>
              <br />
              <br />
              Depending on how you use our Site, your interactions with us, and
              the permissions you give us, the purposes for which we use your
              personal data include:
              <ul>
                <li>
                  To manage and respond to any queries or complaints to our
                  customer service team.
                </li>
                <li>
                  To personalise the Site to you and show you content we think
                  you will be most interested in.
                </li>
                <li>To improve and maintain the Site and monitor its usage.</li>
                <li>
                  For market research, e.g., we may contact you for feedback
                  about our services.
                </li>
                <li>
                  To send you marketing messages and show you targeted
                  advertising, where we have your consent or are otherwise
                  permitted to do so.
                </li>
                <li>
                  For security purposes, to investigate fraud and where
                  necessary to protect ourselves and third parties.
                </li>
                <li>To comply with our legal and regulatory obligations.</li>
                We rely on the following legal basis, under data protection law,
                to process your personal data:
                <li>
                  Because the processing is necessary to perform a contract with
                  you or take steps prior to entering into a contract with you
                  (e.g., where you have made a purchase with us, we use your
                  personal data to process the payment and fulfil your order).
                </li>
                <li>
                  Because we have obtained your consent (e.g., when you contact
                  us with a query, or if you consent to receive marketing from
                  us).
                </li>
                <li>
                  We use information about you to tailor your view of the Site,
                  to make it more interesting and relevant in respect of the
                  services and offers on view.
                </li>
              </ul>
            </p>
            <p>
              <b>Marketing</b>
              <br />
              <br />
              Depending upon your marketing preferences, we may use your
              personal data to send you marketing messages, newsletters, etc. by
              email. Some of these messages may be tailored to you, based on
              your previous browsing, and other information we hold about you.
              <br />
              <br />
              If you no longer want to receive marketing communications from us
              (or would like to opt back in!), you can change your preferences
              at any time by contacting us (details below) and clicking on the
              ‘unsubscribe’ link in any email. If you unsubscribe from
              marketing, please note we may still contact you with service
              messages from time to time (e.g., information about your legal
              rights).
              <br />
              <br /> You may also see ads for our Site on third-party websites,
              including on social media. These ads may be tailored to you using
              cookies (which track your web activity, so enable us to serve ads
              to customers who have visited our Site). Where you see an ad on
              social media, this may be because we have engaged the social
              network to show ads to our customers, or users who match the
              demographic profile of our customers. In some cases, this may
              involve sharing your email address with the social network. If you
              no longer want to see tailored ads you can change your cookie and
              privacy settings on your browser and these third-party websites.
              <br />
              <br />
              <b>Who do we share this personal data with?</b>
              <br />
              <br />
              We share customers’ personal data with third parties in the
              following circumstances:
              <ul>
                <li>
                  With our trusted suppliers as necessary to operate the Site.
                </li>
                <li>With our professional and legal advisors.</li>
                <li>
                  With third parties engaged in fraud prevention and detection.
                </li>
                <li>
                  With law enforcement or other governmental authorities, e.g.,
                  to report fraud or in response to a lawful request.
                </li>
                <br />
                If we sell any business assets, the personal data of our
                customers may be disclosed to a potential buyer. In this event,
                we will make reasonable attempts to ensure the buyer will be
                bound by the terms of this Privacy Policy. Otherwise, where we
                have your consent or are otherwise legally permitted to do so.
              </ul>
            </p>
            <p>
              <b>
                DATA PROTECTION PROVISIONS ABOUT THE APPLICATION AND USE OF
                GOOGLE ANALYTICS (WITH ANONYMIZATION FUNCTION)
              </b>
              <br />
              <br />
              On this Platform, the controller has integrated the component of
              Google Analytics (with the anonymizer function). Google Analytics
              is a web analytics service. Web analytics is the collection,
              gathering, and analysis of data about the behaviour of visitors to
              websites. A web analysis service collects, inter alia, data about
              the website from which a person has come (the so-called referrer),
              which sub-pages were visited, or how often and for what duration a
              sub-page was viewed. Web analytics are mainly used for the
              optimization of a website and in order to carry out a cost-benefit
              analysis of Internet advertising. The operator of the Google
              Analytics component is Google Inc., 1600 Amphitheatre Pkwy,
              Mountain View, CA 94043-1351, United States. For web analytics
              through Google Analytics, the controller uses the application
              “_gat. _anonymizeIp”. By means of this application, the IP address
              of the Internet connection of the data subject is abridged by
              Google and anonymized when accessing our Platform from a Member
              State of the European Union or another Contracting State to the
              Agreement on the European Economic Area. The purpose of the Google
              Analytics component is to analyse the traffic on our Platform.
              Google uses the collected data and information, among other
              things, to evaluate the use of our Platform to provide online
              reports, which show the activities on our Platform, and to provide
              other services concerning the use of our Platform for us. Google
              Analytics places a cookie on the information technology system of
              the data subject. The definition of cookies is explained above.
              With the setting of the cookie, Google is enabled to analyse the
              use of our Platform. With each call-up to one of the individual
              pages of this Platform, which is operated by the controller and
              into which a Google Analytics component was integrated, the
              Internet browser on the information technology system of the data
              subject will automatically submit data through the Google
              Analytics component for the purpose of online advertising and the
              settlement of commissions to Google. During the course of this
              technical procedure, the enterprise Google gains knowledge of
              personal information, such as the IP address of the data subject,
              which serves Google, inter alia, to understand the origin of
              visitors and clicks, and subsequently create commission
              settlements.
              <br /> <br />
              The cookie is used to store personal information, such as the
              access time, the location from which the access was made, and the
              frequency of visits to our Platform by the data subject. With each
              visit to our Platform, such personal data, including the IP
              address of the Internet access used by the data subject, will be
              transmitted to Google in the United States of America. These
              personal data are stored by Google in the United States of
              America. Google may pass the personal data collected through the
              technical procedure to third parties.
              <br />
              <br /> The data subject may, as stated above, prevent the setting
              of cookies through our Platform at any time by means of a
              corresponding adjustment of the web browser used and thus
              permanently deny the setting of cookies. Such an adjustment to the
              Internet browser used would also prevent Google Analytics from
              setting a cookie on the information technology system of the data
              subject. In addition, cookies already in use by Google Analytics
              may be deleted at any time via a web browser or other software
              programs.
              <br />
              <br /> In addition, the data subject has the possibility of
              objecting to a collection of data that are generated by Google
              Analytics, which is related to the use of this Platform, as well
              as the processing of this data by Google and the chance to
              preclude any such. For this purpose, the data subject must
              download a browser add-on under the link
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                https://tools.google.com/dlpage/gaoptout
              </a>{" "}
              and install it. This browser add-on tells Google Analytics through
              JavaScript that any data and information about the visits of the
              Platform may not be transmitted to Google Analytics. The
              installation of the browser add-ons is considered an objection by
              Google. If the information technology system of the data subject
              is later deleted, formatted, or newly installed, then the data
              subject must reinstall the browser add-ons to disable Google
              Analytics. If the browser add-on was uninstalled by the data
              subject or any other person who is attributable to their sphere of
              competence or is disabled, it is possible to execute the
              reinstallation or reactivation of the browser add-ons.
              <br />
              <br /> Further information and the applicable data protection
              provisions of Google may be retrieved under
              <a
                href="https://www.google.com/intl/en/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.com/intl/en/policies/privacy/
              </a>{" "}
              and under{" "}
              <a
                href="https://www.google.com/analytics/terms/us.html."
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.com/analytics/terms/us.html.
              </a>{" "}
              Google Analytics is further explained under the following Link
              <a
                href="https://www.google.com/analytics/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.com/analytics/.
              </a>
              <br />
              <br />{" "}
              <b>DATA PROTECTION PROVISIONS ABOUT THE USE OF MAILCHIMP.</b>
              <br />
              <br />
              On the Platform, the controller has integrated MailChimp.
              MailChimp is an online service provider which allows the
              advertising growth of businesses. The purpose of MailChimp’s
              integration is to promote the services of the website to existing
              users as well as to potential new users. MailChimp’s data privacy
              policy is further explained under the following link. <br />
              <br /> Privacy Policy:{" "}
              <a
                href="https://mailchimp.com/about/security/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mailchimp.com/about/security/
              </a>{" "}
              <br /> <br />
              <a
                href="https://mailchimp.com/legal/data-processing-addendum/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Data Processing Addendum:
                https://mailchimp.com/legal/data-processing-addendum/
              </a>
              <br />
              <br />
              <b>Hotjar</b>
              <br />
              <br />
              If you have given your consent to cookies, we use Hotjar in order
              to better understand our users' needs and to optimize our service
              and users' experience. This is provided by Hotjar Ltd, Level 2, St
              Julians Business Center, 3, Elia Zammit Street, St Julians STJ
              1000, Malta, Europe.
              <br />
              <br /> Hotjar is a technology service that helps us better
              understand our users' experience (e.g., how much time they spend
              on which pages, which links they choose to click, what users do
              and don't like, etc.) and this enables us to build and maintain
              our service by means of user feedback. Hotjar uses cookies and
              other technologies to collect data on our users' behaviour and
              their devices (in particular the IP address of the device
              (captured and stored only in anonymized form), device screen size,
              device type (unique device identifiers), browser information,
              geographic location (country only), preferred language used to
              display our website). Hotjar stores this information in a
              pseudonymized user profile. Neither Hotjar nor we will ever use
              this information to identify individual users or to match it with
              further data on an individual user. For further details, please
              see Hotjar's Privacy Policy.
              <br />
              <br /> If you do not want to be recorded by Hotjar, you can
              disable it by setting the DoNotTrack header in your browser. For
              more information and more about Hotjar's data processing, please
              visit{" "}
              <a
                href="https://www.hotjar.com/legal/compliance/opt-out"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.hotjar.com/legal/compliance/opt-out.
              </a>
              <br />
              <br />
              <b>Storage and Retention</b>
              <br />
              <br />
              The criteria used to determine the period of storage of personal
              data is the respective statutory retention period. After the
              expiration of that period, the corresponding data is routinely
              deleted, as long as it is no longer necessary for the fulfilment
              of the contract or the initiation of a contract.
              <br />
              <br /> Please note that where you unsubscribe from our marketing
              communications, we will keep a record of your email address to
              ensure we do not send you marketing emails in future.
              <br />
              <br />
              <b>Security</b>
              <br />
              <br />
              This Site ensures that data is encrypted when leaving the Site.
              This process involves the converting of information or data into a
              code to prevent unauthorised access.
              <br />
              <br /> Whilst we do everything within our power to ensure that
              personal data is protected at all times from our Site, we cannot
              guarantee the security and integrity of the information that has
              been transmitted to our Site.
              <br />
              <br />
              <b>Children</b>
              <br />
              <br />
              Our Site is not intended for, and should not be used by, children
              under the age of 18. We do not knowingly collect personal data
              from children under 18.
              <br />
              <br />
              <b>Cookies</b>
              <br />
              <br />
              Our Sites use cookies and similar technologies to provide certain
              functionality to the Site, to understand and measure their
              performance, and to deliver targeted advertising.
              <br />
              <br />
              <b>Your rights</b>
              <br />
              <br />
              You have certain rights in respect of your personal data,
              including the right to access, portability, correct, and request
              the erasure of your personal data. The processing of your personal
              data shall always be in line with the data protection laws of
              Singapore or the Personal Data Protection Act 2012 (“PDPA”).
              <br />
              <br /> You also have the right to object to your personal data
              being used for certain purposes, including to send you marketing.
              See ‘Marketing’ above, for more details of how to opt out of
              marketing.
              <br />
              <br /> We will comply with any requests to exercise your rights in
              accordance with applicable law. Please be aware, however, that
              there are a number of limitations to these rights, and there may
              be circumstances where we are not able to comply with your
              request. To make any requests regarding your personal data, or if
              you have any questions or concerns regarding your personal data,
              you should contact us using the details below. You are also
              entitled to contact your local supervisory authority for data
              protection.
              <br />
              <br />
              <b>
                Provision of personal data as a statutory or contractual
                requirement; Requirement necessary to enter into a contract;
                Obligation of the data subject to provide the personal data;
                possible consequences of failure to provide such data
              </b>
              <br />
              <br />
              We clarify that the provision of personal data is partly required
              by law (e.g., tax regulations) or can also result from contractual
              provisions (e.g., information on the contractual partner).
              Sometimes it may be necessary to conclude a contract that the data
              subject provides us with personal data, which must subsequently be
              processed by us. The data subject is, for example, obliged to
              provide us with personal data when our company signs a contract
              with him or her. The non-provision of the personal data would have
              the consequence that the contract with the data subject could not
              be concluded. Before personal data is provided by the data
              subject, the data subject must contact us. We will clarify to the
              data subject whether the provision of the personal data is
              required by law, or contract or is necessary for the conclusion of
              the contract, whether there is an obligation to provide the
              personal data and the consequences of non-provision of the
              personal data.
              <br />
              <br />
              <b>Contact Us</b>
              If you have any queries on any aspect of our Privacy Policy,
              please contact us using the details below:
              <br />
              <br />
              Airvays Technologies Pte Ltd <br /> <br /> Address: 7 soon lee
              street #02-45 ISPACE, Singapore 627608. <br /> <br /> Email:
              <a href="mailto: support@fizota.com"> support@fizota.com</a>{" "}
            </p>
          </div>
        </section>
        <Footer {...this.props} />{" "}
      </div>
    );
  }
}
