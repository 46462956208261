/* eslint-disable */
import { Box, CircularProgress, IconButton, Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import "./ModalPopup.scss";
import CloseIcon from "@mui/icons-material/Close";
import CustomEditor from "../CustomEditor/CustomEditor";
import bpin from "../../../common/images/map-pin.svg";
import bcalender from "../../../common/images/calender-banner.svg";
import Select from "react-select";
import bprofile from "../../../common/images/banner-profile.svg";
import { useHistory } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "40%",
  maxWidth: "40%",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30%",
  maxWidth: "30%",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "480px",
  maxWidth: "480px",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const ModalPopup = (props) => {
  const {
    type,
    open,
    handleClose,
    handleSubmit,
    handleChange,
    handleEditorChange,
    errorMsg,
    title,
    message,
    isLoading,
    state,
    onChange,
    continuePackage,
    respMsg,
    createPackage,
    openPackage,
  } = props;
  const Reasons = [
    "We can’t accommodate and host the tour.",
    "There is no availability for the specific days.",
    "Lack of tour guide/transportation availability.",
    "⁠Internal operational issue.",
    "Tour can’t be operated due to unforeseen circumstances.",
    "⁠Bad weather conditions.",
  ];

  const [productType, setProductType] = useState(null);

  const history = useHistory();

  const handleContinue = () => {
    if (productType === "uop") {
      history.push("/myaccount/add-myproducts");
    } else {
      continuePackage();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={
          type === "verifyPass" || type === "cancel"
            ? style1
            : type === "packCreation"
            ? style2
            : style
        }
        className="modal-popup"
      >
        <IconButton onClick={handleClose} className="close-btn">
          <CloseIcon htmlColor="#4258BF" />
        </IconButton>
        {type === "verifyPass" && (
          <div className="modal-popup__container">
            <div className="modal-popup__container__title">Verify It's You</div>
            <div className="modal-popup__container__main">
              <div className="form-group">
                <input
                  type="text"
                  name="password"
                  placeholder="password"
                  // value={this.state.pickUp}
                  onChange={handleChange}
                />
              </div>
              <p className="modal-popup__container__main__errMsg">
                {errorMsg !== "" && errorMsg}
              </p>
            </div>
            <a className="button button-fill" onClick={handleSubmit}>
              Submit
            </a>
          </div>
        )}
        {type === "cancel" && (
          <div className="modal-popup__container">
            <div className="modal-popup__container__title">
              Reason to Cancel
            </div>
            <div className="modal-popup__container__main">
              <div className="form-group">
                <select
                  name="cancReason"
                  // value={item.type}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {Reasons?.map((item, index) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
              </div>
              <div className="form-group">
                <textarea
                  id="textarea"
                  name="cancDetail"
                  placeholder="Reason.."
                  // value={this.state.day_description}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <a className="button ghost-button" onClick={handleSubmit}>
              Cancel
              {isLoading && <CircularProgress className="spinner" size={25} />}
            </a>
          </div>
        )}
        {type === "contact" && (
          <div className="modal-popup__container1">
            <div className="modal-popup__container1__title">
              Contact traveller
            </div>
            <div className="modal-popup__container1__main">
              <div className="form-group">
                <input
                  type="text"
                  name="title"
                  placeholder="title"
                  // value={this.state.pickUp}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <CustomEditor
                  // value={this.state.day_description}
                  onChange={handleEditorChange}
                />
              </div>
            </div>
            <a className="button button-fill" onClick={handleSubmit}>
              Send{" "}
              {isLoading && <CircularProgress className="spinner" size={25} />}
            </a>
          </div>
        )}
        {type === "addProduct" && (
          <div className="modal-popup__container">
            <div className="modal-popup__container__main1">
              <div
                className={`form-group ${
                  productType === "normal" ? "active" : ""
                }`}
              >
                <a href={void 0} onClick={() => setProductType("normal")}>
                  <h6 className="modal-popup__container__main1__text1">
                    I am travel influencer{" "}
                  </h6>
                  <p className="modal-popup__container__main1__text2">
                    Use fizota's wider inventory to craft unique packages
                  </p>
                </a>
              </div>
              <div
                className={`form-group ${
                  productType === "uop" ? "active" : ""
                }`}
              >
                <a href={void 0} onClick={() => setProductType("uop")}>
                  {/* href="" */}
                  <h6 className="modal-popup__container__main1__text1">
                    I am tour operator/ travel agent / DMC
                  </h6>
                  <p className="modal-popup__container__main1__text2">
                    Seamlessly distribute your packages to new set of digital
                    travellers
                  </p>
                </a>
              </div>
              <div className="modal-popup__container__main1__contBtn">
                <a
                  href={void 0}
                  className="button guest-button"
                  onClick={handleContinue}
                >
                  Continue
                </a>
              </div>
            </div>
          </div>
        )}
        {(type === "warning" || type === "success") && (
          <div className="modal-popup__container">
            <div className="modal-popup__container__main1">
              <div className="form-group bg-white">
                <h4>{title}</h4>
                <p>{message}</p>
                <button onClick={handleClose}>Ok</button>
              </div>
            </div>
          </div>
        )}
        {type === "packCreation" && (
          <div className="step-banner-form">
            <h3 className="textcenter color-header">
              Create unique <span>vacation</span>
            </h3>
            <div className="step-banner-whom step-banner-stay">
              <Select
                value={state.selectedpackagecity}
                placeholder="Select Package City"
                onChange={(val) => {
                  onChange("selectedpackagecity", val);
                }}
                options={state.cityList}
              />
              <img src={bpin} />
              {state.error_address === true && (
                <div className="error">This field is required</div>
              )}
            </div>
            <div className="step-banner-whom create-whom">
              <input
                type="text"
                placeholder="Number of days"
                value={state.numberofdays}
                name="numberofdays"
                onChange={(val) => {
                  handleChange(val);
                }}
              />
              <img src={bcalender} />
              {state.error_numberofdays === true && (
                <div className="error">
                  Please enter a number between 2 to 15
                </div>
              )}
            </div>

            <div className="step-banner-whom">
              <Select
                value={state.whomgoing}
                placeholder="Whom you are going with?"
                onChange={(val) => {
                  onChange("whomgoing", val);
                }}
                options={[
                  {
                    value: "Couple",
                    label: "Couple",
                  },
                  {
                    value: "Family",
                    label: "Family",
                  },
                  {
                    value: "Friends",
                    label: "Friends",
                  },
                  {
                    value: "Solo",
                    label: "Solo",
                  },
                ]}
              />
              <img src={bprofile} />
            </div>
            <button type="submit" className="button" onClick={createPackage}>
              Create Packages
            </button>
          </div>
        )}
        {type === "respMsg" && (
          <div className="modal-popup__container1">
            <div className="modal-popup__container1__title">Message</div>
            <div className="modal-popup__container1__main">
              <div className="form-group">
                <h3>{respMsg}</h3>
              </div>
            </div>
            <a className="button button-fill" onClick={handleSubmit}>
              ok
            </a>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default ModalPopup;
