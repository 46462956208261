import { all } from "redux-saga/effects";
import { watchGetSettings } from "./settings";
import { watchGetCountry } from "./country";
import { watchGetProductList } from "./productlist";
import { watchGetProductDetails } from "./productdetails";
import { watchGetSegmentList } from "./segmentlist";
import { watchGetTagList } from "./taglist";
import { watchGetCreatorLoginData } from "./creatorlogin";
import { watchGetFbLoginData } from "./fblogin";
import { watchGetCreatorFbLoginData } from "./fblogincreator";
import { watchGetGoogleLoginData } from "./googlelogin";
import { watchGetGoogleLoginDataCreator } from "./googlelogincreator";
import { watchGetCustomerDetail } from "./customerdetail";
import { watchGetCreatorDetail } from "./creatordetail";
import { watchGetForgetPassword } from "./forgetpassword";
import { watchGetCreatorRegistration } from "./creatorregistration";
import { watchGetChangePassword } from "./changepassword";
import { watchGetUpdateCustomerProfile } from "./updatecustomerprofile";
import { watchGetUpdateCreatorProfile } from "./updatecreatorprofile";
import { watchGetCustomerLoginData } from "./customerlogin";
import { watchGetCustomerRegistration } from "./customerregistration";
import { watchGetFavourite } from "./favourite";
import { watchGetFavouritelist } from "./favouritelist";
import { watchGetBookingHistory } from "./bookinghistory";
import { watchGetFunThings } from "./funthings";
import { watchGetSearchFlight } from "./searchflight";
import { watchGetMystiflyRevalidate } from "./mystiflyrevalidate";
import { watchGetMystiflyCheapestRevalidate } from "./mystiflycheapestrevalidate";
import { watchGetWishList } from "./wishlist";
import { watchGetGetUploadFiles } from "./uploadfiles";

export default function* () {
  yield all([
    watchGetSettings(),
    watchGetCountry(),
    watchGetProductList(),
    watchGetProductDetails(),
    watchGetSegmentList(),
    watchGetTagList(),
    watchGetCreatorLoginData(),
    watchGetFbLoginData(),
    watchGetCreatorFbLoginData(),
    watchGetGoogleLoginData(),
    watchGetGoogleLoginDataCreator(),
    watchGetCustomerDetail(),
    watchGetCreatorDetail(),
    watchGetForgetPassword(),
    watchGetCreatorRegistration(),
    watchGetChangePassword(),
    watchGetUpdateCustomerProfile(),
    watchGetUpdateCreatorProfile(),
    watchGetCustomerLoginData(),
    watchGetCustomerRegistration(),
    watchGetFavourite(),
    watchGetFavouritelist(),
    watchGetBookingHistory(),
    watchGetFunThings(),
    watchGetSearchFlight(),
    watchGetMystiflyRevalidate(),
    watchGetMystiflyCheapestRevalidate(),
    watchGetWishList(),
    watchGetGetUploadFiles(),
  ]);
}
