import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

export default class Termsandconditions extends Component {
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };
  render() {
    return (
      <div>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <section className="innersection">
          <div className="container">
            <p>
              <h1>Terms and Conditions</h1>
              <b>1. INTRODUCTION</b>
              <br />
              <br />
              Welcome to Airvays Technologies Pte Ltd (“Fizota"), a company
              based in Singapore. These Terms of Use regulate the use and access
              of
              <a
                href="https://www.fizota.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                https://www.fizota.com
              </a>
              , media form, media channel, and mobile website/app related,
              linked, or otherwise connected thereto (collectively the “Site”).
              Fizota provides information to users about particular countries
              and travel destinations via weekly newsletters. The Services
              provided by us are specified in more detail in Section 3 of these
              Terms of Use. <br />
              <br />
              These
              <Link>
                {" "}
                Terms of Use, Privacy Policy, Cookie Policy, Disclaimers
              </Link>{" "}
              and other Additional Terms we make available from time to time set
              out the legal obligation vis-à-vis terms and conditions for your
              interaction and usage of the Site (“Collectively “Terms”). This
              page contains a mandatory arbitration provision and regulates the
              resolution of any dispute that may arise between you and us as a
              result of using our Site. By clicking “Accept” or other synonymous
              terms when prompted or, using or accessing the Site, you
              acknowledge and agree that you will adhere to these Terms and all
              other operating rules, policies, and procedures that may be issued
              periodically on the Site by us, each of which is incorporated by
              reference periodically by us. If you do not agree to any of these
              Terms including the compulsory arbitration provision, you must
              stop using the Site.
              <br />
              <br /> The individuals who book travel packages are referred to as
              consumers, users, customers, or travellers ("Users" or
              "Customers"), including the individuals who create dynamic travel
              packages using Fizota’s inventory, Share the packages with others,
              and earn commissions from the sale of travel packages ("Travel
              Creators"). These terms and conditions apply to all Users of the
              platform, including both Customers and Travel Creators.
              <br />
              <br />
              By accepting these Terms, you agree that the Terms constitute a
              binding contract, effective as of the date of first acceptance by
              you, between Airvays Technologies Pte Ltd (hereinafter referred to
              as “Fizota”, “we”, “us”, or “our”) and you the user (hereinafter
              referred to as (“you”, “your”) (each a “Party” to the contract and
              collectively, the “Parties”).
              <br />
              <br />
              <b>2. ELIGIBILITY</b> <br />
              <br />
              <ol style={{ listStyleType: "none" }}>
                <li>
                  2.1 To use our Site, you must:
                  <ol style={{ listStyleType: "none" }}>
                    {" "}
                    <br />
                    <li>
                      2.1.1 Be at least of the age of majority and not
                      disqualified from entering into contracts under any law;{" "}
                    </li>{" "}
                    <br />
                    <li>2.1.2. complete the registration process; </li>
                    <br />
                    <li>2.1.3. agree to our Terms; and</li> <br />
                    <li>
                      2.1.4 provide true, complete, and up-to-date legal and
                      contact information
                    </li>{" "}
                    <br />
                    <li>
                      2.1.5. You represent and warrant that you have the
                      authority to accept these Terms on behalf of the company
                      you may be affiliated with.
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      2.1.6 By using Site, you represent and warrant that you
                      will use Site only for non-commercial purposes.
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      2.1.7. By using Site, you represent and warrant that you
                      meet all the requirements listed above and that you will
                      not use Site in a way that violates any laws or
                      regulations. Fizota may refuse service, to any users, and
                      change eligibility requirements at any time.
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      2.1.8. By accepting these Terms, you represent and warrant
                      that you are qualified concerning the conditions stated
                      herein, therefore, are permitted to use the Site. If you
                      do not meet any of the conditions stated herein, you shall
                      not access/use the Site and must cease to be a user.
                    </li>{" "}
                  </ol>
                </li>
              </ol>
              <br />
              <br />
              <b>3. SERVICES</b> <br />
              <br />
              By signing up and creating both the user and creator account on
              the site, you will be able to book, create, and share travel
              packages and earn a commission (For creators). Signing up as a
              user you provide email, contact and full name and as a creator you
              provide email, contact, full name and you channel hyperlinks and
              details for the payout. If you wish to discontinue using our
              service you can delete your account by logging in to “My Account”.{" "}
              <br />
              <br />
              By subscribing to our services by providing your email address on
              the Site, you will receive information about product/service
              content, marketing and promotional emails. If you wish to
              unsubscribe to the email service, please click on the
              “unsubscribe” button usually located at the bottom. When you click
              on “unsubscribe” or otherwise terminate our Services, our
              liability to you shall cease as of that moment in complete. All
              information contained in the email is subject to inaccuracies and
              errors, therefore we urge our users to read our
              <a href="#disclaimer1"> Disclaimers</a> for more details regarding
              the same, prior to using the Services. <br />
              <br />
              <b>4. PERSONAL DATA</b> <br />
              <br />
              To provide you with the Site as mentioned in these Terms it is
              required that we collect your basic information which may include
              your email address, contact number, full name and payout details
              (“Personal Data”). You agree that your Personal Data is collected
              by us through your consent. Please read our
              <Link to={"/privacy-policy"}> Privacy Policy</Link> for detailed
              information as to how we handle your Personal Data.
              <br />
              <br />
              <b>5. TERM</b> <br />
              <br />
              The Term begins as soon as you access the Site and continues as
              long as you use the Site and Services offered by us.
              <br />
              <br />
              <b>6. BOOKINGS</b> <br />
              <br />
              2.1 To use our Site, you must:
              <ol style={{ listStyleType: "none" }}>
                <br />
                <li>
                  6.1 Booking Confirmation: All bookings made with Fizota are
                  subject to confirmation and availability. The availability of
                  flights, hotels, activities, and transfers is subject to
                  change until the booking is confirmed.
                </li>
                <br />
                <li>
                  6.2 Payments and Refunds: Full payment is required at the time
                  of booking unless otherwise stated. Refunds for eligible
                  cancellations will be processed as per the guidelines
                  mentioned in the "Cancellation Policy" section. Refund amounts
                  may vary based on international exchange rates, refunds
                  received from suppliers, and payments received from customers
                  up to the date of cancellation. Any changes in the refund
                  amount will be communicated to customers via registered email.
                  Travel Documents: It is the responsibility of the user to
                  ensure that all travel documents, including passports, visas,
                  and health certificates, are valid and obtained prior to the
                  departure date. Fizota shall not be held liable for any
                  consequences arising from your failure to comply with the
                  necessary travel requirements.
                </li>
                <br />
                <li>
                  6.3 Changes and Cancellations: Any changes or cancellations to
                  bookings must be requested by submitting a cancellation
                  request form provided under “Booked packages” in the user
                  account. Additional fees, penalties, or charges may apply for
                  changes or cancellations, as determined by the suppliers and
                  service providers involved. Fizota will assist you to the best
                  of its abilities, but it cannot guarantee the acceptance of
                  all change or cancellation requests.
                </li>
                <br />
                <li>
                  6.4. Travel Advisories and Safety: It is your responsibility
                  to stay informed about any travel advisories, warnings, or
                  safety precautions issued by relevant authorities for the
                  destinations they plan to visit. Fizota recommends that
                  customers review travel advisories and take necessary
                  precautions to ensure their safety and well-being during the
                  trip.
                </li>
                <br />
              </ol>
              <br />
              <br />
              <b>7. USER REPRESENTATIONS</b> <br />
              <br />
              <ol style={{ listStyleType: "none" }}>
                <li>
                  7.1 You represent and warrant not to do the following: :
                  <ol style={{ listStyleType: "none" }}>
                    {" "}
                    <br />
                    <li>
                      7.1.1 Use, display, mirror or frame the Site or any
                      individual element within the Site, proprietary
                      information, or the layout and design of any page or form
                      contained on a page, without our express written consent;{" "}
                    </li>{" "}
                    <br />
                    <li>
                      Access, tamper with, or use non-public areas of the Site,
                      our computer systems, or the technical delivery systems of
                      our providers;
                    </li>
                    <br />
                    <li>
                      7.1.3. Attempt to probe, scan, or test the vulnerability
                      of any of our systems or network or breach any security or
                      authentication measures;
                    </li>{" "}
                    <br />
                    <li>
                      7.1.4 Avoid, bypass, remove, deactivate, impair,
                      descramble, or otherwise circumvent any technological
                      measure implemented by us or any of our providers or any
                      other third party (including another user) to protect the
                      Site or Site Content;
                    </li>{" "}
                    <br />
                    <li>
                      7.1.5. Attempt to access or search the Site or Site
                      Content or download Site Content from the Site through the
                      use of any engine, software, tool, agent, device, or
                      mechanism (including spiders, robots, crawlers, data
                      mining tools or the like) other than the software and/or
                      search agents provided by us or other generally available
                      third-party web browsers;
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.6 Send anyone any unsolicited or unauthorised
                      advertising, promotional materials, email, junk mail,
                      spam, chain letters or other form of solicitation;
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.7. Use any Meta tags or other hidden text or metadata
                      utilising our trademark(s), logo, URL or product name
                      without our express written consent;
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.8. Use the Site or Site Content, or any portion
                      thereof, for any commercial purpose or for the benefit of
                      any third party or in any manner not permitted by these
                      Terms;
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.9. Forge any TCP/IP packet header or any part of the
                      header information in any email or newsgroup posting, or
                      in any way use the Site or Site Content to send altered,
                      deceptive, or false source-identifying information;
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.10. Attempt to decipher, decompile, disassemble, or
                      reverse engineer any of the software used to provide the
                      Site or Site Content;
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.11. Interfere with, or attempt to interfere with, the
                      access of any user, host, or network, including, without
                      limitation, sending a virus, overloading, flooding,
                      spamming, or mail-bombing the Site;
                    </li>
                    <br />
                    <li>
                      {" "}
                      7.1.12. Collect or store any personally identifiable
                      information from the Site from other users of the Site
                      without their express permission;
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.13. Impersonate or misrepresent your affiliation with
                      any person or entity;
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.14. Violate any applicable law or regulation; or
                    </li>{" "}
                    <br />
                    <li>
                      {" "}
                      7.1.15. Encourage or enable any other individual to do any
                      of the foregoing.
                    </li>{" "}
                    <br />
                  </ol>
                </li>
              </ol>
              <br />
              <br />
              <b> 8. LICENSE</b>
              <br />
              <br />
              Subject to the Terms, Fizota gives you a limited, revocable,
              non-sublicensable, non-exclusive, and non-transferable licence to
              the Services (and other items displayed on the Site for download)
              only for purposes of using the Services in accordance with these
              Terms. It is expressly prohibited without the prior express
              permission from Fizota to use, reproduce, modify, distribute, or
              store any Content for purposes other than using the Services
              consistent with these Terms herein.
              <br />
              <br />
              <b> 9. INTELLECTUAL PROPERTY RIGHTS</b>
              <br />
              <br />
              <ol style={{ listStyleType: "none" }}>
                <li>
                  9.1 The Site contains the Intellectual Property of Fizota in
                  the form of content, graphics, videos, audio, text, and any
                  other digital content (“Site Content”). This is an agreement
                  for the use of Services, and you are not granted a licence to
                  any Site Content under this Terms of Use. Except to the extent
                  that applicable laws prevent us from doing so, you will not,
                  directly or indirectly: (i) reverse engineer, decompile,
                  disassemble, or otherwise attempt to discover the source code,
                  object code, or underlying structure, ideas, or algorithms of,
                  or found at or through the Site; (ii) remove any proprietary
                  notices or labels from the Site Content; reproduce or copy the
                  Site Content or any part thereof; (iii) modify, translate, or
                  create derivative works based on the Site Content; (iv) copy,
                  distribute, pledge, assign, or otherwise transfer or encumber
                  rights to the Site Content; (v) create any derivative product
                  from any of the foregoing; (vi) without our express written
                  permission, introduce automated agents or scripts to the Site
                  so as to generate automated searches, requests and queries, or
                  to strip or mine data from the Site; or (vii) allow third
                  parties to gain access to the Site or to Site Content in any
                  manner other than as expressly permitted in this Terms of Use.
                </li>{" "}
                <br />
                <li>
                  9.2 You acknowledge and agree that the Site, the names and
                  logos and all related products and names, design marks and
                  slogans, and all other material comprising the Site, are the
                  property of the Fizota or its affiliates (collectively, the
                  “Marks”). Unless stated otherwise, all Marks are protected as
                  the copyright, trade dress, trademarks and/or other
                  intellectual properties owned by us or by other parties that
                  have licensed their material to us. You are not authorised to
                  use any of the Marks in any advertising, publicity, or any
                  other commercial manner without the prior written consent of
                  Fizota. Your use of the Site confers no title or ownership in
                  the Site or the Marks and is not a sale of any rights in the
                  Site or the Marks. All ownership rights remain in Fizota or
                  its third-party suppliers, as the case may be.
                </li>{" "}
                <br />
                <li>
                  {" "}
                  9.3 You acknowledge and agree that any comments, ideas and/or
                  reports provided to us (“Feedback”) shall be the property of
                  Fizota and you hereby irrevocably transfer and assign to
                  Fizota such Feedback, and all associated intellectual property
                  rights, provided however that you shall be free to use such
                  Feedback in the ordinary conduct of your business.
                </li>{" "}
                <br />
                <li>
                  {" "}
                  9.4 It is our Policy to limit access to our Site of users who
                  infringe the intellectual property rights of others, as a
                  consequence of which we shall terminate our contract with you.
                  If you find that anything on our Site infringes any copyright
                  that you own or control, please contact us using the
                  information provided in section 16.
                </li>{" "}
                <br />
              </ol>
              <br />
              <br />
              <b> 10. TERMINATION</b>
              <br />
              <br />
              We reserve the right to terminate your access to all or any part
              of the Site and Services at any point of time, without providing
              any cause, with or without notice, effective immediately, which
              may result in the forfeiture and destruction of all associated
              information. Any such termination shall immediately revoke the
              licence granted under Section 7, and you shall effective
              immediately be prohibited from accessing or using the Site,
              Services or Content for any reason. The provisions of these Terms
              which by their nature should survive termination shall survive
              termination, including but not limited to Licences, warranty
              disclaimers, ownership provisions, limitations of liability and
              indemnification.
              <br />
              <br />
              <b> 11. RELEASE</b>
              <br />
              <br />
              To the maximum extent permissible by applicable law, you hereby
              absolutely release Fizota and its affiliates as well as all other
              users of the Site from responsibilities including but not limited
              to, claims, causes of action, liability, expenses, demands, and/or
              damages (actual and consequential) of all kinds and nature, known
              and unknown and claims of negligence, that may arise from the use
              of or inability to use, or in relation to your use of and/or
              reliance on the Site, including any disputes which may arise
              between users and the acts or omissions of third parties.
              <br />
              <br />
              <b> 12. INDEMNIFICATION</b>
              <br />
              <br />
              You acknowledge and agree that you shall at all times defend,
              indemnify, and hold harmless us, our affiliates and each of our
              affiliates including but not limited to, respective officers,
              directors, contractors, employees, agents, suppliers, and
              representatives against all liabilities, claims, fees, costs,
              penalties or sanctions, losses, expenses, and interest of any
              nature, including reasonable attorneys’ fees, arising out of or
              which may relate to (a) your use or misuse of, or access to, the
              Site; (b)your violation of any privacy, professional, ethics,
              licensing, or consumer protection laws, rules, or regulations; (c)
              your misuse of anyone’s private, proprietary, or Personal data;
              (d) infringement by you (or any third party using your identity in
              the Site) of any intellectual property or other rights of any
              person or entity; or (e) otherwise in violation of these Terms in
              any way. It is our right to assume the exclusive defence and
              control of any matter otherwise subject to indemnification by you,
              in which event you shall assist and cooperate with us in asserting
              any available defences at your expense, including reasonable
              attorneys’ fees incurred by us.
              <br />
              <br />
              <b> 13. LIMITATION OF LIABILITY</b>
              <br />
              <br />
              THE USE OF THE SITE OFFERED BY US IS ENTIRELY AT YOUR OWN RISK. IN
              NO CASE SHALL WE, NOR OUR OFFICERS, DIRECTORS, EMPLOYEES,
              CONTRACTORS, AGENTS, PARTNERS, SUPPLIERS, CONTENT PROVIDERS, OR
              ANY USERS BE LIABLE TO YOU UNDER CONTRACT, TORT, STRICT LIABILITY,
              NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH REGARDS TO
              THE SITE FOR: (I) ANY LOST PROFITS, LOSS IN REVENUE, LOSS OF
              GOODWILL, ANY DATA LOSS, LOSS OF USE, COST OF PROCURING SUBSTITUTE
              GOODS OR SERVICES, OTHER INTANGIBLE LOSSES, OR INDIRECT,
              INCIDENTAL, SPECIAL, PUNITIVE, COMPENSATORY, EXEMPLARY, RELIANCE,
              LIQUIDATED, OR ANY SIMILAR CONSEQUENTIAL DAMAGES OF ANY TYPE
              WHATSOEVER (HOWEVER ARISING), (II) ANY, VIRUSES, BUGS, TROJAN
              HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGIN), (III)
              ANY PERSONAL INJURY OR HARM, INCLUDING DEATH, WHICH IS CAUSED BY
              YOUR USE OR MISUSE OF THE SITE, (IV) ANY CLAIMS, DEMANDS, OR
              DAMAGES ARISING OUT OF OR RELATING TO ANY DISPUTE BETWEEN YOU AND
              ANY OTHER USER OF THE SITE, OR (V) ANY DIRECT DAMAGES IN EXCESS OF
              (IN THE AGGREGATE) OF THE GREATER THAN ANY FEES PAID BY YOU FOR
              USING OF PARTICULAR SERVICES DURING THE IMMEDIATELY PREVIOUS THREE
              (3) MONTH PERIOD. REMEDIES UNDER THESE TERMS OF USE ARE EXCLUSIVE
              AND ARE LIMITED TO THOSE EXPRESSLY PROVIDED FOR IN THESE TERMS OF
              USE. NOTHING IN THESE TERMS OF USE SHALL BE DEEMED TO EXCLUDE OR
              LIMIT YOUR LIABILITY IN RESPECT OF ANY INDEMNITY GIVEN BY YOU
              UNDER THESE TERMS OF USE.
              <br />
              <br />
              <b> 14. GOVERNING LAW AND DISPUTE RESOLUTION</b>
              <br />
              <br />
              The parties agree that the validity, operation, and performance of
              these Terms shall be governed by and interpreted in accordance
              with the laws of Singapore applicable therein (notwithstanding
              conflict of law rules). The Parties do expressly and irrevocably
              concede to the jurisdiction of courts with respect to any matter
              or claim, suit, action or proceeding arising under or related to
              these Terms. <br /> Any dispute concerning the subject matter of
              these Terms, or the breach, termination, or validity thereof (a
              “Dispute”) will be settled exclusively in accordance with the
              procedures set forth herein. The party seeking resolution of a
              Dispute will first give notice in writing of the Dispute to the
              other party, setting forth the nature of the Dispute and a concise
              statement of the issues to be resolved. If the Dispute has not
              been resolved through good faith efforts and negotiations of
              senior officers or representatives of the parties within fifteen
              (15) days of receipt by the relevant party of the notice of
              Dispute, such notice will be deemed to be a notice of arbitration
              and the parties agree to submit the Dispute to a single arbitrator
              mutually agreeable to both parties. The venue of such arbitration
              shall be as may be mutually decided by the Parties. In the event
              that the Parties cannot agree on a sole arbitrator, the arbitrator
              will be appointed by a judge of the appropriate court on
              application by either party to the Dispute. All decisions and
              awards rendered by the arbitrator will be final and binding upon
              the parties for all questions submitted to the such arbitrator,
              and the costs associated with such submission shall be shared
              equally by the parties involved in the Dispute unless the
              arbitrator decides otherwise. The parties waive all rights of
              appeal, therefore to any court or tribunal, and agree that the
              only recourse by any party to any court will be for the purpose of
              enforcing an arbitration award.
              <br />
              <br />
              <b> 15. MODIFICATION</b>
              <br />
              <br />
              We shall have the right to make modifications or replace any of
              the Terms, or suspend, change, or discontinue the Site (including
              but not limited to, the availability of any featured content, or
              database) at any time or instance by posting a notice through the
              Site. We may also do so by sending you a notice via email, via the
              Site, or by any other means of communication. We reserve the right
              to impose limits on certain features. We may if required to do so
              restrict your access to parts or all of the Site without notice or
              liability. We endeavour to try and provide notice of modifications
              to these Terms. However, you also agree that it is also your
              responsibility to make reasonable efforts to be aware of such
              modifications.
              <br /> When you continue to use the Site after notification of any
              modifications to the Terms shall mean acceptance of those
              modifications, and those modifications shall apply to your
              continued use of the Site going forward. Your use of the Site is
              subject to the Terms in effect at the time of such use.
              <br />
              <br />
              <b>16. MISCELLANEOUS</b>
              <ol style={{ listStyleType: "none" }}>
                <li>
                  16.1 Entire agreement and severability. These Terms are the
                  entire agreement between you and us with regard to the Site.
                  These Terms supersede all prior, contemporaneous
                  communications and proposals made (whether oral, written, or
                  electronic) between you and us with regard to the Site. If any
                  provisions mentioned in these Terms are found to be
                  unenforceable or invalid, that particular provision or
                  provisions will be limited or eliminated to the minimum extent
                  necessary so that these Terms will otherwise remain in full
                  force and effect and enforceable. In the event of the failure
                  of either Party to exercise in any respect, any right provided
                  for herein shall not be deemed a waiver of any further rights
                  hereunder.
                </li>
                <li>
                  16.2 Relationship of the parties. You and Fizota are
                  independent contractors. These Terms shall not and do not
                  create a partnership, franchise, joint venture, agency,
                  fiduciary, or employment relationship of any kind between the
                  Parties. You shall not have any authority of any kind to bind
                  us in any respect. Unless expressly stated otherwise in these
                  Terms, there are no third-party beneficiaries to the Terms. We
                  do not have any special relationship with you or any fiduciary
                  duty.
                </li>{" "}
                <br />
                <li>
                  16.3 Force majeure. We will not be liable in any case for any
                  failure or delay in the performance of our obligations for any
                  reason hereunder if such failure results from (a) any cause
                  beyond our reasonable control, including but not limited to,
                  mechanical, electronic or communications failure or
                  degradation, denial-of-service attacks, (b) any failure by a
                  third-party hosting provider or utility provider, (c) strikes,
                  shortages, riots, fires, acts of God, war, terrorism, and
                  governmental action.
                </li>{" "}
                <br />
                <li>
                  16.4 Assignment. You agree that these Terms are personal to
                  you, and are not assignable, transferable or sublicensable by
                  you. We reserve the right to assign, transfer or delegate any
                  of our rights and obligations hereunder without obtaining
                  consent.
                </li>{" "}
                <br />
                <li>
                  16.5 Notices. All notices under these Terms shall be in
                  writing unless otherwise specified in these Terms of Use.
                  Notices to us shall be sent by email to
                  <a href="mailto: support@fizota.com">
                    {" "}
                    support@fizota.com
                  </a>{" "}
                  You shall ensure written confirmation of receipt for the
                  notice to be effective. Notices to you shall be sent to your
                  last known email address (or the email address of your
                  successor, if any) and/or to any email address that would be
                  reasonably likely to provide notice to you, and such notice
                  shall be effective upon transmission.
                </li>{" "}
                <br />
                <li>
                  16.6 No waiver. Our failure to enforce any part of these Terms
                  shall not constitute a waiver of our right to later enforce
                  that or any other part of these Terms. Waiver of compliance in
                  any particular instance does not mean that we will waive
                  compliance in the future.
                </li>{" "}
                <br />
                <li>
                  {" "}
                  16.7 Interpretation. The headers are provided only to make
                  this agreement easier to read and understand.
                </li>{" "}
                <br />
              </ol>
              <br />
              <br />
              <b> 17. CONTACT</b> <br />
              <br />
              You may contact us through our Site, or the address given below:
              <br /> <br />
              Airvays Technologies Pte Ltd <br /> <br />
              <p id="disclaimer1"></p>
              Address: 7 soon lee street #02-45 ISPACE, Singapore 627608. <br />{" "}
              <br />
              Email:<a href="mailto: support@fizota.com"> support@fizota.com</a>
              <br />
              <br />
              <b>Disclaimers</b>
              <br />
              <br />
              This Disclaimer is hereby through this reference construed as part
              of our Terms of Use. <br /> <br />
              AIRVAYS TECHNOLOGIES PTE LTD (“FIZOTA”) HEREBY THROUGH THIS
              REFERENCE EXPRESSLY DISCLAIMS AS FOLLOWS, AND YOU THE USERS AND
              VISITORS OF THE SITE HEREBY ACKNOWLEDGE AND AGREE AND EXPRESSLY
              DISCLAIM, RELEASE AND WAIVE ANY LIABILITY, CLAIM, DAMAGES, LOSS,
              COST OR EXPENSE, INCONSISTENT WITH, RELATED TO OR ARISING FROM THE
              FOLLOWING:
              <br /> <br />
              <b>COPYRIGHT</b>
              <br /> <br />
              We reserve all copyrights on text or images on our “site” or
              channel owned by Fizota. The text or images in the “site” or
              “channel” may not be copied or distributed without our prior
              permission. <br /> <br />
              If there is any approved use of the content, the following
              conditions should be followed: <br /> <br />
              The source of copied material should be mentioned as “Fizota” or
              Airvays Technologies Pte Ltd;
              <br /> <br /> This statement should appear on all forms of
              distribution.
              <br /> <br />
              <b>THIRD-PARTY SERVICES</b>
              <br /> <br />
              The Services and the Site provided by us may permit you to link to
              other websites, services, or resources on the Internet, and other
              websites, services or resources may contain links to the Site.
              Also, Content may contain links to other websites, services, or
              resources on the Internet. When you access third-party resources
              on the Internet, you shall do so at your own risk. These other
              resources are not controlled by us, and you agree that we shall
              not be responsible or liable for including but not limited to the
              content, functions, accuracy, legality, appropriateness or any
              other aspect of such websites or resources. The inclusion of any
              such link shall not imply our endorsement or any association in
              any way between us and their operators. You also agree that we
              will not be responsible or liable in any case, either directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with the use of or reliance on any such
              content, goods, or services available on or through any such
              website or resource. <br /> <br />
              <b>WARRANTY DISCLAIMER</b>
              <br /> <br />
              THE SITE AND SERVICES ARE PROVIDED “AS IS”, “AS AVAILABLE” BASIS.
              THE USE OF SITES AND SERVICES IS AT THE USER’S SOLE RISK. THE SITE
              AND SERVICES ARE PROVIDED WITHOUT WARRANTY, REPRESENTATION, OR
              GUARANTEE OF ANY KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF TITLE OR ACCURACY
              AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, OR NON-INFRINGEMENT AND ANY WARRANTIES IMPLIED
              BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE
              EXPRESSLY DISCLAIMED, WITH THE SOLE EXCEPTION OF WARRANTIES (IF
              ANY) WHICH CANNOT BE EXPRESSLY EXCLUDED UNDER APPLICABLE LAW.
              FIZOTA, OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS, AND
              CONTENT PROVIDERS DO NOT WARRANT THAT: (I) THE SITE OR THE
              SERVICES ARE OR WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR
              TIME, INSTANCE OR LOCATION; (II) ANY DEFECTS MATERIAL OR NOT, OR
              ERRORS WILL BE CORRECTED; (III) ANY/ALL CONTENT OR SOFTWARE
              AVAILABLE AT OR THROUGH THE SITE IS FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS; (IV) ANY/ALL INFORMATION IS COMPLETE,
              ACCURATE, UP-TO-DATE, OR RELIABLE; (V) ANY PARTICULAR SERVICE,
              CONTENT, OR PRODUCT REFERRED TO IN THE SITE IS SAFE, APPROPRIATE,
              OR EFFECTIVE FOR YOUR AND/OR YOUR EMPLOYEES; (VI) THAT RESULTS OF
              USING THE SITE PROVIDED BY US WILL MEET YOUR REQUIREMENTS(VII) THE
              USE OF THE SITE PROVIDED BY US SHALL COMPLY WITH ANY LAWS, RULES,
              REGULATIONS, REQUIREMENTS, POLICIES, QUALIFICATIONS, OR BEST
              PRACTICES, INCLUDING BUT NOT LIMITED TO PRIVACY LAWS, PROFESSIONAL
              LICENSURE, OR REIMBURSEMENT; (VIII) THE USE OF THE SITE AND
              SERVICES SHALL NOT RESULT IN LEGAL DUTIES OR LIABILITY. WE DO NOT
              GUARANTEE IN ANY INSTANCE THAT ANY PARTICULAR CONTENT OR MATERIAL
              SHALL BE MADE AVAILABLE THROUGH THE SITE OR SERVICES.
            </p>
          </div>
        </section>
        <Footer {...this.props} />{" "}
      </div>
    );
  }
}
