/* eslint-disable */

import React, { Component, createRef } from "react";
class NewSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpArray: ["", "", "", "", "", ""],
      otp: "",
      errors: {
        otp: "",
      },
      validotperror: "",
    };
    this.inputRefs = Array(6)
      .fill()
      .map((_, i) => createRef());
  }
  componentWillReceiveProps(PropsDt) {
    if (PropsDt.resetField === true) {
      this.setState(
        {
          validotperror: "",
          otp: "",
        },
        () => {
          this.props.sateValChange("resetField", false);
        }
      );
    }
  }

  handleInputChange = (name, index, value) => {
    if (/^\d*$/.test(value)) {
      const updatedOTP = [...this.state.otpArray];
      updatedOTP[index] = value;
      this.setState({ otpArray: updatedOTP }, () => {
        if (index < 5 && value !== "") {
          this.inputRefs[index + 1].current.focus();
        }
      });
    }
  };
  handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && this.state.otpArray[index] === "") {
      const updatedOTP = [...this.state.otpArray];
      updatedOTP[index - 1] = "";
      this.setState({ otpArray: updatedOTP }, () => {
        this.inputRefs[index - 1].current.focus();
      });
    }
  };
  // validateField(fieldName, value) {
  //   const errors = { ...this.state.errors };
  //   let nameValid = true;
  //   switch (fieldName) {
  //     case "otp":
  //       if (value.length === 0) {
  //         errors.otp = "OTP is empty";
  //         nameValid = false;
  //       }
  //       break;
  //     default:
  //       break;
  //   }

  //   this.setState({ errors });
  // }
  handleSubmit = (e) => {
    e.preventDefault();
    const { otpArray } = this.state;
    const otpValue = otpArray.join("");

    if (otpValue.trim() === "") {
      this.setState({ validotperror: "Field is required" });
      return;
    } else {
      this.setState({ validotperror: "" });
      this.props.onFormSubmit({
        otp: otpValue,
      });
    }
    // const { otp, errors } = this.state;
    // let valid = 0;

    // if (otp === "") {
    //   this.setState({ validotperror: "Field is required", showname: true });
    //   valid++;
    // }
    // this.validateField("otp", otp);

    // if (valid === 0) {
    //   this.setState({
    //     validotperror: "",
    //     otp: "",
    //   });
    //   console.log(this.props, "this.props");
    //   this.props.onFormSubmit({
    //     otp,
    //   });
    // }
  };

  // handlePhoneChange = (phone, value) => {
  //   console.log(phone, value, "value");
  //   const phoneWithCountryCode = `1${value}`;
  //   this.setState({ phone: phoneWithCountryCode });
  //   this.validateField("phone", value);
  // };
  // setPhone(phone, data) {
  //   // ("mobile", phone);
  //   this.setState({ phone: phone });

  //   $("#countryCode").val(data.countryCode);
  // }

  render() {
    const { otpArray, otp, validotperror } = this.state;

    return (
      <div>
        {/* <h2>Signup Form</h2> */}
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label className="control-label">OTP</label>
            <div className="form-group-input">
              {[...Array(6)].map((_, index) => (
                <input
                  className="otp-input"
                  key={index}
                  name="otp"
                  type="text"
                  maxLength="1"
                  value={otpArray[index]}
                  onChange={(e) =>
                    this.handleInputChange(e.target.name, index, e.target.value)
                  }
                  autoFocus={index === 0}
                  ref={this.inputRefs[index]}
                  onKeyDown={(e) => this.handleBackspace(index, e)}
                />
              ))}
            </div>
            {otp === "" && this.state.validotperror && (
              <div className="error">{this.state.validotperror}</div>
            )}
          </div>

          <div className="submit-btn-main">
            <button type="submit" className="button signup_submits">
              Verify OTP
            </button>
            {this.props.signupError && (
              <div className="error">{this.props.signupError}</div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default NewSignup;
