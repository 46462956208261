import { SET_UPLOAD_FILES } from "../actions";

const uploadfiles = (state = [], action) => {
    switch (action.type) {
        case SET_UPLOAD_FILES:
            return [...action.value];
        default:
            return state;
    }
};

export default uploadfiles;
