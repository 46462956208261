/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "../../common/css/owl.carousel.css";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { apiUrl, fizoheader } from "../Helpers/Config";
import {
  stripslashes,
  showAlert,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";
var qs = require("qs");
class Cancelrequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadRequest: true,
      cancell_details: "",
      booking_details: "",
      cancelled_list: "",
      password: "",
      error_password: "",
    };
    this.loadCancelRequest();
  }
  componentDidMount() {}
  loadCancelRequest() {
    if (
      this.props.match.params !== "" &&
      typeof this.props.match.params !== undefined &&
      typeof this.props.match.params !== "undefined"
    ) {
      if (
        this.props.match.params.requestID !== "" &&
        typeof this.props.match.params.requestID !== undefined &&
        typeof this.props.match.params.requestID !== "undefined"
      ) {
        axios
          .get(
            apiUrl +
              "booking/getcancelRequest?refrence_no=" +
              this.props.match.params.requestID,
            fizoheader
          )
          .then((res) => {
            if (res.data.status === "ok") {
              var cancelled_list = [];
              if (res.data.booking_details[0].cancelled_list.length > 0) {
                res.data.booking_details[0].cancelled_list.map((item) => {
                  cancelled_list.push(parseInt(item));
                });
              }
              this.setState({
                cancell_details: res.data.cancell_details[0],
                booking_details: res.data.booking_details[0],
                cancelled_list: cancelled_list,
                loadRequest: false,
              });
            } else {
              this.setState(
                {
                  loadRequest: false,
                },
                function () {
                  showAlert("Error", res.data.message);
                  $.magnificPopup.open({
                    items: {
                      src: ".alert_popup",
                    },
                    type: "inline",
                  });
                }
              );
              this.props.history.push("/");
            }
          });
      } else {
        this.setState(
          {
            loadRequest: false,
          },
          function () {
            showAlert("Error", "Invalid Refrence No");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        );
        this.props.history.push("/");
      }
    } else {
      this.setState(
        {
          loadRequest: false,
        },
        function () {
          showAlert("Error", "Invalid Refrence No");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      );
      this.props.history.push("/");
    }
  }
  handleTextChange(event) {
    var name = event.target.name;
    this.setState({ [name]: event.target.value, ["error_" + name]: "" });
  }
  proceedCancel() {
    var error = 0;
    if (this.state.password === "") {
      error++;
      this.setState({ error_password: "Please enter password" });
    } else {
      this.setState({ error_password: "" });
    }
    if (error === 0) {
      var postObject = {};
      var ticket_id = [];
      var ticket_code = [];
      this.state.booking_details.cancellListWithTicketType.map((item) => {
        ticket_id.push(item.id);
        ticket_code.push(item.code);
      });
      postObject = {
        customer_id: this.state.booking_details.customer_id,
        booking_id: this.state.booking_details.booking_id,
        ticket_id: JSON.stringify(ticket_id),
        ticket_code: JSON.stringify(ticket_code),
        password: this.state.password,
      };
      console.log(postObject, "postObject");
      showLoader("proceed_cancel");
      axios
        .post(
          apiUrl + "booking/cancelBooking",
          qs.stringify(postObject),
          fizoheader
        )
        .then((res) => {
          $.magnificPopup.close();
          hideLoader("proceed_cancel");
          if (res.data.status === "ok") {
            showAlert("Success", res.data.message);
            this.props.history.push("/");
          } else if (res.data.status === "error") {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });
    }
  }
  confirmCancel() {
    $.magnificPopup.open({
      items: {
        src: "#ticketcanellconfirm-popup",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });
  }
  closePopup() {
    $.magnificPopup.close();
  }

  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        {this.state.loadRequest === false ? (
          <div className="cancel-request">
            <div className="container">
              <div className="crbox">
                {this.state.booking_details !== "" && (
                  <ul>
                    <li>
                      <strong>Booking ID:</strong>{" "}
                      <span>
                        {this.state.booking_details.booking_reference_number}
                      </span>{" "}
                    </li>
                    <li>
                      <strong>Customer Name:</strong>{" "}
                      <span>
                        {this.state.booking_details.booking_customerName}
                      </span>{" "}
                    </li>
                    <li>
                      <strong> Customer Mobile:</strong>{" "}
                      <span>{this.state.booking_details.customer_phone}</span>{" "}
                    </li>
                    <li>
                      <strong>Customer Eamil:</strong>{" "}
                      <span>{this.state.booking_details.booking_email}</span>{" "}
                    </li>
                    <li>
                      <strong>Product Name:</strong>{" "}
                      <span>
                        {stripslashes(
                          this.state.booking_details.attraction_title
                        )}
                      </span>{" "}
                    </li>
                    <li>
                      <strong>Package Name:</strong>{" "}
                      <span>
                        {stripslashes(this.state.booking_details.package_name)}
                      </span>{" "}
                    </li>
                    {this.state.cancell_details !== "" && (
                      <li>
                        <strong>Cancel Reason:</strong>{" "}
                        <span>
                          {this.state.cancell_details.cancel_reason !== "" &&
                            (this.state.cancell_details.cancel_reason ===
                            "Others"
                              ? stripslashes(
                                  this.state.cancell_details.cancel_reason
                                )
                              : stripslashes(
                                  this.state.cancell_details.cancel_reason
                                ))}
                        </span>
                      </li>
                    )}
                  </ul>
                )}
                <h3>Cancel Ticket Details</h3>
                {this.state.booking_details !== "" && (
                  <ul className="ctd-details">
                    {this.state.booking_details.tickets.map((item, index) => {
                      if (this.state.cancelled_list.indexOf(item.id) >= 0) {
                        return (
                          <li key={index}>
                            <strong>
                              {item.code} ({item.quantity} X {item.name})
                            </strong>
                          </li>
                        );
                      }
                    })}
                  </ul>
                )}
                <div className="booknow-btm">
                  <a href={void 0} className="button" onClick={this.confirmCancel.bind()}>
                    Proceed
                  </a>
                  <a className="button">Close</a>
                </div>
              </div>
            </div>

            <div
              id="ticketcanellconfirm-popup"
              className="mfp-hide common-popup"
            >
              <a
                className="mfp-close"
                href={void 0}
                onClick={this.closePopup.bind(this)}
              ></a>
              <div className="cancel-header">
                <h3>Cancellation</h3>
              </div>
              <div className="cp-form">
                <div className="form-group pwd-group">
                  <label className="control-label">Password</label>
                  <div className="change-group">
                    <input
                      type="password"
                      name="password"
                      onChange={this.handleTextChange.bind(this)}
                    />
                    {this.state.error_password !== "" && (
                      <div className="error">{this.state.error_password}</div>
                    )}
                  </div>
                </div>

                <div className="booknow-btm">
                  <a
                    className="button"
                    id="proceed_cancel"
                    onClick={this.proceedCancel.bind(this)}
                  >
                    Proceed
                  </a>
                  <a href={void 0} className="button" onClick={this.closePopup.bind(this)}>
                    Close
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id="dvLoading">
            <div className="loader triangle">
              <svg viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  stroke="black"
                  stroke-width="3"
                  fill="transparent"
                />
              </svg>
            </div>
          </div>
        )}
        <Footer {...this.props} />
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Cancelrequest);
