/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import abt from "../../common/images/abtus-bg.jpg";
import { Helmet } from "react-helmet";

class Aboutus extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {}

  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <title>
            Your Gateway to Seamless Travel: Discover, Book, and Explore with
            Fizota - Your Trusted Travel Partner
          </title>
          <link rel="canonical" href="https://www.fizota.com/aboutus" />
          <meta
            name="description"
            content="At Fizota, we redefine travel experiences. Effortless bookings, personalized adventures, and unmatched service. Start your journey with us today!"
          />
          <meta
            name="keywords"
            content="weekend getaways, weekend getaways packages, holiday package, holiday packages overseas, holiday packages online, holiday packages 2024, vacation travel packages, travel packages online, travel packages 2024, all inclusive holidays packages, travel itinerary planner, travel planner vs travel agent, travel trip planner, customized holiday packages"
          />
          {/* Add more meta tags or other head elements as needed */}
        </Helmet>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div className="abt-banner rel">
          <img src={abt} alt="about" />
          <h1>
            Inspire, Discover & <br />
            Explore..
          </h1>
        </div>
        <div className="abt-content textcenter">
          <div className="container">
            {/* <h2 className="color-header">
              Connecting digital <span>travelers</span> with travel{" "}
              <span>creators</span>
            </h2> */}
            <h1>Fizota - About Us</h1>
            <p>
              We are an online experience marketplace trying to provide value to
              the FIT (Free independent travelers) and travel creator by
              bringing them together..
            </p>
          </div>
        </div>
        <div className="abt-why">
          <div className="container">
            <h3 className="color-header textcenter">
              Why <span>Fizota?</span>
            </h3>
            <ul>
              <li>
                <h5>1. Get Inspired.</h5>
                <p>Let your favorite creators craft your dream vacation.</p>
              </li>
              <li>
                <h5>2. Hyper-Customisation.</h5>
                <p>Customise your vacation in real-time, like never before.</p>
              </li>
              <li>
                <h5>3. Price Transparency.</h5>
                <p>Customize your vacation like never before.</p>
              </li>
              <li>
                <h5>4. Single-Click Booking.</h5>
                <p>
                  Book all your itineraries with a single click of a button.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

export default Aboutus;
