export const GET_GLOBAL_SETTINGS = "getGlobalSettings";
export const SET_GLOBAL_SETTINGS = "setGlobalSettings";
export const GET_COUNTRY = "getCountry";
export const SET_COUNTRY = "setCountry";
export const GET_PRODUCTLIST = "getProductList";
export const SET_PRODUCTLIST = "setProductList";
export const GET_PRODUCTDETAILS = "getProductDetails";
export const SET_PRODUCTDETAILS = "setProductDetails";
export const GET_SEGMENTLIST = "getSegmentList";
export const SET_SEGMENTLIST = "setSegmentList";
export const GET_TAGLIST = "getTagList";
export const SET_TAGLIST = "setTagList";
export const GET_CREATOR_LOGINDATA = "getCreatorLoginData";
export const SET_CREATOR_LOGINDATA = "setCreatorLoginData";
export const GET_FBLOGINDATA = "getFbLoginData";
export const SET_FBLOGINDATA = "setFbLoginData";
export const GET_CREATOR_FBLOGINDATA = "getCreatorFbLoginData";
export const SET_CREATOR_FBLOGINDATA = "setCreatorFbLoginData";
export const GET_FBCREATORLOGINDATA = "getFbCreatorLoginData";
export const SET_FBCREATORLOGINDATA = "setFbCreatorLoginData";
export const GET_GOOGLELOGINDATA = "getGoogleLoginData";
export const SET_GOOGLELOGINDATA = "setGoogleLoginData";
export const GET_GOOGLECREATORLOGINDATA = "getGoogleCreatorLoginData";
export const SET_GOOGLECREATORLOGINDATA = "setGoogleCreatorLoginData";
export const GET_UPDATECUSTOMERPROFILE = "getUpdateCustomerProfile";
export const SET_UPDATECUSTOMERPROFILE = "setUpdateCustomerProfile";
export const GET_UPDATECREATORPROFILE = "getUpdateCreatorProfile";
export const SET_UPDATECREATORPROFILE = "setUpdateCreatorProfile";
export const GET_CUSTOMER_DETAIL = "getCustomerDetail";
export const SET_CUSTOMER_DETAIL = "setCustomerDetail";
export const GET_CREATOR_DETAIL = "getCreatorDetail";
export const SET_CREATOR_DETAIL = "setCreatorDetail";
export const GET_FORGET_PASSWORD = "getForgetPassword";
export const SET_FORGET_PASSWORD = "setForgetPassword";
export const GET_CREATOR_REGISTRATION = "getCreatorRegistration";
export const SET_CREATOR_REGISTRATION = "setCreatorRegistration";
export const GET_CHANGEPASSWORD = "getChangePassword";
export const SET_CHANGEPASSWORD = "setChangePassword";

export const GET_CUSTOMER_LOGINDATA = "getCustomerLoginData";
export const SET_CUSTOMER_LOGINDATA = "setCustomerLoginData";
export const GET_CUSTOMER_REGISTRATION = "getCustomerRegistration";
export const SET_CUSTOMER_REGISTRATION = "setCustomerRegistration";

export const GET_FAVOURITE = "getFavourite";
export const SET_FAVOURITE = "setFavourite";
export const GET_FAVOURITELIST = "getFavouritelist";
export const SET_FAVOURITELIST = "setFavouritelist";
export const GET_BOOKINGHISTORY = "getBookingHistory";
export const SET_BOOKINGHISTORY = "setBookingHistory";
export const GET_FUNTHINGS = "getFunThings";
export const SET_FUNTHINGS = "setFunThings";

export const GET_SEARCHFLIGHT = "getSearchFlight";
export const SET_SEARCHFLIGHT = "setSearchFlight";
export const GET_MYSTIFLYREVALIDATE = "getMystiflyRevalidate";
export const SET_MYSTIFLYREVALIDATE = "setMystiflyRevalidate";
export const GET_MYSTIFLYCHEAPESTREVALIDATE = "getMystiflyCheapestRevalidate";
export const SET_MYSTIFLYCHEAPESTREVALIDATE = "setMystiflyCheapestRevalidate";

export const GET_WISHLIST = "getWishlist";
export const SET_WISHLIST = "setWishlist";

export const GET_UPLOAD_FILES = "getUploadFiles";
export const SET_UPLOAD_FILES = "setUploadFiles";
