import { SET_FBCREATORLOGINDATA } from '../actions';

const fblogincreator = (state = [], action) => {
    switch (action.type) {
        case SET_FBCREATORLOGINDATA:
            return [...action.value];
        default: return state;
    }
}

export default fblogincreator;
