/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_TAGLIST, SET_TAGLIST } from "../actions";
import { apiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";
export const watchGetTagList = function* () {
  yield takeEvery(GET_TAGLIST, workerGetTagList);
};

function* workerGetTagList() {
  try {
    const uri = apiUrl + "products/getTagList";
    const result = yield call(Axios.get, uri, fizoheader);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_TAGLIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Tag list Failed");
  }
}
