/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import abt from "../../common/images/abtus-bg.jpg";
import { Link } from "react-router-dom";
 class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.helpDetail = this.helpDetail.bind(this);

    this.state = {
      contentType: "helpCenter",
      searchText: "",
      searchData: [],
      filteredData: [],
      Title: "",
      isFlight: false,
      isActivities: false,
      isVacation: true,
    };
  }

  sateValChange = (field, value) => {
    console.log("called (list page)");
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  handleChange(e) {
    const text = e.target.value;
    this.setState({ searchText: text })
  }

  helpDetail(title, category) {
    console.log(title,category,"category");
    if (title !== "") {
      this.props.history.push({
        pathname: "/helpcenter-detail",
        state: {
          Category: category,
        },
      });
    }
  }
  searchHelp(category) {
    if (category !== "") {
      this.props.history.push({
        pathname: "/helpcenter-detail",
        state: {
          Category: category.category,
          Title: category.blogtitle,
        },
      });
    }
  }

  render() {
    return (
      <>

        <div className="abt-banner rel">
          <img src={abt} alt="backgroundimg" />
          <div className="topics-serch">
            <h2>How can we help you?</h2>
            <div className="search-topics">
              <input
                type="text"
                placeholder="Search the Topics "
                value={this.state.searchText}
                onChange={this.handleChange.bind(this)}
              />
            </div>
            <ul>
              {this.state.filteredData.map((item, index) => (
                <li key={index}>
                  <div
                    className="search-show"
                    onClick={this.searchHelp.bind(this, item)}
                    style={{ color: "#ffffff" }}
                  >
                    <p>{item.blogtitle}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="bread-crumbs">
          <div className="container">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                {" "}
                <Link to={"/helpcenter"}
                  target={"_blank"}>
                  {" "}
                  helpCenter
                </Link>
              </li>
              {/* <li>{this.state.productdetail.products_name}</li> */}
            </ul>
          </div>
        </div>
        <div className="help-content">
          <div className="container">
            <ul>
              <li
                onClick={this.helpDetail.bind(
                  this,
                  "TravellerFAQs",
                  "TravellerFAQs"
                )}
              >
                <a href={void 0}>
                  {/* <div className="help-icn">
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </div> */}
                  <div className="help-contnt">
                    <h4>Traveller FAQs</h4>
                    <p>Wanderer’s Guidebook: Frequently Asked Questions</p>
                  </div>
                </a>
              </li>
              <li
                onClick={this.helpDetail.bind(
                  this,
                  "TravelcreatorFAQs",
                  "TravelcreatorFAQs"
                )}
              >
                <a href={void 0}>
                  <div className="help-contnt">
                    <h4>Travel Creator FAQs</h4>
                    <p>Creator’s Corner: Travel Content FAQs</p>
                  </div>
                </a>
              </li>
              <li
                onClick={this.helpDetail.bind(
                  this,
                  "Bookings&Payments",
                  "Bookings&Payments"
                )}
              >
                {" "}
                <a href={void 0}>
                  <div className="help-contnt">
                    <h4>Bookings & Payments</h4>
                    <p>Secure Journeys: Information on Bookings & Payments</p>
                  </div>
                </a>
              </li>
              <li
                onClick={this.helpDetail.bind(
                  this,
                  "Deals&Promotions",
                  "Deals&Promotions"
                )}
              >
                {" "}
                <a href={void 0}>
                  <div className="help-contnt">
                    <h4>Deals & Promotions</h4>
                    <p>Exciting Offers: Details on Deals & Promotions</p>
                  </div>
                </a>
              </li>
              <li
                onClick={this.helpDetail.bind(
                  this,
                  "News & Updatess",
                  "News & Updates"
                )}
              >
                {" "}
                <a href={void 0}>
                  <div className="help-contnt">
                    <h4>News & Updates</h4>
                    <p>Travel Chronicles: Latest News & Updates</p>
                  </div>
                </a>
              </li>
              <li>
                {" "}
                <a href="https://www.fizota.com/blog/" target="_blank">
                  <div className="help-contnt">
                    <h4>Get Inspired!</h4>
                    <p>Unleash Your Wanderlust: Get Inspired!</p>
                  </div>
                </a>
              </li>
              {/* <li>
                <a href={void 0}>
                
                  <div className="help-contnt">
                    <h4>About Fizota</h4>
                    <p>
                      Find out everything about Fizota and why you should book
                      with us!
                    </p>
                  </div>
                </a>
              </li> */}
            </ul>
            <div>
              <div className="feedbackcontainer">
                <h1>Fizota - Help & Support.</h1>
                <script
                  type="text/javascript"
                  src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
                ></script>
                <style type="text/css" media="screen, projection">
                  @import
                  url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
                </style>
                <iframe
                  title="Feedback Form"
                  className="freshwidget-embedded-form"
                  id="freshwidget-embedded-form"
                  src="https://fizota.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&searchArea=no"
                  scrolling="no"
                  height="500px"
                  width="100%"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default Aboutus;
