/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import cookie from "react-cookies";
import axios from "axios";
import { apiUrl, fizoheader } from "../Helpers/Config";
import { hideLoader, showAlert, showLoader } from "../Helpers/SettingHelper";
import profileeditImg from "../../common/images/edit-pen.svg";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { GET_CUSTOMER_DETAIL, GET_UPDATECUSTOMERPROFILE, GET_UPLOAD_FILES } from "../../actions";
import Form from "./Form";
var base64 = require("base-64");
import Avatar from "react-avatar";
import bcrypt from "bcryptjs";
var qs = require("qs");
const deactivationReasons = [
  "I have another Fizota account",
  "I have concerns with Fizota's policy",
  "I have better alternative platform",
  "The platform is complicated to use",
  "Fizota's support is not great.",
];
class Listpromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerdetail: "",
      fields: {
        customer_name: "",
        email: "",
        phone: "",
        password: "",
        gender: "",
        phone_country: "",
        phone_code: "",
      },
      imageurl: "",
      editprofile: false,
      showRemoveButton: true,
      selectedReason: "",
      isAgreedForDeactivation: false,
      error_selectedReason: false,
      error_isAgreedForDeactivation: false,
      error_deactivationPassword_msg: "",
      error_deactivationPassword: false,
      passwordType: "password",
      msgPop: "",
      error_currentPassword: false,
      error_newPassword: false,
      error_newPassword_msg: "",
      error_confirmPassword: false,
      error_confirmPassword_msg: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isAgreedForChangePassword: false,
      error_currentPassword_msg: "",
      current_password_matched: false,
      error_isAgreedForChangePassword: false,
      deactivatebutton: false,
      uploadfiles: ""
    };
    this.handleAgreementChange = this.handleAgreementChange.bind(this);
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    }
    var params = "customer_id=" + cookie.load("UserId");
    this.props.getCustomerDetail(params);
    this.Closepopup = this.Closepopup.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log(this.state.imageurl, "images")
    console.log(nextProps.uploadfiles)
    if (nextProps.uploadfiles.status === "ok") {
      console.log(nextProps.uploadfiles)
      if (this.state.imageurl !== nextProps.uploadfiles.web && typeof nextProps.uploadfiles.web !== undefined && typeof nextProps.uploadfiles.web !== "undefined") {
        this.setState({ imageurl: nextProps.uploadfiles.web }, function () {
          if (this.state.imageurl) {
            var formData = new FormData();
            console.log(this.state.imageurl, "imageurl")
            formData.append("customer_photo", this.state.imageurl);

            formData.append("customer_id", cookie.load("UserId"));

            axios
              .post(apiUrl + "customer/updateprofileimage", formData, fizoheader)
              .then((res) => {
                hideLoader("myaccount_update", "class");
                if (res.data.status === "ok") {
                  showAlert("Success", res.data.message);
                  console.log(res.data, "data")
                  $("#profile_image").val("");
                  this.setState({ imageurl: res.data.url });
               /*    $.magnificPopup.open({
                    items: {
                      src: ".alert_popup",
                    },
                    type: "inline",
                  }); */
                } else {
                  var errormsg =
                    res.data.form_error !== ""
                      ? res.data.form_error
                      : res.data.message;
                  showAlert("Error", errormsg);
                  $.magnificPopup.open({
                    items: {
                      src: ".alert_popup",
                    },
                    type: "inline",
                  });
                }
              });
          }
        });
      }

    }

    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState(
        {
          customerdetail: customerDetails,
        },
        function () {}
      );
      var genderLable = {};
      if (customerDetails.customer_gender == "M") {
        genderLable = { value: "M", label: "Male" };
      } else if (customerDetails.customer_gender == "F") {
        genderLable = { value: "F", label: "Female" };
      } else if (customerDetails.customer_gender == "O") {
        genderLable = { value: "O", label: "Others" };
      }
      this.setState(
        update(this.state, {
          customerdetail: {
            $set: customerDetails,
          },
          imageurl: {
            $set:
              customerDetails.customer_photo !== null
                ? customerDetails.customer_photo /* ?.split(
                    "https://api-mp.fizota.com/media/customers/"
                  )[1] */
                : "",
          },
          fields: {
            customer_name: {
              $set:
                customerDetails.customer_name !== null
                  ? customerDetails.customer_name
                  : "",
            },
            email: {
              $set:
                customerDetails.customer_email !== null
                  ? customerDetails.customer_email
                  : "",
            },
            phone: {
              $set:
                customerDetails.customer_phone !== null
                  ? customerDetails.customer_phone_code +
                    " " +
                    customerDetails.customer_phone
                  : "",
            },
            gender: {
              $set: genderLable,
            },
            phone_country: {
              $set:
                customerDetails.customer_phone_country !== null
                  ? customerDetails.customer_phone_country
                  : "",
            },
            phone_code: {
              $set:
                customerDetails.customer_phone_code !== null
                  ? customerDetails.customer_phone_code
                  : "",
            },
          },
        })
      );
    }
    if (nextProps.updatecustomerprofile !== this.props.updatecustomerprofile) {
      const { updatecustomerprofile } = nextProps;
      if (updatecustomerprofile[0].status === "ok") {
        var params = "customer_id=" + cookie.load("UserId");
        this.props.getCustomerDetail(params);
        $(".myaccount_update .gloading_img").remove();
        // showAlert("success", updatecustomerprofile[0].message);

        $.magnificPopup.open({
          items: {
            src: "#gender-msg-pop",
          },
          type: "inline",
        });
        this.setState({
          msgPop: updatecustomerprofile[0].message,
        });
      } else {
        $(".myaccount_update .gloading_img").remove();
        if (updatecustomerprofile[0].form_error) {
          showAlert("error", updatecustomerprofile[0].form_error);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          showAlert("error", updatecustomerprofile[0].message);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
            showCloseBtn: false,
            midClick: true,
            closeOnBgClick: false,
          });
        }
      }
    }
  }
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };
  /* To Submit the personal informartion form */
  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.phone !== "" && formPayload.phone !== null) {
      var mobile = formPayload.phone.split(" ");

      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }
    var postObject = {
      customer_name: formPayload.customer_name,
      customer_phone: mobileNo,
      customer_phone_code: mobile_code,
      customer_countryCode: $("#profilecountryCode").val(),
      customer_email: formPayload.email,
      customer_gender: formPayload.gender.value,
      // customer_password:
      //   formPayload.password !== "" ? base64.encode(formPayload.password) : "",
      customer_id: cookie.load("UserId"),
      customer_photo: this.state.imageurl,
    };
    console.log(formPayload, postObject, "formPayload");
    // return false;
    $(".myaccount_update").append('<b class="gloading_img"></b>');
    this.props.getUpdateCustomerProfile(qs.stringify(postObject));
    console.log("Current imageurl:", this.state.imageurl);
    $.magnificPopup.close();
  };

  openPopup(popupID) {
    this.setState({ editprofile: true }, function () {
      $.magnificPopup.open({
        items: {
          src: popupID,
        },
        type: "inline",
        midClick: true,
      });
    });
  }
  async uplaodFiles() {
    var formData = new FormData();
    var imagefile = document.querySelector("#profile_image");
    const file = imagefile.files[0];
    console.log(file, "file")
    const formPayload = this.state.fields;
    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.phone !== "" && formPayload.phone !== null) {
      var mobile = formPayload.phone.split(" ");
      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }
    if (imagefile.files.length > 0) {
      formData.append("file", file);
      this.props.GetUploadFiles(formData)
    }
    $(".myaccount_update").append('<b class="gloading_img"></b>');
  }
 /*  async uplaodFiles() {
    var formData = new FormData();
    var imagefile = document.querySelector("#profile_image");
    const file = imagefile.files[0];
    const formPayload = this.state.fields;
    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.phone !== "" && formPayload.phone !== null) {
      var mobile = formPayload.phone.split(" ");
      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }
    if (imagefile.files.length > 0) {
      formData.append("image", file);
      axios
        .post(apiUrl + "settings/uploadFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ imageurl: res.data.url }, function () {
              if (imagefile.files.length > 0) {
                console.log(this.state.imageurl, "imageurl")
                formData.append("customer_photo", this.state.imageurl);

                formData.append("customer_id", cookie.load("UserId"));

                axios
                  .post(apiUrl + "customer/updateprofileimage", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((res) => {
                    hideLoader("myaccount_update", "class");
                    if (res.data.status === "ok") {
                      showAlert("Success", res.data.message);
                      console.log(res.data, "data")
                      $("#profile_image").val("");
                      this.setState({ imageurl: res.data.url });
                      $.magnificPopup.open({
                        items: {
                          src: ".alert_popup",
                        },
                        type: "inline",
                      });
                    } else {
                      var errormsg =
                        res.data.form_error !== ""
                          ? res.data.form_error
                          : res.data.message;
                      showAlert("Error", errormsg);
                      $.magnificPopup.open({
                        items: {
                          src: ".alert_popup",
                        },
                        type: "inline",
                      });
                    }
                  });
              }
            });
          }
        });
    }
  }
    $(".myaccount_update").append('<b class="gloading_img"></b>');
  } */

  openPopUpDeactivate = () => {
    let errorCount = 0;
    if (!this.state.selectedReason) {
      this.setState({ error_selectedReason: true });
      errorCount++;
    }
    if (!this.state.isAgreedForDeactivation) {
      this.setState({ error_isAgreedForDeactivation: true });
      errorCount++;
    }

    if (errorCount > 0) {
      return;
    }

    $.magnificPopup.open({
      items: {
        src: "#deactiveConfirm",
      },
      type: "inline",
      showCloseBtn: true,
      midClick: true,
      closeOnBgClick: false,
    });
  };
  handleChange = (event) => {
    var { name, value } = event.target;
    var error_selectedReason = false;
    var error_currentPassword = false;
    var error_newPassword = false;
    var error_newPassword_msg = "";
    var error_confirmPassword = false;
    var error_confirmPassword_msg = "";
    var error_currentPassword_msg = "";
    var current_password_matched = this.state.current_password_matched;

    if (name === "selectedReason" && value === "") {
      error_selectedReason = true;
    }
    if (name === "currentPassword" && value.trim() === "") {
      error_currentPassword = true;
      error_currentPassword_msg = "This field is required";
      current_password_matched = false;
    } else if (name === "currentPassword" && value.trim() !== "") {
      var storedHash = this.state.customerdetail.customer_password;
      var isPasswordCorrect = bcrypt.compareSync(value, storedHash);

      if (!isPasswordCorrect) {
        error_currentPassword = true;
        error_currentPassword_msg = "Password is not correct";
        current_password_matched = false;
      } else if (isPasswordCorrect) {
        error_currentPassword = false;
        current_password_matched = true;
      }
    }

    if (name === "newPassword" && value.trim() === "") {
      error_newPassword = true;
      error_newPassword_msg = "This field is required";
    }
    if (name === "confirmPassword") {
      if (value.trim() === "") {
        error_confirmPassword = true;
        error_confirmPassword_msg = "This field is required";
      } else if (value !== this.state.newPassword) {
        error_confirmPassword = true;
        error_confirmPassword_msg = "Passwords do not match";
      }
    }
    this.setState({
      [name]: value,
      error_selectedReason: error_selectedReason,
      error_currentPassword: error_currentPassword,
      error_newPassword: error_newPassword,
      error_newPassword_msg: error_newPassword_msg,
      error_confirmPassword: error_confirmPassword,
      error_confirmPassword_msg: error_confirmPassword_msg,
      error_currentPassword_msg: error_currentPassword_msg,
      current_password_matched: current_password_matched,
    });
  };
  handleAgreementChange(event) {
    const { name, checked } = event.target;
    var error_isAgreedForDeactivation = false;
    var error_isAgreedForChangePassword = false;

    if (name === "isAgreedForDeactivation" && checked === false) {
      error_isAgreedForDeactivation = true;
    }
    if (name === "isAgreedForChangePassword" && checked === false) {
      error_isAgreedForChangePassword = true;
    }
    this.setState({
      [name]: checked,
      error_isAgreedForDeactivation: error_isAgreedForDeactivation,
      error_isAgreedForChangePassword: error_isAgreedForChangePassword,
    });
  }
  handleChangeDeact = (event) => {
    var { name, value } = event.target;
    var error_deactivationPassword = false;
    var error_deactivationPassword_msg = "";
    var deactivatebutton = false;

    if (name === "deactivationPassword" && value.trim() === "") {
      error_deactivationPassword = true;
      error_deactivationPassword_msg = "This field is required";
    } else if (name === "deactivationPassword" && value.trim() !== "") {
      var storedHash = this.state.customerdetail.customer_password;
      var isPasswordCorrect = bcrypt.compareSync(value, storedHash);

      if (!isPasswordCorrect) {
        error_deactivationPassword = true;
        deactivatebutton = false;
        error_deactivationPassword_msg = "Password is not correct";
      } else if (isPasswordCorrect) {
        deactivatebutton = true;
      }
    }
    this.setState({
      [name]: value,
      error_deactivationPassword_msg: error_deactivationPassword_msg,
      error_deactivationPassword: error_deactivationPassword,
      deactivatebutton: deactivatebutton,
    });
  };
  handleDeactivateClick = async () => {
    var error = 0;
    if (!this.state.isAgreedForDeactivation) {
      error++;
      this.setState({ error_isAgreedForDeactivation: true });
    }

    if (!this.state.selectedReason) {
      error++;
      this.setState({
        error_selectedReason: true,
      });
    }
    if (error === 0) {
      showLoader("processDeactivate");
      var formData = new FormData();
      formData.append("customer_id", base64.encode(cookie.load("UserId")));
      formData.append("customer_status", "D");
      formData.append("deactivate_reason", this.state.selectedReason);

      axios
        .post(apiUrl + "customer/deactivation", formData, fizoheader)
        .then((res) => {
          if (res.data.status === "ok") {
            showAlert("Success", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
              showCloseBtn: false,
              closeOnBgClick: false,
            });
            cookie.remove("UserId", { path: "/logout" });
            this.props.history.push("/logout");
          } else if (res.data.status === "error") {
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
          console.log(res.data.message);
        })
        .catch((error) => {
          console.error("Error changing password:", error);
        });
    }
  };
  showhidePWD() {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  }
  Closepopup(index) {
    console.log(index, "closed");
    if (index === "deactivation") {
      $.magnificPopup.close({
        items: {
          src: "#deactivation_popup",
        },
        type: "inline",
        showCloseBtn: false,
        midClick: true,
        closeOnBgClick: false,
      });
      cookie.remove("UserId", { path: "/logout" });
      this.props.history.push("/logout");
    } else if (index === "password_updated") {
      $.magnificPopup.close({
        items: {
          src: "#alert_popup",
        },
        type: "inline",
        showCloseBtn: true,
        midClick: true,
        closeOnBgClick: false,
      });
      cookie.remove("UserId", { path: "/logout" });
      this.props.history.push("/logout");
    } else if (index === "gender") {
      $.magnificPopup.close({
        items: {
          src: "#msg-pop",
        },
        type: "inline",
        showCloseBtn: true,
        midClick: true,
        closeOnBgClick: false,
      });
    }
  }

  handleChangePassword = () => {
    var error = 0;
    //   const PasswordsPattern =
    // /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

    if (!this.state.isAgreedForChangePassword) {
      error++;
      this.setState({ error_isAgreedForChangePassword: true });
    }

    if (!this.state.current_password_matched) {
      error++;
      this.setState({
        error_currentPassword: true,
        error_currentPassword_msg: "Password is not correct",
      });
    }

    if (
      this.state.newPassword.trim() === "" ||
      this.state.confirmPassword.trim() === ""
    ) {
      error++;
      this.setState({
        error_newPassword: true,
        error_confirmPassword: true,
        error_confirmPassword_msg: "Please enter both passwords correctly",
      });
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      error++;
      this.setState({
        error_confirmPassword: true,
        error_confirmPassword_msg: "Passwords do not match",
      });
    } else if (this.state.newPassword.length < 8) {
      error++;
      this.setState({
        error_newPassword: true,
        error_newPassword_msg: "password should be minimum 8 digits",
      });
    } else if (this.state.confirmPassword.length < 8) {
      error++;
      this.setState({
        error_confirmPassword: true,
        error_confirmPassword_msg: "password should be minimum 8 digits",
      });
    }
    // else if (!PasswordsPattern.test(this.state.newPassword)) {
    //   error++;
    //   this.setState({
    //     error_newPassword: true,
    //     error_newPassword_msg: "Password does not meet the required criteria",
    //   });
    // }

    if (error === 0) {
      var formData = new FormData();
      formData.append("refrence", base64.encode(cookie.load("UserId")));
      formData.append("oldpassword", base64.encode(this.state.currentPassword));
      formData.append("password", base64.encode(this.state.newPassword));
      formData.append(
        "confirmpassword",
        base64.encode(this.state.confirmPassword)
      );

      axios
        .post(apiUrl + "customer/changepassword", formData, fizoheader)
        .then((res) => {
          if (res.data.status === "ok") {
            // showAlert("success", res.data.message);
            this.setState({
              msgPop: res.data.message,
            });
            $.magnificPopup.open({
              items: {
                src: "#alert_popup",
              },
              type: "inline",

              closeOnBgClick: false,
              showCloseBtn: false,
            });
          } else {
            showAlert("Alert", res.data.message);
            this.setState({
              msgPop: res.data.message,
            });
            $.magnificPopup.open({
              items: {
                src: "#alert_popup",
              },
              type: "inline",
            });
          }
        })
        .catch((error) => {
          console.error("Error changing password:", error);
        });
    }
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };

  render() {
    console.log(this.state, "state values")
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        {Object.keys(this.state.customerdetail) > 0 !== "" && (
          <section className="innersection">
            <div className="container">
              <div className="admin-profile">
                <Sidebar pageName="profile" />
                <div className="admin-profile-rhs">
                  <h5>
                    Welcome{" "}
                    <span> {this.state.customerdetail.customer_name}</span>,
                  </h5>
                  <div className="profile-detail-innerdiv">
                    <div className="profile-detail-left">
                      <div className="profile-imgdiv">
                        <a href={void 0}>
                          {this.state.imageurl !== "" &&
                          this.state.imageurl !== null ? (
                            <img src={this.state.imageurl} alt="" />
                          ) : (
                            <Avatar
                              name={this.state.customerdetail.customer_name}
                              size="150"
                              round={true}
                              color="#4258bf"
                            />
                          )}
                          <span>
                            <form
                              action="#"
                              method="post"
                              encType="multipart/form-data"
                            >
                              <input
                                type="file"
                                id="profile_image"
                                className="custom-file-input"
                                onChange={(event) => {
                                  this.uplaodFiles(event);
                                  return false;
                                }}
                              />
                            </form>
                          </span>
                        </a>
                      </div>

                      <div className="profile-edtbtn">
                        <button
                          className="button"
                          onClick={this.openPopup.bind(
                            this,
                            "#editprofile-popup"
                          )}
                        >
                          <img src={profileeditImg} alt="" /> Edit Details
                        </button>
                      </div>
                    </div>
                    {/** profile Detail Left End **/}

                    <div className="profile-detail-right">
                      <ul>
                        <li>
                          <div className="profile-Infield">Name</div>
                          <div className="profile-Outfield">
                            {this.state.customerdetail.customer_name}
                          </div>
                        </li>

                        <li>
                          <div className="profile-Infield">E-mail address</div>
                          <div className="profile-Outfield">
                            <a href={void 0}>
                              {this.state.customerdetail.customer_email}
                            </a>
                          </div>
                        </li>

                        <li>
                          <div className="profile-Infield">Gender</div>
                          <div className="profile-Outfield">
                            {(() => {
                              if (
                                this.state.customerdetail.customer_gender ===
                                "M"
                              ) {
                                return <span>Male</span>;
                              } else if (
                                this.state.customerdetail.customer_gender ===
                                "F"
                              ) {
                                return <span>Female</span>;
                              } else if (
                                this.state.customerdetail.customer_gender ===
                                "O"
                              ) {
                                return <span>Others</span>;
                              } else {
                                return <span>-</span>;
                              }
                            })()}
                          </div>
                        </li>

                        <li>
                          <div className="profile-Infield">Contact</div>
                          <div className="profile-Outfield">
                            <a href={void 0}>
                              {this.state.customerdetail.customer_phone}
                            </a>
                          </div>
                        </li>

                        <li>
                          <div className="profile-Infield">Password</div>
                          <div className="profile-Outfield">
                            <span> ........</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/** profile Detail Right End **/}
                  </div>
                  <div className="password-con-box">
                    <div className="title-with-description">
                      <h2>
                        Change <span> password</span>
                      </h2>
                    </div>
                    <div className="pwd-body-form">
                      <div
                        className={
                          this.state.current_password_matched
                            ? "pwd-tick-show form-group"
                            : "form-group "
                        }
                      >
                        <label className="control-label">
                          Current password <em>*</em>
                        </label>
                        <input
                          type="password"
                          name="currentPassword"
                          value={this.state.currentPassword}
                          placeholder="Enter your current password"
                          onChange={this.handleChange}
                          disabled={this.state.current_password_matched}
                        />
                        {this.state.error_currentPassword && (
                          <div className="error">
                            {this.state.error_currentPassword_msg}
                          </div>
                        )}
                        {this.state.current_password_matched && (
                          <div className="pwd-tick">
                            <span>&#10004;</span>
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label className="control-label">
                          New password <em>*</em>
                        </label>
                        <input
                          type="password"
                          name="newPassword"
                          value={this.state.newPassword}
                          placeholder="Enter your new password"
                          onChange={this.handleChange}
                          disabled={!this.state.current_password_matched}
                        />
                        {this.state.error_newPassword && (
                          <div className="error">
                            {this.state.error_newPassword_msg}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          Confirm password <em>*</em>
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          placeholder="Confirm your new password"
                          onChange={this.handleChange}
                          disabled={!this.state.current_password_matched}
                        />
                        {this.state.error_confirmPassword && (
                          <div className="error">
                            {this.state.error_confirmPassword_msg}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="with-chk-save">
                      <p>
                        <input
                          type="checkbox"
                          name="isAgreedForChangePassword"
                          checked={this.state.isAgreedForChangePassword}
                          onChange={this.handleAgreementChange}
                        />

                        <span>
                          By resetting your account password you agree to our{" "}
                          <a href={void 0}>
                            Terms and Conditions, Privacy policy.
                          </a>
                        </span>
                      </p>
                      <a
                        href={void 0}
                        className="button dis-button"
                        onClick={this.handleChangePassword}
                      >
                        Change password
                      </a>
                    </div>
                    {this.state.error_isAgreedForChangePassword && (
                      <div className="error">This field is required</div>
                    )}
                  </div>

                  <div className="profile-detail-footer">
                    {/*  <p>
                      By deleting account you agree the{" "}
                      <a href="#">Term & Condition</a> and{" "}
                      <a href="#">privacy Policy</a>
                    </p>
                    <button type="Delete Account" className="button">
                      Delete Account
                    </button> */}
                    <div className="password-con-box">
                      <div className="title-with-description">
                        <h2>
                          Account <span> deactivation</span>
                        </h2>
                      </div>
                      <div className="deactive-form">
                        <div className="deactive-form-txt">
                          <h6>
                            What happens when you deactivate your account?
                          </h6>
                          <ul>
                            <li>
                              Your saved details will be deleted. Your booking &
                              cancellation and Wishlist details will not be
                              accessible.
                            </li>
                            {/* <li>
                              You will not be able to create packages and
                              receive commission anymore.
                            </li> */}
                            <li>
                              You will not be able to re-activate the same
                              account again.
                            </li>
                          </ul>
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            I want to deactivate my account because..
                          </label>

                          <select
                            name="selectedReason"
                            value={this.state.selectedReason}
                            onChange={this.handleChange}
                          >
                            <option value="">Select a reason</option>
                            {deactivationReasons.map((reason, index) => (
                              <option key={index} value={reason}>
                                {reason}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {this.state.error_selectedReason && (
                        <div className="error">Please select a reason</div>
                      )}

                      <div className="with-chk-save">
                        <p>
                          <input
                            type="checkbox"
                            name="isAgreedForDeactivation"
                            checked={this.state.isAgreedForDeactivation}
                            onChange={this.handleAgreementChange}
                          />

                          <span>
                            By deactivating your account you agree, that you
                            have understood the consequences. And you agree to
                            the
                            <a href={void 0}>
                              Terms and Conditions, Privacy policy.
                            </a>
                          </span>
                        </p>
                        <a
                          href={void 0}
                          className="button dis-button"
                          onClick={this.openPopUpDeactivate}
                        >
                          Deactivate account
                        </a>
                      </div>
                      {this.state.error_isAgreedForDeactivation && (
                        <div className="error">This field is required</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="editprofile-popup" className="mfp-hide common-popup">
              <div className="cp-header">
                <h3>Your Profile</h3>
                <p>Update your profile below.</p>
              </div>
              {this.state.editprofile === true && (
                <Form
                  {...this.props}
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleFormSubmit}
                  onInvalid={() => console.log("Form invalid!")}
                />
              )}
            </div>
          </section>
        )}
        <div id="deactiveConfirm" className="mfp-hide common-popup textcenter">
          <h4>To confirm deactivation, enter your account password below!</h4>
          <div className="pt-crm-form">
            <div className="form-group">
              <label className="control-label">
                Enter password <em>*</em>
              </label>
              <input
                type={this.state.passwordType}
                name="deactivationPassword"
                value={this.state.deactivationPassword}
                placeholder="Enter your current password"
                onChange={this.handleChangeDeact}
              />
              {this.state.error_deactivationPassword === true && (
                <div className="error">
                  {this.state.error_deactivationPassword_msg}
                </div>
              )}
              <span
                className={
                  this.state.passwordType === "text" ? "active" : "posab"
                }
              >
                <i
                  className="fa fa-eye-slash"
                  aria-hidden="true"
                  onClick={this.showhidePWD.bind(this)}
                ></i>
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  onClick={this.showhidePWD.bind(this)}
                ></i>
              </span>
            </div>
            <a
              href={void 0}
              className={
                this.state.deactivatebutton === true
                  ? "button"
                  : "button debutton"
              }
              id="processDeactivate"
              onClick={
                this.state.deactivatebutton === true
                  ? this.handleDeactivateClick
                  : null
              }
            >
              Deactivate
            </a>
          </div>
          <div className="small-by">
            <span>*</span> By clicking "Deactivate" you agree, that you have
            understood the consequences of changing your creator profile details
            as it may affect your package performances, commissions and payouts.
          </div>
        </div>
        <div id="gender-msg-pop" className="mfp-hide common-popup">
          <div className="textcenter">
            <h3>Updated</h3>
            <p>{this.state.msgPop}</p>
            <button onClick={this.Closepopup.bind(this, "gender")}>ok</button>
          </div>
        </div>
        <div id="alert_popup" className="mfp-hide common-popup">
          <div className=" textcenter">
            <div className="cp-body">
              <p> {this.state.msgPop}</p>
              <button onClick={this.Closepopup.bind(this, "password_updated")}>
                Ok
              </button>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  console.log(state, "redux state value")
  var custdetailArr = Array(); 
  var common = Array();
  var uploadfiles = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      // common = state.customerdetail[0].customerdetail;
    }
  }
  if (Object.keys(state.uploadfiles).length > 0) {

    if (state.uploadfiles[0].status === "ok") {
      console.log(state.uploadfiles[0], "response")
      uploadfiles = state.uploadfiles[0]
    }
  }


  return {
    customerdetail: custdetailArr,
    common: common,
    updatecustomerprofile: state.updatecustomerprofile,
    uploadfiles: uploadfiles
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
    getUpdateCustomerProfile: (formPayload) => {
      dispatch({ type: GET_UPDATECUSTOMERPROFILE, formPayload });
    },
    GetUploadFiles: (formPayload) => {
      dispatch({ type: GET_UPLOAD_FILES, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
