/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CREATOR_LOGINDATA, SET_CREATOR_LOGINDATA } from "../actions";
import { userapiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCreatorLoginData = function* () {
  yield takeEvery(GET_CREATOR_LOGINDATA, workerGetCreatorLoginData);
};

function* workerGetCreatorLoginData({ formPayload }) {
  try {
    const result = yield call(getCreatorLoginData, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CREATOR_LOGINDATA, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getCreatorLoginData(formPayload) {
  return Axios.post(userapiUrl + "creators/login", formPayload, fizoheader);
}
