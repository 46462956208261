/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import { Link } from "react-router-dom";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

function validationForgot(props) {
  const { email } = props.fields;
  return {
    fields: ["email"],
    validations: {
      email: [[isEmpty, email]],
    },
  };
}

class Forgotpassword extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgEmail, errMsgPassword;
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    return (
      <div>
        <div className="cp-form">
          <div className="cp-form-inner">
            <div className="form-group no-margin-btm">
              <input
                type="email"
                placeholder="Enter your email"
                value={fields.email}
                {...$field("email", (e) => onChange("email", e.target.value))}
              />
            </div>

            <div className="form-button">
              <button
                className="button forgotpassword-cls"
                id="forgotpasswordbtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.$submit(onValid, onInvalid);
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        <div className="cp-form-footer">
          <p>
            By resetting your account password you agree to our
            <Link to={"/terms-and-conditions"} target="_blank" className="link">
              Terms &amp; conditions,
            </Link>{" "}
            <Link
              to={"/cancellation-and-refund"}
              target="_blank"
              className="link"
            >
              Cancellation and Refund
            </Link>{" "}
            and{" "}
            <Link to={"/privacy-policy"} target="_blank" className="link">
              Privacy policy
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

Forgotpassword = validated(validationForgot)(Forgotpassword);

export default Forgotpassword;
