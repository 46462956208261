/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import { apiUrl, fizoheader } from "../Helpers/Config";
import Select from "react-select";
import bpin from "../../common/images/map-pin.svg";
import bcalender from "../../common/images/calender-banner.svg";
import bprofile from "../../common/images/banner-profile.svg";
class createNewPackage extends Component {
  constructor(props) {
    super(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    }
    //  else {
    //   this.props.history.push("/");
    // }
    this.state = {
      CUserId: CUserId,
      airport: [],
      selectedairport: [],
      numberofdays: "",
      whomgoing: [],
      address: "",
      packagecountry: "",
      packageCity: "",
      pacakgeLat: "",
      packageLon: "",
      error_address: false,
      error_numberofdays: false,
      error_whomgoing: false,
      error_selectedairport: false,
      packageLoading: true,
      cityList: [],
      selectedpackagecity: [],
      airport_id: "",
    };
  }
  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value, [name + "_error"]: "" });
  }
  onChange(name, value) {
    console.log(name, value, "valuevalue");
    if (name === "selectedpackagecity") {
      var splitValue = value.value.split("~");
      console.log(splitValue, "splitValuesplitValue");
      this.setState({
        [name]: value,
        address: splitValue[0],
        packagecountry: splitValue[10],
        pacakgeLat: splitValue[3],
        packageLon: splitValue[4],
        packageCity: splitValue[8],
        airport_id: splitValue[2],
      });
    } else {
      this.setState({ [name]: value });
    }
  }
  continuePackage() {
    var error = 0;
    if (this.state.address === "") {
      error++;
      this.setState({ error_address: true });
    }
    if (this.state.numberofdays === "") {
      error++;
      this.setState({ error_numberofdays: true });
    }
    if (Object.keys(this.state.whomgoing).length === 0) {
      error++;
      this.setState({ error_whomgoing: true });
    }
    if (error === 0) {
      var packagedetails = {
        address: this.state.address,
        packagecountry: this.state.packagecountry,
        packageCity: this.state.packageCity,
        pacakgeLat: this.state.pacakgeLat,
        packageLon: this.state.packageLon,
        numberofdays: this.state.numberofdays,
        whomgoing: this.state.whomgoing,
        selectedairport: this.state.airport_id,
      };
      $.magnificPopup.close();
      this.props.history.push({
        pathname: "/myaccount/create-package",
        state: packagedetails,
      });
    }
  }
  openPopup() {
    $.magnificPopup.open({
      items: {
        src: "#createpackage",
      },
      type: "inline",
      midClick: true,
    });
  }
  loadAirport() {
    axios
      .get(
        apiUrl + "settings/getAirport?country=" + this.state.packagecountry,
        fizoheader
      )
      .then((res) => {
        var airport = [];
        if (res.data.status === "ok") {
          res.data.result_set.map((item) => {
            airport.push({
              value: item.airport_id,
              label: item.airport_name + " - " + item.airport_code,
            });
          });
          this.setState({ airport: airport });
        }
      });
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "address") {
      if (value !== "") {
        var address = $("#pac-input").val();
        var addressdetails = value.split("~~");
        this.setState(
          {
            address: address,
            packagecountry: addressdetails[0],
            pacakgeLat: addressdetails[1],
            packageLon: addressdetails[2],
            packageCity: addressdetails[3],
          },
          function () {
            if (this.state.packagecountry !== "") {
              this.loadAirport();
            }
          }
        );
      }
    }
    if (field === "cityList" && value !== "") {
      var cityList = [];
      if (Object.keys(value.result_set).length > 0) {
        Object.keys(value.result_set).map((item) => {
          var selectedCountry = value.result_set[item];
          selectedCountry.city.map((city) => {
            cityList.push({
              label: city.city_name + ", " + city.country_name,
              value:
                city.airport_address +
                "~" +
                city.airport_code +
                "~" +
                city.airport_id +
                "~" +
                city.airport_latitude +
                "~" +
                city.airport_longitude +
                "~" +
                city.airport_name +
                "~" +
                city.city_code +
                "~" +
                city.city_id +
                "~" +
                city.city_name +
                "~" +
                city.country_code +
                "~" +
                city.country_name +
                "~" +
                city.webbeds_city_code,
            });
          });
        });
      }
      this.setState(
        {
          cityList: cityList,
        },
        function () {}
      );
    }
  };

  render() {
    return (
      <>
        <div id="createpackage" className="mfp-hide common-popup">
          <div className="step-banner-form">
            <h3 className="textcenter color-header">
              Create unique <span>vacation</span>
            </h3>
            <div className="step-banner-whom step-banner-stay">
              <Select
                value={this.state.selectedpackagecity}
                placeholder="Select Package City"
                onChange={this.onChange.bind(this, "selectedpackagecity")}
                options={this.props.cityList}
              />
              {/*  <Autocomplete
            sateValChange={this.sateValChange}
            placeholder="Enter your perfect destination."
          /> */}
              <img src={bpin} />
              {this.state.error_address === true && (
                <div className="error">This field is required</div>
              )}
            </div>
            <div className="step-banner-whom create-whom">
              <input
                type="text"
                placeholder="Number of days"
                value={this.state.numberofdays}
                name="numberofdays"
                onChange={this.handleChange.bind(this)}
              />
              <img src={bcalender} />
              {this.state.error_numberofdays === true && (
                <div className="error">This field is required</div>
              )}
            </div>

            <div className="step-banner-whom">
              <Select
                value={this.state.whomgoing}
                placeholder="Whom you are going with?"
                onChange={this.onChange.bind(this, "whomgoing")}
                options={[
                  {
                    value: "Couple",
                    label: "Couple",
                  },
                  {
                    value: "Family",
                    label: "Family",
                  },
                  {
                    value: "Friends",
                    label: "Friends",
                  },
                  {
                    value: "Solo",
                    label: "Solo",
                  },
                ]}
              />
              <img src={bprofile} />
              {this.state.error_whomgoing === true && (
                <div className="error">This field is required</div>
              )}
            </div>
            <button
              type="submit"
              className="button"
              onClick={this.continuePackage.bind(this)}
            >
              Create Packages
            </button>
          </div>
        </div>
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(createNewPackage);
