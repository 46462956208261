import { SET_BOOKINGHISTORY } from "../actions";

const bookinghistory = (state = [], action) => {
  switch (action.type) {
    case SET_BOOKINGHISTORY:
      return [...action.value];
    default:
      return state;
  }
};

export default bookinghistory;
