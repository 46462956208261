/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_PRODUCTLIST, SET_PRODUCTLIST } from "../actions";
import { apiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";
export const watchGetProductList = function* () {
  yield takeEvery(GET_PRODUCTLIST, workerGetProductList);
};

function* workerGetProductList(datas) {
  try {
    const uri = apiUrl + "products/getAllProducts?" + datas.params;
    const result = yield call(Axios.get, uri, fizoheader);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCTLIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Product list Failed");
  }
}
