/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import abt from "../../common/images/helpbg.jpg";
import Axios from "axios";
import { HelpApiUrl, fizoheader } from "../Helpers/Config";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

class Aboutus extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    var Title = "";
    if (
      this.props.location.state.Title !== "" &&
      this.props.location.state.Title !== undefined &&
      this.props.location.state.Title !== "undefined"
    ) {
      Title = this.props.location.state.Title;
    }
    var Category = "";
    if (
      this.props.location.state.Category !== "" &&
      this.props.location.state.Category !== undefined &&
      this.props.location.state.Category !== "undefined"
    ) {
      Category = this.props.location.state.Category;
    }

    this.state = {
      contentType: "helpCenter",
      helpLine: [],
      helpDescription: "",
      Title: Title,
      Categories: Category,
      defaultTitle: "How to create creator account?",
      category: [],
      searchText: "",
      searchData: [],
      filteredData: [],
      Title: "",
    };
  }
  componentDidMount() {
    this.loadHelpLine();
    this.helpArticle();
  }

  loadHelpLine() {
    Axios.get(
      HelpApiUrl +
        `blog-posts?page=1&limit=20&contentType=${this.state.contentType}`
    )
      .then((response) => {
        const dataItems = response.data.filter(
          (item) => item.category === this.state.Categories
        );
        this.setState({
          helpLine: response.data,
          category: dataItems,
          searchData: response.data,
        });

        if (response.data.length > 0) {
          this.helpDescription(
            this.state.Title !== "" ? this.state.Title : dataItems[0].blogtitle
          );
        }
      })
      .catch((error) => {
        console.error("Error loading help line:", error);
      });
  }
  helpDescription(helping) {
    const description = this.state.helpLine.find(
      (help) => help.blogtitle === helping
    );
    if (description) {
      this.setState({
        helpDescription: description.description,
        Title: helping,
      });
    }
    // this.setState({
    //   helpDescription: description,
    //   Title: helping,
    // });
  }
  filterData() {
    console.log(this.state.searchData, "response");
    const { searchData, searchText } = this.state;
    if (searchText !== "") {
      const filteredData = searchData.filter((item) =>
        item.blogtitle.toLowerCase().includes(searchText.toLowerCase())
      );
      this.setState({ filteredData });
    } else {
      this.setState({ filteredData: [] });
    }
  }

  handleChange(e) {
    const text = e.target.value;
    this.setState({ searchText: text }, () => {
      this.filterData();
    });
  }
  helpArticle(res) {
    console.log(res);
    if (res === "Yes") {
      NotificationManager.success("Share Link has been copied successfully");
    }
  }
  searchHelp(category) {
    console.log(category, "category");
    if (category !== "") {
      // this.props.history.push({
      //   pathname: "/helpcenter-detail",
      //   state: {
      //     Category: category,
      //   },
      // });
      this.setState(
        {
          Title: category.blogtitle,
          Category: category.category,
        },
        function () {
          this.loadHelpLine();
        }
      );
    }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    console.log(this.state.Title, "title");
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div className="abt-banner rel">
          <img src={abt} alt="backgroundimg" />
          <h1>Help Center</h1>{" "}
        </div>
        <div>
          <div className="search-topics">
            <input
              type="text"
              placeholder="Search the Topics "
              value={this.state.searchText}
              onChange={this.handleChange.bind(this)}
            />
          </div>

          <ul>
            {this.state.filteredData.map((item, index) => (
              <li key={index}>
                <div
                  className="search-show"
                  onClick={this.searchHelp.bind(this, item)}
                  style={{ color: "black" }}
                >
                  <p>{item.blogtitle}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="bread-crumbs">
          <div className="container">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/myaccount/helpcenter">
                  {" "}
                  <a href={void 0}>helpCenter</a>
                </Link>
              </li>
              <li>
                <a href={void 0}>{this.state.Categories}</a>
              </li>
              <li>
                <a href={void 0}>{this.state.Title}</a>
              </li>
              {/* <li>{this.state.productdetail.products_name}</li> */}
            </ul>
          </div>
        </div>

        <div className="help-content-topics">
          <div className="container">
            <div className="help-content-inner">
              <div className="hci-lhs">
                {this.state.category.map((item, index) => {
                  return (
                    <ul key={index}>
                      <li>
                        <a
                          href={void 0}
                          onClick={this.helpDescription.bind(
                            this,
                            item.blogtitle
                          )}
                        >
                          {item.blogtitle}
                        </a>{" "}
                      </li>
                    </ul>
                  );
                })}
              </div>

              <div className="hci-rhs">
                <div className="main-txt">
                  <h2>{this.state.Title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.helpDescription,
                    }}
                  ></p>
                  {/* <p>
                    Nulla et tincidunt diam. Donec cursus metus volutpat,
                    pharetra lorem varius, laoreet leo. Nunc tincidunt accumsan
                    purus, in vehicula urna tristique et. Vestibulum quam ex,
                    pellentesque vulputate orci sed, elementum accumsan tortor.
                    Phasellus ultricies cursus consequat. Aenean molestie tortor
                    eget commodo suscipit. Aliquam ut dolor sagittis, aliquam
                    eros non, dapibus dolor.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

export default Aboutus;
