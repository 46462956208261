/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Helmet } from "react-helmet";
import { subApiurl } from "../Helpers/Config";
import {
  step2,
  landingpath,
  mobsearch,
  hs1,
  hs3,
  rgs,
  crbox,
  crtool,
  viewtool,
  viewtool1,
} from "../Helpers/Images";

var mobilesteps = {
  items: 2,
  loop: false,
  dots: false,
  nav: false,
  margin: 10,
  stagePadding: 30,
  responsive: {
    0: {
      items: 1,
      margin: 10,
      stagePadding: 20,
    },
    480: {
      items: 2,
      margin: 10,
      stagePadding: 20,
    },
  },
};

class Creatoranding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subMail: "",
      submsg: "",
      subvalid: false,
      emailvalid: false,
      Errorpop: false,
      isFlight: false,
      isActivities: false,
      isVacation: true,
    };
  }
  componentDidMount() {
    $(".counter").countUp();
  }
  openLogin() {
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      this.props.history.push("/myaccount/dashboard");
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
        midClick: true,
      });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const { subMail, subvalid, emailvalid } = this.state;
    const data = {
      email: subMail,
    };

    if (
      // subvalid === false &&
      emailvalid === false &&
      this.state.subMail !== ""
    ) {
      axios.post(subApiurl + "subscribe", data).then((response) => {
        if (response.data.status === "ok") {
          this.setState(
            {
              submsg: response.data.message,
              subMail: "",
              Errorpop: true,
            },
            function () {
              $.magnificPopup.open({
                items: {
                  src: "#earning-details",
                },
                type: "inline",
                showCloseBtn: false,
                midClick: true,
                closeOnBgClick: false,
              });
            }
          );
        } else if (response.data.status === "error") {
          this.setState(
            {
              submsg: response.data.error,
              Errorpop: true,
              subMail: "",
            },
            function () {
              $.magnificPopup.open({
                items: {
                  src: "#earning-details",
                },
                type: "inline",
                showCloseBtn: false,
                midClick: true,
                closeOnBgClick: false,
              });
            }
          );
        }
      });
    }
  };
  sateValChange = (field, value) => {
    console.log(field, value, "values");
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  handleNameChange = (event) => {
    this.setState({ subMail: event.target.value });
    if (event.target.value === "") {
      this.setState({ subvalid: true });
    } else {
      this.setState({ subvalid: false });
    }
    if (
      !/^\S+@\S+\.\S+$/.test(event.target.value) &&
      event.target.value !== ""
    ) {
      this.setState({ emailvalid: true });
    } else {
      this.setState({ emailvalid: false });
    }
  };

  closePopup() {
    $.magnificPopup.close();
    this.setState({
      Errorpop: false,
      submsg: "",
    });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Fizota - Join as a Digital Travel Creator & Earn Income</title>
          <link rel="canonical" href="https://www.fizota.com/creator" />
          <meta
            name="description"
            content="Fizota.com: Embrace your creativity and inspire digital travelers. Craft compelling experiences that leave a lasting impact. Join us as a creator, share your passion, and earn passive income. Start inspiring today!"
          />
          <meta
            name="keywords"
            content="travel creator, travel itinerary creator, travel content creator, travel creators, travel blog creator, trip creator"
          />
          {/* Add more meta tags or other head elements as needed */}
        </Helmet>
        <Header {...this.props} sateValChange={this.sateValChange} />

        <section className="step-banner land-banner">
          <img className="full-width" src={landingpath} />
          <img className="right-corner" src={rgs} />
          <div className="container">
            <div className="step-banner-content">
              <div className="step-banner-lhs">
                <h1>
                  <span>Become </span>a Travel Creator.
                  <br />
                  <span>Inspire </span> people.
                </h1>
                <p>
                  Help digital travelers by crafting inspiring
                  <br />
                  experiences and earn passive income.
                </p>
                <a
                  href={void 0}
                  onClick={this.openLogin.bind(this)}
                  className="button"
                >
                  Start crafting experiences
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-banner-search">
          <div className="container">
            <div className="cc-search">
              <input type="text" className="m-search" />
              <img className="cc-img" src={mobsearch} />
            </div>
          </div>
        </section>
        <section className="pack-and-step">
          <div className="container">
            <div className="platform-step">
              <h1 className="color-header textcenter">
                Create <span>packages</span> and <span>earn</span> more.
              </h1>
              <ul>
                <li>
                  <figure>
                    <img src={hs1} alt="add-creator" />
                  </figure>
                  <h5>Step 1</h5>
                  <p>
                    Sign up as a creator. And create inspiring packages using
                    our wider inventory for free.
                  </p>
                </li>
                <li>
                  <figure>
                    <img src={step2} alt="step1" />
                  </figure>
                  <h5>Step 2</h5>
                  <p>
                    List and share your packages in the platform, with your
                    followers.
                  </p>
                </li>
                <li>
                  <figure>
                    <img src={hs3} alt="step" />
                  </figure>
                  <h5>Step 3</h5>
                  <p>
                    Earn passive commission upto 30% every time when your
                    packages are booked.
                  </p>
                </li>
              </ul>
              <div className="step-slide">
                <OwlCarousel options={mobilesteps}>
                  <div>
                    <figure>
                      <img src={hs1} alt="step1" />
                    </figure>
                    <h5>Step 1</h5>
                    <p>
                      Get inspired by the pre-built packages by the community or
                      Craft your own package.
                    </p>
                  </div>
                  <div>
                    <figure>
                      <img src={step2} alt="step12" />
                    </figure>
                    <h5>Step 2</h5>
                    <p>
                      Choose your travel needs from the wider inventory and get
                      the real-time prices.
                    </p>
                  </div>
                  <div>
                    <figure>
                      <img src={hs3} alt="step3" />
                    </figure>
                    <h5>Step 3</h5>
                    <p>
                      Book all your travel itinerary with a single click. Redeem
                      with a single ticket.
                    </p>
                  </div>
                </OwlCarousel>
              </div>
              <div className="center-btn textcenter">
                <a
                  href={void 0}
                  onClick={this.openLogin.bind(this)}
                  className="button"
                >
                  Create packages
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="fiz-understand">
          <div className="container">
            <div className="fiz-mid">
              <div className="fiz-mid-lhs">
                <span>Fizota understands creators</span>
                <h3>
                  So, we provide <em>free</em>
                  <br />
                  access to our wider
                  <br />
                  inventory.
                </h3>
              </div>
              <div className="fiz-mid-center">
                <ul>
                  <li>
                    <h3>
                      <span className="counter">490</span>k +{" "}
                    </h3>
                    <p>Accommodations </p>
                  </li>
                  <li>
                    <h3>
                      <span className="counter">100</span>k +{" "}
                    </h3>
                    <p>Things to do</p>
                  </li>
                </ul>
              </div>
              <div className="fiz-mid-rhs">
                <img className="fmr-img-one" src={viewtool} />
                <img className="fmr-img-two" src={viewtool1} />
              </div>
            </div>
          </div>
        </section>

        <section className="creator-tool">
          <div className="container">
            <div className="ct-lhs">
              <img src={crtool} />
            </div>
            <div className="ct-rhs">
              <span>Tools for creators</span>
              <h3>
                Seamless easy to <br />
                use tools for your
                <br />
                <em>success.</em>{" "}
              </h3>
              <ul>
                <li>Analytics and dashboard</li>
                <li>Automated payout and insights</li>
                <li>Powerful itinerary search engines</li>
                <li>And more..</li>
              </ul>
              <a
                href={void 0}
                onClick={this.openLogin.bind(this)}
                className="button"
              >
                Sign up now
              </a>
            </div>
          </div>
        </section>
        <section className="passive-income">
          <div className="container">
            <div className="vacation-box-btm rel">
              <div className="vacation-box-txt">
                <span>Passive income stream</span>
                <h3>
                  Earn upto <em>30%*</em> <br />
                  revenue commission
                  <br /> each time when you
                  <br /> inspire someone.
                </h3>
              </div>
              <div className="vacation-box-img">
                <img src={crbox} alt="box" />
              </div>
            </div>
          </div>
        </section>

        <section className="comparsion-fiz">
          <div className="container">
            <div className="media-box-center">
              <div className="media-box">
                <h4>
                  Typical <span>affiliate</span> program
                </h4>
                <ul>
                  <li>Product based affiliate links. </li>
                  <li>Affiliate links have expiry time.</li>
                  <li>
                    Commission for the booking made only through affiliate link.
                  </li>
                </ul>
              </div>
              <div className="media-box mb-green">
                <h4>
                  Fizota's <span>creator</span> platform
                </h4>
                <ul>
                  <li>Travel creator community based.</li>
                  <li>Payout every time packages booked</li>
                  <li>
                    Commission is shared regardless of the bookings channels.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="home-newsletter textcenter">
          <div className="container">
            <div className="formboy">
              <h2>
                Join the community and get <span>inspired.</span>
              </h2>
              <p>
                Subscribe and get the weekly inspirational <br />
                content right at your inbox.
              </p>
              <div className="newsletter-form">
                <input
                  type="email"
                  id="email"
                  className="subscripe_email"
                  placeholder="Enter your email"
                  required=""
                  name="subMail"
                  value={this.state.subMail}
                  onChange={this.handleNameChange}
                />
                <button
                  type="submit"
                  className="button"
                  onClick={this.handleSubmit}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
          {this.state.Errorpop && (
            <div>
              <div
                id="earning-details"
                className="mfp-hide common-popup earnpop-details"
              >
                <div className="ed-header">
                  <h3>{this.state.submsg}</h3>
                </div>
                <div className="ed-body">
                  <button onClick={() => this.closePopup()}>Ok</button>
                </div>
              </div>
            </div>
          )}
        </section>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Creatoranding);
