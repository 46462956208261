/* eslint-disable */

import { Box, Button, IconButton, Modal, Tooltip } from "@mui/material";
import React from "react";
import "./AddOnModal.scss";
import {
  Close as CloseIcon,
  InfoOutlined as InfoIcon,
} from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60%",
  maxWidth: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AddOnModal = (props) => {
  const { open, handleClose, state, index, handleChange } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="add-on_main">
        <IconButton onClick={handleClose} className="close-btn">
          <CloseIcon />
        </IconButton>
        <div className="add-on_main__content">
          <div className="add-on_main__content__title">
            Give title for the add-on
          </div>
          <input
            placeholder="Ex: English speaking guide"
            type="text"
            value={state.addOns[index].title}
            onChange={(e) => handleChange(index, "title", e)}
          />
          <br />
          <br />
          <div className="add-on_main__content__title">Ticket type </div>
          <div className="add-on_main__content__sub">
            Select the ticket types where this add-ons are applicable
          </div>
          <div className="add-on_main__content__types">
            <div className="form-group">
              <input
                type="checkbox"
                checked={state.addOns[index].ticketType.includes("standard")}
                onChange={(e) => handleChange(index, "standard", e)}
              />
              &nbsp;&nbsp;
              <label className="control-label">Standard</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                checked={state.addOns[index].ticketType.includes("superior")}
                onChange={(e) => handleChange(index, "superior", e)}
              />
              &nbsp;&nbsp;
              <label className="control-label">Superior</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                checked={state.addOns[index].ticketType.includes("premium")}
                onChange={(e) => handleChange(index, "premium", e)}
              />
              &nbsp;&nbsp;
              <label className="control-label">Premium</label>
            </div>
          </div>
          <div className="add-on_main__content__title">
            Price{" "}
            <Tooltip title={"Enter the pricing on per pax basis"}>
              <InfoIcon />
            </Tooltip>{" "}
          </div>
          <div className="add-on_main__content__sub">
            The price is applicable to 1 pax only{" "}
          </div>
          <div className="add-on_main__content__seasonRow">
            {state.includedSeason.includes("peak") && (
              <div className="add-on_main__content__seasonRow__row">
                <div className="add-on_main__content__seasonRow__row__col1">
                  <div className="add-on_main__content__title">
                    Peak season{" "}
                  </div>
                  <div className="add-on_main__content__sub">
                    Per package - Nett price
                  </div>
                </div>
                <div className="add-on_main__content__seasonRow__row__col2">
                  <input
                    placeholder="Enter price"
                    type="text"
                    value={state.addOns[index].peakPrice}
                    onChange={(e) => handleChange(index, "peakPrice", e)}
                  />
                </div>
              </div>
            )}
            {state.includedSeason.includes("non-peak") && (
              <div className="add-on_main__content__seasonRow__row">
                <div className="add-on_main__content__seasonRow__row__col1">
                  <div className="add-on_main__content__title">
                    Non-Peak season{" "}
                  </div>
                  <div className="add-on_main__content__sub">
                    Per package - Nett price
                  </div>
                </div>
                <div className="add-on_main__content__seasonRow__row__col2">
                  <input
                    placeholder="Enter price"
                    type="text"
                    value={state.addOns[index].nonPeakPrice}
                    onChange={(e) => handleChange(index, "nonPeakPrice", e)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="add-on_main__content__title">
            Give description for the add-on{" "}
          </div>
          <div className="form-group">
            <textarea
              id="textarea"
              name="desp"
              placeholder="Example: English speaking guide"
              value={state.addOns[index].description}
              onChange={(e) => handleChange(index, "description", e)}
            ></textarea>
          </div>
          <div className="add-on_main__content__actionMain">
            <Button
              variant="contained"
              className="add-on_main__content__actionMain__saveBtn"
              onClick={handleClose}
            >
              Save add-on
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddOnModal;
