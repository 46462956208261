/* eslint-disable */

import { Box, Button, IconButton, Modal, Tooltip } from "@mui/material";
import React from "react";
import "./PricingGroup.scss";
import {
  InfoOutlined as InfoIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import minusb from "../../../common/images/minus-b.svg";
import plusb from "../../../common/images/plus-b.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "90%",
  maxWidth: "90%",
  minWidth: "60%",
  maxWidth: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const stayType = [
  [
    {
      label: "Standard",
      tooltip:
        "Enter the pricing for the packages that include 3 star or equivalent type of accommodations",
    },
    "standardPeak",
    "standardNonPeak",
  ],
  [
    {
      label: "Superior",
      tooltip:
        "Enter the pricing for the packages that include 4 star or equivalent type of accommodations",
    },
    "superiorPeak",
    "superiorNonPeak",
  ],
  [
    {
      label: "Premium",
      tooltip:
        "Enter the pricing for the packages that include 5 star and above type of accommodations",
    },
    "premiumPeak",
    "premiumNonPeak",
  ],
];

const PricingGroup = (props) => {
  const { open, handleClose, state, index, handleChange } = props;

  const loadRatingstar = (points) => {
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return <i className="fa fa-star-half-o" aria-hidden="true"></i>;
          } else {
            return <i className="fa fa-star" aria-hidden="true"></i>;
          }
        }
      });
    }
  };
  // set the input value with percentage
  const percentageFormatValue = (value) => {
    if (value > 100) {
      value = "100";
    }
    return `${value || 0} %`;
  };
  const ticketField =
    state.productType === "dayTour" ? "dayTourTicket" : "customTicket";
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="pricing-group_main">
        <IconButton onClick={handleClose} className="close-btn">
          <CloseIcon />
        </IconButton>
        <div className="pricing-group_main__content">
          <div className="pricing-group_main__content__title">Group Size </div>
          <br />
          <div className="pricing-group_main__content__paxRow">
            <div className="pricing-group_main__content__paxRow__col">
              <div className="pricing-group_main__content__paxRow__col__row">
                <div className="pricing-group_main__content__paxRow__col__row__col1">
                  <label className="pricing-group_main__content__paxRow__col__row__heading">
                    Min pax required
                  </label>
                  <label className="pricing-group_main__content__paxRow__col__row__sub">
                    Pax
                  </label>
                </div>
                <div className="addcart_row">
                  <div className="qty-bx">
                    <span
                      className="qty-minus"
                      onClick={(e) => handleChange(index, 0, "min", e, "sub")}
                    >
                      <img className="minus-ic" src={minusb} alt="Minus" />
                    </span>
                    <input
                      type="text"
                      className="qty-input"
                      readOnly={false}
                      value={state[ticketField][index].min}
                      onChange={(e) => handleChange(index, 0, "min", e)}
                    />
                    <span
                      className="qty-plus"
                      onClick={(e) => handleChange(index, 0, "min", e, "add")}
                    >
                      <img className="plus-ic" src={plusb} alt="Plus" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-group_main__content__paxRow__col">
              <div className="pricing-group_main__content__paxRow__col__row">
                <div className="pricing-group_main__content__paxRow__col__row__col1">
                  <label className="pricing-group_main__content__paxRow__col__row__heading">
                    Max pax required
                  </label>
                  <label className="pricing-group_main__content__paxRow__col__row__sub">
                    Pax
                  </label>
                </div>
                <div className="addcart_row">
                  <div className="qty-bx">
                    <span
                      className="qty-minus"
                      onClick={(e) => handleChange(index, 0, "max", e, "sub")}
                    >
                      <img className="minus-ic" src={minusb} alt="Minus" />
                    </span>
                    <input
                      type="text"
                      className="qty-input"
                      readOnly={false}
                      value={state.customTicket[index].max}
                      handleChange={(e) => handleChange(index, 0, "min", e)}
                    />
                    <span
                      className="qty-plus"
                      onClick={(e) => handleChange(index, 0, "max", e, "add")}
                    >
                      <img className="plus-ic" src={plusb} alt="Plus" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {stayType.map((item, idx) => (
            <>
              {}
              <div className="pricing-group_main__content__box">
                <div className="pricing-group_main__content__box__header">
                  <div>
                    <div className="pricing-group_main__content__box__header__title">
                      {item[0]?.label} - Adult{" "}
                      <Tooltip title={item[0]?.tooltip}>
                        <InfoIcon />
                      </Tooltip>
                    </div>
                    <div className="pricing-group_main__content__box__header__sub">
                      Usually includes {idx + 3} star or equivalent
                      accommodation
                    </div>
                  </div>
                  <div className="star-rating-review">
                    <div className="star-rating">
                      {loadRatingstar(`${idx + 3}`)}
                    </div>
                  </div>
                </div>
                <div className="pricing-group_main__content__seasonRow">
                  {state.includedSeason.includes("peak") && (
                    <div className="pricing-group_main__content__seasonRow__row">
                      <div className="pricing-group_main__content__seasonRow__row__col1">
                        <div className="pricing-group_main__content__seasonRow__row__title">
                          Peak season{" "}
                        </div>
                        <div className="pricing-group_main__content__seasonRow__row__sub">
                          Per package - Nett price
                        </div>
                      </div>
                      <div className="pricing-group_main__content__seasonRow__row__col2">
                        <input
                          placeholder="Enter price"
                          type="text"
                          value={
                            state.customTicket[index]?.pricing[idx]?.peakPrice
                          }
                          onChange={(e) =>
                            handleChange(index, idx, "peakPrice", e)
                          }
                        />
                      </div>
                    </div>
                  )}
                  {state.includedSeason.includes("non-peak") && (
                    <div className="pricing-group_main__content__seasonRow__row">
                      <div className="pricing-group_main__content__seasonRow__row__col1">
                        <div className="pricing-group_main__content__seasonRow__row__title">
                          Non-Peak season{" "}
                        </div>
                        <div className="pricing-group_main__content__seasonRow__row__sub">
                          Per package - Nett price
                        </div>
                      </div>
                      <div className="pricing-group_main__content__seasonRow__row__col2">
                        <input
                          placeholder="Enter price"
                          type="text"
                          value={
                            state.customTicket[index]?.pricing[idx]
                              ?.nonPeakPrice
                          }
                          onChange={(e) =>
                            handleChange(index, idx, "nonPeakPrice", e)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}
          <br />
          <div className="pricing-group_main__content__title">
            Select Child price as a % of adult price{" "}
          </div>
          <div className="pricing-group_main__content__sub">
            Aged between 12-18 years old{" "}
          </div>
          <br />
          <br />
          <div className="pricing-group_main__content__paxRow">
            <div className="pricing-group_main__content__paxRow__col">
              <div className="pricing-group_main__content__paxRow__col__row">
                <div className="pricing-group_main__content__paxRow__col__row__col1">
                  <label className="pricing-group_main__content__paxRow__col__row__heading">
                    With extra bed
                  </label>
                  <label className="pricing-group_main__content__paxRow__col__row__sub">
                    Select % of adult price
                  </label>
                </div>
                <div className="addcart_row">
                  <div className="qty-bx">
                    <span
                      className="qty-minus"
                      onClick={(e) =>
                        handleChange(index, 0, "withBed", e, "sub")
                      }
                    >
                      <img className="minus-ic" src={minusb} alt="Minus" />
                    </span>
                    <input
                      type="text"
                      className="qty-input"
                      readOnly={false}
                      value={percentageFormatValue(
                        state.customTicket[index].withBed
                      )}
                      onChange={(e) => handleChange(index, 0, "withBed", e)}
                    />
                    <span
                      className="qty-plus"
                      onClick={(e) =>
                        handleChange(index, 0, "withBed", e, "add")
                      }
                    >
                      <img className="plus-ic" src={plusb} alt="Plus" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-group_main__content__paxRow__col">
              <div className="pricing-group_main__content__paxRow__col__row">
                <div className="pricing-group_main__content__paxRow__col__row__col1">
                  <label className="pricing-group_main__content__paxRow__col__row__heading">
                    Without extra bed
                  </label>
                  <label className="pricing-group_main__content__paxRow__col__row__sub">
                    Select % of adult price
                  </label>
                </div>
                <div className="addcart_row">
                  <div className="qty-bx">
                    <span
                      className="qty-minus"
                      onClick={(e) =>
                        handleChange(index, 0, "withOutBed", e, "sub")
                      }
                    >
                      <img className="minus-ic" src={minusb} alt="Minus" />
                    </span>
                    <input
                      type="text"
                      className="qty-input"
                      readOnly={false}
                      value={percentageFormatValue(
                        state.customTicket[index].withOutBed
                      )}
                      onChange={(e) => handleChange(index, 0, "withOutBed", e)}
                    />
                    <span
                      className="qty-plus"
                      onClick={(e) =>
                        handleChange(index, 0, "withOutBed", e, "add")
                      }
                    >
                      <img className="plus-ic" src={plusb} alt="Plus" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pricing-group_main__content__actionMain">
            <Button
              variant="contained"
              className="pricing-group_main__content__actionMain__saveBtn"
              onClick={handleClose}
            >
              Save group
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PricingGroup;
