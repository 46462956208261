import { Box, IconButton, Modal } from "@mui/material";
import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./CalendarModal.scss";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "40%",
  maxWidth: "40%",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "hidden",
};

const CalendarModal = (props) => {
  const { open, handleClose } = props;
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleDate = (ranges) => {
    setDateRange([ranges.selection]);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="calendar-modal step-content-calender">
        <IconButton onClick={handleClose} className="close-btn">
          <CloseIcon htmlColor="#4258BF" />
        </IconButton>
        <div className="calendar-modal__datepicker">
          <DateRange
            editableDateInputs={true}
            onChange={(range) => handleDate(range)}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            minDate={new Date()}
            months={1}
            direction="horizontal"
            preventSnapRefocus={true}
          />
          <div className="save-btn">
            <button>Save</button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CalendarModal;
