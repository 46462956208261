/* eslint-disable */

import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";

class NewSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      passwordType: "password",
      confirmPassword: "",
      confirmPasswordType: "password",
      phone: "",
      errors: {
        name: "",
        email: "",
        password: "",
        UpperPassowrd: "",
        confirmPassword: "",
        LowerPassowrd: "",
        phone: "",
      },
      validerror: "",
      validemailerror: "",
      validnameerror: "",
      validpassworderror: "",
      validconfpassworderror: "",

      validhandleerror: "",
      showemail: false,
      showname: false,
      showpass: false,
      showconfirm: false,
      confpass: false,
      emailform: false,
    };
  }

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.resetField === true) {
      this.setState(
        {
          validemailerror: "",
          validnameerror: "",
          validhandleerror: "",
          name: "",
          handlename: "",
          email: "",
          password: "",
          confirmPassword: "",
        },
        () => {
          this.props.sateValChange("resetField", false);
        }
      );
    }
  }

  showhidePWD() {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  }

  showhideConfirmPWD() {
    if (this.state.confirmPasswordType === "password") {
      this.setState({ confirmPasswordType: "text" });
    } else {
      this.setState({ confirmPasswordType: "password" });
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };

  validateField(fieldName, value) {
    const errors = { ...this.state.errors };
    let passwordValid = true;
    let emailValid = true;
    let phoneValid = true;
    let nameValid = true;

    switch (fieldName) {
      case "email":
        if (value.length === 0) {
          errors.emailRequired = "Email is required";
          emailValid = false;
        } else {
          errors.emailRequired = "";
        }

        if (!/^\S+@\S+\.\S+$/.test(value) && value.length !== 0) {
          errors.emailFormat = "Invalid email format";
          emailValid = false;
          this.setState({ emailform: true });
        } else {
          errors.emailFormat = "";
        }
        break;
      // case "phone":
      //   if (value.length === 0) {
      //     errors.phoneRequired = "Phone number is required";
      //     phoneValid = false;
      //   } else {
      //     errors.phoneRequired = "";
      //   }
      //   break;
      case "password":
        if (value.length < 8) {
          errors.password = "Password must be at least 8 characters";
          passwordValid = false;
        } else {
          errors.password = "";
        }

        if (!/(?=.*\d)/.test(value)) {
          errors.UpperPassowrd = "Password must contain at least one digit";
          passwordValid = false;
        } else {
          errors.UpperPassowrd = "";
        }

        if (!/(?=.*[A-Z])/.test(value)) {
          errors.LowerPassowrd =
            "Password must contain at least one uppercase letter";
          passwordValid = false;
        } else {
          errors.LowerPassowrd = "";
        }
        break;
      case "name":
        if (value.length === 0) {
          errors.name = "Name is empty";
          nameValid = false;
        }
        break;
      case "confirmPassword":
        errors.confirmPassword =
          value !== this.state.password ? "Passwords do not match" : "";
        value !== this.state.password && this.setState({ confpass: true });
        break;
      default:
        break;
    }

    if (phoneValid && fieldName === "phone") {
      errors.phone = "Phone number is valid";
    }

    this.setState({ errors });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, confirmPassword, name, phone, errors } =
      this.state;
    let valid = 0;
    if (email === "") {
      this.setState({ validemailerror: "Field is required", showemail: true });
      valid++;
    }
    if (password === "") {
      this.setState({
        validpassworderror: "Field is required",
        showpass: true,
      });

      valid++;
    }
    if (confirmPassword === "") {
      this.setState({
        validconfpassworderror: "Field is required",
        showconfirm: true,
      });

      valid++;
    }
    if (name === "") {
      this.setState({ validnameerror: "Field is required", showname: true });
      valid++;
    }
    this.validateField("name", name);
    this.validateField("email", email);
    this.validateField("password", password);
    this.validateField("confirmPassword", confirmPassword);
    // this.validateField("phone", phone);

    if (valid === 0) {
      this.props.onFormSubmit({
        name,
        email,
        password,
        confirmPassword,
        // phone,
      });
    }
  };

  // handlePhoneChange = (phone, value) => {
  //   console.log(phone, value, "value");
  //   const phoneWithCountryCode = `1${value}`;
  //   this.setState({ phone: phoneWithCountryCode });
  //   this.validateField("phone", value);
  // };
  // setPhone(phone, data) {
  //   // ("mobile", phone);
  //   this.setState({ phone: phone });

  //   $("#countryCode").val(data.countryCode);
  // }

  render() {
    const {
      email,

      password,
      confirmPassword,
      errors,
      // phone,
      name,
      validerrors,
    } = this.state;

    return (
      <div>
        {/* <h2>Signup Form</h2> */}
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label className="control-label">Name</label>
            <input
              placeholder="Enter your full name"
              type="text"
              name="name"
              value={name}
              onChange={this.handleInputChange}
            />
            {name === "" && this.state.validnameerror && (
              <div className="error">{this.state.validnameerror}</div>
            )}
          </div>
          <div className="form-group">
            <label className="control-label">Email:</label>
            <input
              placeholder="Enter your email"
              type="email"
              name="email"
              value={email}
              onChange={this.handleInputChange}
            />
            {errors.emailFormat && (
              <div className="error">{errors.emailFormat}</div>
            )}
            {email === "" && this.state.validemailerror && (
              <div className="error">{this.state.validemailerror}</div>
            )}
          </div>
          {/* <div className="form-group">
            <label className="control-label">Phone</label>
            <input type="hidden" id="countryCode" />
            <PhoneInput
              country={"sg"}
              value={phone}
              onChange={(phone, data, event, formattedValue) => {
                this.setPhone(formattedValue, data);
              }}
            />
          </div> */}

          <div
            className={`form-group pwd-group ${
              errors.password ||
              errors.UpperPassowrd ||
              errors.LowerPassowrd ||
              (password === "" && this.state.showpass)
                ? "pwd-group-mb"
                : ""
            }`}
          >
            <label className="control-label">Password:</label>
            <div className="form-group pwd-group no-margin-btm">
              <input
                type={this.state.passwordType}
                placeholder="Enter your new password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
              />
              <span
                className={this.state.passwordType === "text" ? "active" : ""}
              >
                <i
                  className="fa fa-eye-slash"
                  aria-hidden="true"
                  onClick={this.showhidePWD.bind(this)}
                ></i>
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  onClick={this.showhidePWD.bind(this)}
                ></i>
              </span>
            </div>

            {(errors.password ||
              errors.UpperPassowrd ||
              errors.LowerPassowrd ||
              (password === "" && this.state.showpass)) && (
              <ul>
                {errors.password && (
                  <li className="error">{errors.password}</li>
                )}
                {errors.UpperPassowrd && (
                  <li className="error">{errors.UpperPassowrd}</li>
                )}
                {errors.LowerPassowrd && (
                  <li className="error">{errors.LowerPassowrd}</li>
                )}
                {password === "" && this.state.showpass && (
                  <li className="error">{this.state.validpassworderror}</li>
                )}
              </ul>
            )}
          </div>

          <div className="form-group">
            <label className="control-label">Confirm Password:</label>
            <div className="form-group pwd-group no-margin-btm">
              <input
                placeholder="Enter your password again"
                type={this.state.confirmPasswordType}
                name="confirmPassword"
                value={confirmPassword}
                onChange={this.handleInputChange}
              />
              <span
                className={
                  this.state.confirmPasswordType === "text" ? "active" : ""
                }
              >
                <i
                  className="fa fa-eye-slash"
                  aria-hidden="true"
                  onClick={this.showhideConfirmPWD.bind(this)}
                ></i>
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  onClick={this.showhideConfirmPWD.bind(this)}
                ></i>
              </span>
            </div>

            {errors.confirmPassword && (
              <div className="error">{errors.confirmPassword}</div>
            )}
            {errors.validconfpassworderror && (
              <div className="error">{errors.validconfpassworderror}</div>
            )}
          </div>
          <div className="submit-btn-main">
            <button type="submit" className="button signup_submits">
              Sign Up
            </button>
            {this.props.signupError && (
              <div className="error">{this.props.signupError}</div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default NewSignup;
