/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Axios from "axios";
import { apiUrl, fizoheader } from "../Helpers/Config";
import { hideLoader, showAlert, showLoader } from "../Helpers/SettingHelper";
import profileImg from "../../common/images/profile_img.png";
import profilepencile from "../../common/images/white-pen.svg";
import profileeditImg from "../../common/images/edit-pen.svg";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { GET_CUSTOMER_DETAIL, GET_UPDATECUSTOMERPROFILE } from "../../actions";
import Form from "./Form";
var qs = require("qs");
var base64 = require("base-64");
class Listpromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerdetail: "",
      fields: {
        customer_name: "",
        email: "",
        phone: "",
        password: "",
        gender: "",
        phone_country: "",
        phone_code: "",
      },
      imageurl: "",
      editprofile: false,
    };
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    }
    var params = "customer_id=" + cookie.load("UserId");
    this.props.getCustomerDetail(params);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
      var genderLable = {};
      if (customerDetails.customer_gender == "M") {
        genderLable = { value: "M", label: "Male" };
      } else if (customerDetails.customer_gender == "F") {
        genderLable = { value: "M", label: "Female" };
      } else if (customerDetails.customer_gender == "O") {
        genderLable = { value: "O", label: "Others" };
      }
      this.setState(
        update(this.state, {
          customerdetail: {
            $set: customerDetails,
          },
          imageurl: {
            $set:
              customerDetails.customer_photo !== null
                ? customerDetails.customer_photo /* ?.split(
                    "https://api-mp.fizota.com/media/customers/"
                  )[1] */
                : "",
          },
          fields: {
            customer_name: {
              $set:
                customerDetails.customer_name !== null
                  ? customerDetails.customer_name
                  : "",
            },
            email: {
              $set:
                customerDetails.customer_email !== null
                  ? customerDetails.customer_email
                  : "",
            },
            phone: {
              $set:
                customerDetails.customer_phone !== null
                  ? customerDetails.customer_phone_code +
                    " " +
                    customerDetails.customer_phone
                  : "",
            },
            gender: {
              $set: genderLable,
            },
            phone_country: {
              $set:
                customerDetails.customer_phone_country !== null
                  ? customerDetails.customer_phone_country
                  : "",
            },
            phone_code: {
              $set:
                customerDetails.customer_phone_code !== null
                  ? customerDetails.customer_phone_code
                  : "",
            },
          },
        })
      );
    }
    if (nextProps.updatecustomerprofile !== this.props.updatecustomerprofile) {
      const { updatecustomerprofile } = nextProps;
      if (updatecustomerprofile[0].status === "ok") {
        var params = "customer_id=" + cookie.load("UserId");
        this.props.getCustomerDetail(params);
        $(".myaccount_update .gloading_img").remove();
        showAlert("success", updatecustomerprofile[0].message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      } else {
        $(".myaccount_update .gloading_img").remove();
        if (updatecustomerprofile[0].form_error) {
          showAlert("error", updatecustomerprofile[0].form_error);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          showAlert("error", updatecustomerprofile[0].message);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      }
    }
  }
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };
  /* To Submit the personal informartion form */
  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.phone !== "" && formPayload.phone !== null) {
      var mobile = formPayload.phone.split(" ");

      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }
    var postObject = {
      customer_name: formPayload.customer_name,
      customer_phone: mobileNo,
      customer_phone_code: mobile_code,
      customer_countryCode: $("#profilecountryCode").val(),
      customer_email: formPayload.email,
      customer_gender: formPayload.gender.value,
      customer_password:
        formPayload.password !== "" ? formPayload.password : "",
      /* customer_id: cookie.load("UserId"), */
    };
    console.log(formPayload, postObject, "formPayload");
    // return false;
    $(".myaccount_update").append('<b class="gloading_img"></b>');
    /* this.props.getUpdateCustomerProfile(qs.stringify(postObject)); */
  };

  openPopup(popupID) {
    this.setState({ editprofile: true }, function () {
      $.magnificPopup.open({
        items: {
          src: popupID,
        },
        type: "inline",
        midClick: true,
      });
    });
  }
  async uplaodFiles() {
    var formData = new FormData();
    var imagefile = document.querySelector("#profile_image");
    const file = imagefile.files[0];
    console.log("file", file, this.state.fields);
    const params = {
      Bucket: "fizotametaimages",
      Key: `${cookie.load("UserId")}/${file.name}`,
      Body: file,
    };
    const { Location } = await s3.upload(params).promise();
    console.log("uploading to s3", Location);
    this.setState({ imageurl: Location });
    const formPayload = this.state.fields;
    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.phone !== "" && formPayload.phone !== null) {
      var mobile = formPayload.phone.split(" ");
      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }
    var postObject = {
      customer_name: formPayload.customer_name,
      customer_phone: mobileNo,
      customer_phone_code: mobile_code,
      customer_countryCode: $("#profilecountryCode").val(),
      customer_email: formPayload.email,
      customer_gender: formPayload.gender.value,
      customer_password:
        formPayload.password !== "" ? formPayload.password : "",
      customer_id: cookie.load("UserId"),
      customer_photo: Location,
    };
    console.log(formPayload, postObject, "formPayload");
    // return false;
    $(".myaccount_update").append('<b class="gloading_img"></b>');
    /* this.props.getUpdateCustomerProfile(qs.stringify(postObject)); */
    if (imagefile.files.length > 0) {
      formData.append("customer_photo", Location);

      formData.append("customer_id", cookie.load("UserId"));

      Axios.post(
        apiUrl + "customer/updateprofileimage",
        formData,
        fizoheader
      ).then((res) => {
        hideLoader("myaccount_update", "class");
        if (res.data.status === "ok") {
          showAlert("Success", res.data.message);
          $("#profile_image").val("");
          this.setState({ imageurl: res.data.imageurl });
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          var errormsg =
            res.data.form_error !== "" ? res.data.form_error : res.data.message;
          showAlert("Error", errormsg);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
    }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };

  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div className="bread-crumbs-other bread-crumbs">
          <div className="container">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>my profile</li>
            </ul>
          </div>
        </div>
        {Object.keys(this.state.customerdetail) > 0 !== "" && (
          <section className="innersection">
            <div className="container">
              <div className="admin-profile creator-profile">
                <Sidebar pageName="profile" />
                <div className="admin-profile-rhs">
                  <div className="payout-list">
                    <h2 className="color-header">
                      Payout <span>method</span>
                    </h2>
                    <ul className="payout-ul">
                      <li>
                        <div className="paynames">
                          <h4>Malaikolundu Gowtham</h4>
                          <p>DBS Bank Singapore</p>
                          <span>Bank transfer</span>
                        </div>
                        <div className="payedit">
                          <a href="#" className="button">
                            <img src={profilepencile} alt="white-pen" /> Edit
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="paynames">
                          <h4>Vinoth</h4>
                          <p>RBL Bank Singapore</p>
                          <span>Bank transfer</span>
                        </div>
                        <div className="payedit">
                          <a href="#" className="button">
                            <img src={profilepencile} alt="white-pen" /> Edit
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="editprofile-popup" className="mfp-hide common-popup">
              <div className="cp-header">
                <h3>Your Profile</h3>
                <p>Update your profile below.</p>
              </div>
              {this.state.editprofile === true && (
                <Form
                  {...this.props}
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleFormSubmit}
                  onInvalid={() => console.log("Form invalid!")}
                />
              )}
            </div>
          </section>
        )}

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
    updatecustomerprofile: state.updatecustomerprofile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
    getUpdateCustomerProfile: (formPayload) => {
      dispatch({ type: GET_UPDATECUSTOMERPROFILE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
