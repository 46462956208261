/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import profilemen from "../../common/images/profile_men.svg";
import profilemenhover from "../../common/images/profile_men_blue.svg";
import profilewishlist from "../../common/images/profile_wish.svg";
import cookie from "react-cookies";

import profilebooking from "../../common/images/profile_book.svg";

import profilecalcel from "../../common/images/profile_cancel.svg";
import rewardpro from "../../common/images/profile-reward.svg";
import logout from "../../common/images/log-out.svg";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
    };
    this.logoutPopup = this.logoutPopup.bind(this);
    this.confirmLogout = this.confirmLogout.bind(this);
    this.inactivityTimeout = null;
    this.resetLogoutTimeout();
  }
  logoutPopup() {
    $.magnificPopup.open({
      items: {
        src: "#remove_popup",
      },
      type: "inline",
    });
  }
  confirmLogout() {
    $.magnificPopup.close();
    cookie.remove("UserId", { path: "/logout" });
    this.props.history.push("/logout");
  }  
  autoLogout = () => {
    cookie.remove("UserId", { path: "/" }); 
    this.props.history.push("/"); 
  };
  handleUserActivity = () => {
    this.resetLogoutTimeout();
  };
  resetLogoutTimeout = () => {
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = setTimeout(this.autoLogout,  3 * 60 * 60 * 1000);   
  };
  componentDidMount() {
    window.addEventListener('mousemove', this.handleUserActivity);
    window.addEventListener('keydown', this.handleUserActivity);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleUserActivity);
    window.removeEventListener('keydown', this.handleUserActivity);
    clearTimeout(this.inactivityTimeout);
  }
  render() {
    return (
      <div className="mobile-view-scroll">
        <div className="admin-profile-lhs">
          <ul className="profile-menu">
            <li className={this.state.activePage === "profile" ? "active" : ""}>
              <Link className="pronav" to={"/profile"}>
                <img className="normal-img" src={profilemen} alt="profilemenblue" />
                <span>my profile</span>
              </Link>
            </li>
            {/* <li className={this.state.activePage === "rewards" ? "active" : ""}>
              <Link className="procancel" to={"/rewards"}>
                <img className="normal-img" src={rewardpro} alt="" />
                <span>Reward</span>
              </Link>
            </li> */}
            <li
              className={this.state.activePage === "wishlist" ? "active" : ""}
            >
              <Link className="pro-wish" to={"/wishlist"}>
                <img className="normal-img" src={profilewishlist} alt="profilewish" />
                <span>wishlishts</span>
              </Link>
            </li>
            <li
              className={this.state.activePage === "mybooking" ? "active" : ""}
            >
              <Link className="probook" to={"/mybooking"}>
                <img className="normal-img" src={profilebooking} alt="profile_book" />
                <span>my bookings</span>
              </Link>
            </li>
            <li
              className={
                this.state.activePage === "cancellation" ? "active" : ""
              }
            >
              <Link className="procancel" to={"/cancellation"}>
                <img className="normal-img" src={profilecalcel} alt="profilecancel" />
                <span>Cancellations</span>
              </Link>
            </li>
            <li>
              <a href={void 0} className="procancel" onClick={this.logoutPopup}>
                <img className="normal-img" src={logout} alt="logout" />
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>
        <div id="remove_popup" className="mfp-hide common-popup">
          <div className="custom_alert textcenter">
            <h2 className="text-uppercase">Warning</h2>
            <p>Are you sure want to logout </p>
            <div
              className="alt_btns"
              style={{ gap: "10px", display: "inline-flex" }}
            >
              <a
                className="button confirm-button ghost-button"
                onClick={this.confirmLogout}
              >
                Yes
              </a>
              <a
                href={void 0}
                className="button popup-modal-dismiss disbl_href_action"
                onClick={this.resetLogoutTimeout}
              >
                No
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
