/* eslint-disable */
import React, { Component } from "react";
import minusb from "../../../common/images/minus-b.svg";
import plusb from "../../../common/images/plus-b.svg";
import Axios from "axios";
import { UopUrl, fizoheader } from "../../Helpers/Config";
import SubHeader from "../SubHeader";
import "./Pricing.scss";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ModalPopup from "../ModalPopup/ModalPopup";
import cookie from "react-cookies";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from "@mui/icons-material";
import AddOnModal from "../AddOnModal/AddOnModal";
import PricingGroup from "../PricingGroup/PricingGroup";
import useUnsavedChangesWarning from "../../Help/UnsavedPopup";

const customTypes = [
  {
    type: "Add/edit group pricing for custom ticket types",
    desp: "It includes standard, superior and premium ticket types.",
    val: "customTicket",
  },
  {
    type: "Add-ons",
    desp: "Add details of your add-ons like guide, meals, things to do and others..",
    val: "addOns",
  },
];

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    let packageID = "";
    if (this.props?.location?.state?.packageID !== undefined) {
      packageID = this.props?.location?.state?.packageID;
    }
    if (this.props?.location?.state?.creator_package_id !== undefined) {
      packageID = this.props?.location?.state?.creator_package_id;
    }
    let allowedSection = [];
    if (this.props?.location?.state?.allowedSection !== undefined) {
      allowedSection = this.props.location.state.allowedSection;
    }
    if (!allowedSection?.includes("pricing")) {
      this.props.history.push("/myaccount/general-info");
    }
    let postedSection = [];
    if (this.props?.location?.state?.postedSection !== undefined) {
      postedSection = this.props?.location?.state?.postedSection;
    }
    let newPackage = true;
    if (this.props?.location?.state?.newPackage !== undefined) {
      newPackage = this.props?.location?.state?.newPackage;
    } else if (postedSection.includes("pricing")) {
      newPackage = false;
    }
    let includedSeason = [];
    if (this.props?.location?.state?.includedSeason !== undefined) {
      includedSeason = this.props?.location?.state?.includedSeason;
    }
    let productType = "";
    if (this.props?.location?.state?.productType !== undefined) {
      productType = this.props?.location?.state?.productType;
    }
    let customTicketTemplate = [
      {
        min: 0,
        max: 0,
        withBed: 0,
        withOutBed: 0,
        pricing: [
          {
            ticketTypeId: 1,
            type: "standard",
            peakPrice: "",
            nonPeakPrice: "",
          },
          {
            ticketTypeId: 2,
            type: "superior",
            peakPrice: "",
            nonPeakPrice: "",
          },
          {
            ticketTypeId: 3,
            type: "premium",
            peakPrice: "",
            nonPeakPrice: "",
          },
        ],
      },
    ];
    let addOnsTemplate = [
      {
        title: "",
        ticketType: ["standard"],
        peakPrice: 0,
        nonPeakPrice: 0,
        description: "",
      },
    ];
    let dayTourPricing = [
      {
        min: 0,
        max: 0,
        adultPeakPrice: 0,
        childPeakPrice: 0,
        adultNonPeakPrice: 0,
        childNonPeakPrice: 0,
      },
    ];

    this.state = {
      CUserId: CUserId,
      creator_package_id: packageID,
      currentSeason: "peak",
      allowedSection: allowedSection,
      isLoading: false,
      tickets: [
        {
          checked: false,
          category: "adult",
          season: "peak",
          min: 0,
          max: 0,
          price: 0,
        },
        {
          checked: false,
          category: "adult",
          season: "non-peak",
          min: 0,
          max: 0,
          price: 0,
        },
        {
          checked: false,
          category: "child",
          season: "peak",
          min: 0,
          max: 0,
          price: 0,
        },
        {
          checked: false,
          category: "child",
          season: "non-peak",
          min: 0,
          max: 0,
          price: 0,
        },
      ],
      customTicket: [],
      addOns: [],
      dayTourTicket: [],
      dayTourPricingTemplate: dayTourPricing,
      customTicketTemplate: customTicketTemplate,
      addOnsTemplate: addOnsTemplate,
      packageID: packageID,
      openFailedModal: false,
      postedSection: postedSection,
      newPackage: newPackage,
      includedSeason: includedSeason,
      selectedTicketType: null,
      showModal: null,
      currentIndex: 0,
      isFormEdited: false,
      productType: productType,
    };
  }

  componentDidMount() {
    let posted = this.state.postedSection.includes("pricing");
    if (!this.state.newPackage || posted) {
      this.getPricing();
    }
  }

  getPricing = async () => {
    const { tickets, packageID } = this.state;
    Axios.get(`${UopUrl}/pricing?id=${packageID}`, fizoheader)
      .then((res) => {
        // console.log(res);
        if (res.data) {
          let updated = this.updatedNewTicket(tickets, res.data.tickets);
          this.setState({
            tickets: updated,
            customTicket: res.data.customTicket,
            addOns: res.data.addOns,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  updatedNewTicket = (tickets1, tickets2) => {
    // Create a map to store indices of tickets1 based on category and season
    const indicesMap = {};
    tickets1.forEach((ticket, index) => {
      const key = ticket.category + "-" + ticket.season;
      indicesMap[key] = index;
    });

    // Iterate through tickets2 and insert missing objects at the corresponding indices
    tickets2.forEach((ticket) => {
      const key = ticket.category + "-" + ticket.season;

      if (indicesMap.hasOwnProperty(key)) {
        const index = indicesMap[key];
        if (index < tickets1.length) {
          tickets1[index] = ticket;
          tickets1[index].checked = true;
        }
      }
    });
    // console.log(tickets1);
    return tickets1;
  };

  addTravellers = (index, key, act) => {
    try {
      this.setState((prevState) => {
        // Create a copy of the tickets array from the state
        const updatedTickets = [...prevState.tickets];

        // Create a copy of the specific ticket object that needs to be updated
        const updatedTicket = { ...updatedTickets[index] };

        // Update the count based on the action
        let count = updatedTicket[key];
        if (act === "add") {
          count += 1;
        } else if (act === "sub" && count > 0) {
          count -= 1;
        }

        // Update the key with the new count
        updatedTicket[key] = count;

        // Place the updated ticket back into the array
        updatedTickets[index] = updatedTicket;

        // Return the new state
        return { tickets: updatedTickets };
      });
    } catch (err) {
      console.error("Error updating travelers:", err);
    }
  };

  handleClose = () => {
    this.setState({ showModal: null });
  };

  handlePost = async () => {
    try {
      this.setState({ isLoading: true, isFormEdited: false });
      const { tickets, newPackage, customTicket, addOns } = this.state;
      var updatedTickets = [];
      tickets.map((ticket, index) => {
        if (ticket.checked === true) {
          updatedTickets.push(ticket);
        }
      });
      const packageDetails = {
        creator_id: this.state.CUserId,
        creator_package_id: this.state.creator_package_id,
        tickets: updatedTickets,
        customTicket: customTicket,
        addOns: addOns,
      };
      let res;
      if (newPackage) {
        res = await Axios.post(`${UopUrl}/pricing`, packageDetails, fizoheader);
      } else {
        res = await Axios.put(
          `${UopUrl}/pricing/update`,
          packageDetails,
          fizoheader
        );
      }

      this.setState({ isLoading: false }, () => {
        // console.log(res);
        if (res.status === 200) {
          let postedSection = [...this.state.postedSection];
          let allowedSection = [...this.state.allowedSection];
          if (!allowedSection.includes("terms")) {
            allowedSection.push("terms");
          }
          if (!postedSection.includes("pricing")) {
            postedSection.push("pricing");
          }
          let packageDetails = this.props.location.state;
          packageDetails["postedSection"] = postedSection;
          packageDetails["allowedSection"] = allowedSection;
          packageDetails["newPackage"] = newPackage;

          this.props.history.push({
            pathname: "/myaccount/terms",
            state: packageDetails,
          });
        } else {
          this.setState({ openFailedModal: true });
        }
      });
    } catch (err) {
      this.setState({ isLoading: false }, () => console.error(err));
    }
  };

  // Function to update a specific value inside a specific ticket based on category
  updateTicketValue = (category, season, key, event) => {
    try {
      const { value, checked } = event.target;

      // use only one dot for decimal value
      const parts = value.split(".");
      if (parts.length > 2) {
        value = parts[0] + "." + parts.slice(1).join("");
      }

      this.setState((prevState) => {
        const updatedTickets = prevState.tickets.map((ticket) => {
          if (ticket.category === category && ticket.season === season) {
            return {
              ...ticket,
              [key]: value.replace(/[^\d.]/g, ""),
            };
          }
          return ticket;
        });

        return { tickets: updatedTickets, isFormEdited: true };
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleTicketType = (type) => {
    this.setState({ selectedTicketType: type });
  };

  goBack = () => {
    this.setState({ selectedTicketType: null });
  };

  addCustomGroup = (key) => {
    try {
      const { customTicketTemplate, addOnsTemplate } = this.state;
      const group = this.state[key];
      const groupTemplate =
        key === "customTicket" ? customTicketTemplate : addOnsTemplate;

      this.setState((prevState) => ({
        [key]: [...group, ...groupTemplate],
        showModal: key,
        currentIndex: group.length,
      }));
    } catch (err) {
      console.error("Error updating custom group:", err);
    }
  };

  handleCustomGroup = (index, priceIndex, key, event, action) => {
    try {
      const { value } = event.target;
      const { productType } = this.state;
      const ticketKey =
        productType === "dayTour" ? "dayTourTicket" : "customTicket";
      const tickets = this.state[ticketKey];

      // Clone the ticket array to update immutably
      const updatedTickets = [...tickets];
      const updatedObject = { ...updatedTickets[index] };

      // Update the specific key in the object or the pricing array
      if (action === "add" || action === "sub") {
        updatedObject[key] =
          action === "add"
            ? parseFloat(updatedObject[key] || 0) + 1
            : Math.max(0, parseFloat(updatedObject[key] || 0) - 1);
      } else {
        if (key === "peakPrice" || key === "nonPeakPrice") {
          const updatedPricing = [...updatedObject.pricing];
          const updatedPriceObject = {
            ...updatedPricing[priceIndex],
            [key]: value,
          };
          updatedPricing[priceIndex] = updatedPriceObject;
          updatedObject.pricing = updatedPricing;
        } else {
          updatedObject[key] = parseFloat(value);
        }
      }

      // Place the updated object back into the array
      updatedTickets[index] = updatedObject;

      // Update the state with the correct ticket key
      this.setState({ [ticketKey]: updatedTickets, isFormEdited: true });
    } catch (err) {
      console.error("Error editing group:", err);
    }
  };

  handleDayTourPricing = (index, field, event) => {
    const { value } = event.target;
    const { dayTourTicket } = this.state;
    let updatedPricing = [...dayTourTicket];
    updatedPricing[index][field] = value;
    this.setState({ dayTourTicket: updatedPricing }, () =>
      console.log(dayTourTicket)
    );
  };

  handleAddOns = (index, key, event) => {
    try {
      const { value, checked } = event.target;
      let updatedValue;

      this.setState((prevState) => {
        const updatedAddOns = [...prevState.addOns];
        const currentAddOn = { ...updatedAddOns[index] };

        if (["standard", "superior", "premium"].includes(key)) {
          // Initialize ticketType from the current state
          let ticketType = [...currentAddOn.ticketType];

          if (checked) {
            if (!ticketType.includes(key)) {
              ticketType.push(key);
            }
          } else {
            ticketType = ticketType.filter((ticket) => ticket !== key);
          }

          currentAddOn.ticketType = ticketType;
        } else {
          const dots = value.split(".").length - 1;
          updatedValue =
            key === "title" || key === "description"
              ? value.replace(/[^\w\s-]/gi, "")
              : value.replace(/[^0-9.]/g, "");

          // check the value in only have single dot
          if (key != "title" && key != "description" && dots > 1) {
            updatedValue = value.slice(0, value.lastIndexOf("."));
          }

          currentAddOn[key] = updatedValue;
        }

        updatedAddOns[index] = currentAddOn;

        return { addOns: updatedAddOns, isFormEdited: true };
      });
    } catch (err) {
      console.error("Error updating add-ons:", err);
    }
  };

  handleEditGroup = (index, key) => {
    this.setState({
      currentIndex: index,
      showModal: key,
    });
  };

  deleteCustomGroup = (index, key) => {
    var updatedTickets = [...this.state[key]];
    updatedTickets.splice(index, 1);
    this.setState({ [key]: updatedTickets, isFormEdited: true });
  };

  handleCheckBox = (ev, index) => {
    const { checked } = ev.target;
    const { includedSeason } = this.state;
    const temp = [...this.state.tickets];
    if (includedSeason.length > 1) {
      temp[index].checked = checked;
      temp[index + 1].checked = checked;
    } else {
      let particularIndex = includedSeason.includes("peak") ? index : index + 1;
      temp[particularIndex].checked = checked;
    }
    this.setState({ tickets: temp, isFormEdited: true });
  };

  getFirstNonZeroType = (ticket) => {
    const isNonZeroPrice = (price) => parseFloat(price) > 0;

    // Filter pricing objects where either peakPrice or nonPeakPrice is non-zero
    let nonZeroPriceTypes = ticket.pricing
      .filter(
        (item) =>
          isNonZeroPrice(item.peakPrice) || isNonZeroPrice(item.nonPeakPrice)
      )
      .map((item) =>
        item.ticketTypeId === 1
          ? "standard"
          : item.ticketTypeId === 2
          ? "superior"
          : "premium"
      );

    return nonZeroPriceTypes.join(" | ");
  };

  findMinNonZeroValue = (ticket) => {
    let prices = ticket.pricing.flatMap((item) => [
      parseFloat(item.peakPrice) || Infinity,
      parseFloat(item.nonPeakPrice) || Infinity,
    ]);

    // Filter out Infinity values (used to replace empty strings or invalid numbers)
    prices = prices.filter((price) => price !== Infinity);

    // Find the minimum value
    const minPrice = Math.min(...prices);
    return minPrice;
  };

  render() {
    const { includedSeason, tickets, allowedSection } = this.state;

    return (
      <>
        <section className="main-blue-bg">
          <div className="container-full">
            {this.state?.isLoading && (
              <div id="dvLoading">
                <div className="loader triangle">
                  <svg viewBox="0 0 100 100">
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      stroke="black"
                      strokeWidth="3"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </div>
            )}
            <SubHeader
              triggerAction={this.handlePost}
              allowedSection={allowedSection}
              stateValues={this.props?.location?.state}
              finalPage={false}
              // handleApproval={this.handleApproval}
            />

            {this.state.selectedTicketType === null && (
              <div className="pricing-container">
                <h6 className="pricing-container__heading">
                  Edit your ticket-type & prices
                </h6>
                <div className="pricing-container__sub">
                  Please enter your package price based on the ticket-type.
                </div>
                <br />
                {tickets?.slice(0, 6).map((ticket, ticketIndex) => (
                  <div
                    className={`pricing-container__row ${
                      ticketIndex % 2 === 0
                        ? "pricing-container__row__even"
                        : "pricing-container__row__odd"
                    }`}
                  >
                    <div className="pricing-container__row__col1">
                      {ticketIndex % 2 === 0 && (
                        <input
                          checked={
                            includedSeason.includes("peak")
                              ? ticket.checked
                              : tickets[ticketIndex + 1].checked
                          }
                          type="checkbox"
                          onChange={(ev) =>
                            this.handleCheckBox(ev, ticketIndex)
                          }
                        />
                      )}
                    </div>
                    <div
                      className={`pricing-container__row__col2 ${
                        ticket?.checked
                          ? ""
                          : "pricing-container__row__disabled"
                      }`}
                    >
                      <div className="pricing-container__row__col2__heading">
                        {(ticketIndex === 0 || ticketIndex == 2) && (
                          <div>{ticket?.category}</div>
                        )}
                      </div>
                      {ticketIndex === 0 && (
                        <div className="pricing-container__row__col2__sub">
                          Aged between 18-65 years old
                        </div>
                      )}
                      {ticketIndex === 2 && (
                        <div className="pricing-container__row__col2__sub">
                          Aged below 18 years old
                        </div>
                      )}

                      <br />
                      <div>
                        {this.state.includedSeason.includes(ticket?.season) && (
                          <div className="pricing-container__row__col2__row">
                            <div className="pricing-container__row__col2__row__flex">
                              <div className="pricing-container__row__col2__row__flex__first">
                                <label className="pricing-container__row__col2__heading">
                                  {ticket?.season} season
                                </label>
                                <div className="pricing-container__row__col2__sub">
                                  Per package - Nett price
                                </div>
                              </div>
                              <input
                                className="price"
                                type="text"
                                value={ticket.price}
                                onChange={(e) =>
                                  this.updateTicketValue(
                                    ticket.category,
                                    ticket.season,
                                    "price",
                                    e
                                  )
                                }
                              />
                            </div>
                            <div className="pricing-container__row__col2__row__flex1">
                              <label className="pricing-container__row__col2__heading">
                                Min
                              </label>
                              <div className="addcart_row">
                                <div className="qty-bx">
                                  <span
                                    className="qty-minus"
                                    onClick={() =>
                                      this.addTravellers(
                                        ticketIndex,
                                        "min",
                                        "sub"
                                      )
                                    }
                                  >
                                    <img
                                      className="minus-ic"
                                      src={minusb}
                                      alt="Minus"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    className="qty-input"
                                    readOnly={true}
                                    value={ticket.min}
                                  />
                                  <span
                                    className="qty-plus"
                                    onClick={() =>
                                      this.addTravellers(
                                        ticketIndex,
                                        "min",
                                        "add"
                                      )
                                    }
                                  >
                                    <img
                                      className="plus-ic"
                                      src={plusb}
                                      alt="Plus"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="pricing-container__row__col2__row__flex1">
                              <label className="pricing-container__row__col2__heading">
                                Max
                              </label>
                              <div className="addcart_row">
                                <div className="qty-bx">
                                  <span
                                    className="qty-minus"
                                    onClick={() =>
                                      this.addTravellers(
                                        ticketIndex,
                                        "max",
                                        "sub"
                                      )
                                    }
                                  >
                                    <img
                                      className="minus-ic"
                                      src={minusb}
                                      alt="Minus"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    className="qty-input"
                                    readOnly={true}
                                    value={ticket.max}
                                  />
                                  <span
                                    className="qty-plus"
                                    onClick={() =>
                                      this.addTravellers(
                                        ticketIndex,
                                        "max",
                                        "add"
                                      )
                                    }
                                  >
                                    <img
                                      className="plus-ic"
                                      src={plusb}
                                      alt="Plus"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="pricing-container__headingRow">
                  <h6 className="pricing-container__heading">
                    Custom ticket-type and Add-ons
                  </h6>
                </div>

                <br />
                {/* <div className="pricing-container__row">
                <div className="pricing-container__row__col2"> */}
                <div className="pricing-container__listMain">
                  {customTypes?.map((item, index) => (
                    <div className="pricing-container__listMain__box">
                      <div className="pricing-container__listMain__box__col1">
                        <div className="pricing-container__listMain__box__col1__type">
                          {item?.type}
                        </div>
                        <div className="pricing-container__listMain__box__col1__desp">
                          {item?.desp}
                        </div>
                      </div>
                      <div className="pricing-container__listMain__box__col2">
                        <Button
                          variant="contained"
                          onClick={() => this.handleTicketType(item?.val)}
                        >
                          Add information
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {this.state.selectedTicketType === "customTicket" ? (
              <div className="pricing-container__groups">
                <Button
                  variant="text"
                  startIcon={<ArrowBackIosIcon />}
                  className="backBtn"
                  onClick={() => this.goBack()}
                >
                  Back
                </Button>
                <div className="pricing-container__groups__title">
                  Provide group pricings for your packages
                </div>
                <div className="pricing-container__groups__sub">
                  Please edit necessary information to proceed with your package
                  submission.
                </div>
                <div
                  className={`pricing-container__groups__row ${
                    this.state.customTicket?.length === 0 ? "emptyBox" : ""
                  }`}
                >
                  {this.state.customTicket?.length === 0 && (
                    <div
                      className="pricing-container__groups__row__empty"
                      onClick={() => this.addCustomGroup("customTicket")}
                    >
                      <div className="pricing-container__groups__row__empty__btnMain">
                        <Button
                          variant="text"
                          className="add-btn"
                          startIcon={<AddIcon />}
                          onClick={() => this.addCustomGroup("customTicket")}
                        >
                          Add group
                        </Button>
                      </div>
                    </div>
                  )}
                  {this.state.customTicket.map((item, index) => (
                    <div
                      className="pricing-container__groups__row__box"
                      key={index}
                    >
                      <div className="pricing-container__groups__row__box__row">
                        <div className="pricing-container__groups__row__box__row__text">
                          Group {item.min}-{item.max} pax{" "}
                        </div>
                        <div className="pricing-container__groups__row__box__row__actions">
                          <Button
                            variant="text"
                            startIcon={<EditIcon />}
                            className="editBtn"
                            onClick={() =>
                              this.handleEditGroup(index, "customTicket")
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="text"
                            startIcon={<DeleteIcon />}
                            className="deleteBtn"
                            onClick={() =>
                              this.deleteCustomGroup(index, "customTicket")
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                      <div className="pricing-container__groups__row__box__text1">
                        {this.getFirstNonZeroType(item)}
                      </div>
                      <div className="pricing-container__groups__row__box__text1">
                        Starts from SGD ${this.findMinNonZeroValue(item)}
                      </div>
                    </div>
                  ))}
                </div>
                {this.state.customTicket?.length ? (
                  <div className="pricing-container__groups__btnMain">
                    <Button
                      variant="contained"
                      className="add-btn"
                      onClick={() => this.addCustomGroup("customTicket")}
                    >
                      Add more group
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : this.state.selectedTicketType === "addOns" ? (
              <div className="pricing-container__groups">
                <Button
                  variant="text"
                  startIcon={<ArrowBackIosIcon />}
                  className="backBtn"
                  onClick={() => this.goBack()}
                >
                  Back
                </Button>
                <div className="pricing-container__groups__title">
                  Provide add-on pricings for your packages
                </div>
                <div className="pricing-container__groups__sub">
                  Please edit necessary information to proceed with your package
                  submission.
                </div>
                <div
                  className={`pricing-container__groups__row ${
                    this.state.addOns?.length === 0 ? "emptyBox" : ""
                  }`}
                >
                  {this.state.addOns?.length === 0 && (
                    <div
                      className="pricing-container__groups__row__empty"
                      onClick={() => this.addCustomGroup("addOns")}
                    >
                      <div className="pricing-container__groups__row__empty__btnMain">
                        <Button
                          variant="text"
                          className="add-btn"
                          startIcon={<AddIcon />}
                          onClick={() => this.addCustomGroup("addOns")}
                        >
                          Add add-ons
                        </Button>
                      </div>
                    </div>
                  )}
                  {this.state.addOns.map((item, index) => (
                    <div
                      className="pricing-container__groups__row__box"
                      key={index}
                    >
                      <div className="pricing-container__groups__row__box__row">
                        <div className="pricing-container__groups__row__box__row__text">
                          {item.title}
                        </div>
                        <div className="pricing-container__groups__row__box__row__actions">
                          <Button
                            variant="text"
                            startIcon={<EditIcon />}
                            className="editBtn"
                            onClick={() =>
                              this.handleEditGroup(index, "addOns")
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="text"
                            startIcon={<DeleteIcon />}
                            className="deleteBtn"
                            onClick={() =>
                              this.deleteCustomGroup(index, "addOns")
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                      <div className="pricing-container__groups__row__box__text1">
                        {item.ticketType.join(" | ")}
                      </div>
                      <div className="pricing-container__groups__row__box__text1">
                        Starts From $SGD{" "}
                        {item.peakPrice > item.nonPeakPrice
                          ? item.nonPeakPrice
                          : item.peakPrice}
                      </div>
                    </div>
                  ))}
                </div>
                {this.state.addOns?.length !== 0 ? (
                  <div className="pricing-container__groups__btnMain">
                    <Button
                      variant="contained"
                      className="add-btn"
                      onClick={() => this.addCustomGroup("addOns")}
                    >
                      Add more add-ons
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : null}
            {this.state.showModal === "customTicket" ? (
              <PricingGroup
                open={this.state.showModal === "customTicket"}
                handleClose={this.handleClose}
                state={this.state}
                index={this.state.currentIndex}
                handleChange={this.handleCustomGroup}
              />
            ) : this.state.showModal === "addOns" ? (
              <AddOnModal
                open={this.state.showModal === "addOns"}
                handleClose={this.handleClose}
                state={this.state}
                index={this.state.currentIndex}
                handleChange={this.handleAddOns}
              />
            ) : null}
            <ModalPopup
              type="success"
              title="Failed"
              message="Failed to save. Please try again"
              open={this.state.openFailedModal}
              handleClose={() => this.setState({ openFailedModal: false })}
              // handleChange={handleChange}
              // handleSubmit={handleAuth}
            />

            <div></div>
          </div>
          <UnsavedChangesWarning
            message="You have unsaved changes. Are you sure you want to leave?"
            isFormEdited={this.state.isFormEdited}
          />
        </section>
      </>
    );
  }
}

const UnsavedChangesWarning = ({ message, isFormEdited }) => {
  useUnsavedChangesWarning(message, isFormEdited);
  return null;
};
