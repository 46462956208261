/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_FUNTHINGS, SET_FUNTHINGS } from "../actions";
import { apiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetFunThings = function* () {
  yield takeEvery(GET_FUNTHINGS, workerGetFunThings);
};

function* workerGetFunThings({ params }) {
  try {
    const uri = apiUrl + "products/getFunThings?" + params;
    const result = yield call(Axios.get, uri, fizoheader);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FUNTHINGS, value: resultArr });
  } catch {
    console.log("Get Fun Things Failed");
  }
}
