/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./SideBarCreator.scss";
import profilemen from "../../../common/images/profile_men.svg";
import profilemenhover from "../../../common/images/profile_men_blue.svg";
import profilewishlist from "../../../common/images/profile_wish.svg";

import profilebooking from "../../../common/images/profile_book.svg";

import profilecalcel from "../../../common/images/profile_cancel.svg";
import rewardpro from "../../../common/images/profile-reward.svg";
import logout from "../../../common/images/log-out.svg";

import earnwallet from "../../../common/images/earning-wallet.svg";
import myedit from "../../../common/images/myacc-edit.svg";

class SideBarCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
      selectedTab:
        this.props.history.location.pathname === "/myaccount/creatordetails-edit"
          ? 2
          : this.props.history.location.pathname === "/myaccount/profilecreator"
          ? 1
          : 3,
    };
  }

  handleSelectedTab = (num, path) => {
    this.props.history.push(path);
    this.setState({ selectedTab: num });
  };

  render() {
    return (
      <div className="sidebar-main">
        <div className="sidebar-main__tabs">
          <div
            className={`sidebar-main__tabs__tab ${
              this.state.selectedTab === 1 ? "active" : ""
            }`}
            onClick={() => this.handleSelectedTab(1, "/myaccount/profilecreator")}
          >
            Account
          </div>
          <div
            className={`sidebar-main__tabs__tab ${
              this.state.selectedTab === 2 ? "active" : ""
            }`}
            onClick={() => this.handleSelectedTab(2, "/myaccount/creatordetails-edit")}
          >
            Edit Profile
          </div>
          <div
            className={`sidebar-main__tabs__tab ${
              this.state.selectedTab === 3 ? "active" : ""
            }`}
            onClick={() => this.handleSelectedTab(3, "/myaccount/payoutdetails")}
          >
            Payout Details
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

SideBarCreator.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBarCreator)
);
