import React, { Component } from "react";
class Refpage extends Component {
  constructor(props) {
    super(props);

    let slugtext =
      typeof this.props.match.params.slugtext !== "undefined"
        ? this.props.match.params.slugtext
        : "";
    if (slugtext === "home") {
      this.props.history.push("/");
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return <div></div>;
  }
}

export default Refpage;
