/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_GLOBAL_SETTINGS, SET_GLOBAL_SETTINGS } from "../actions";
import { apiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetSettings = function* () {
  yield takeEvery(GET_GLOBAL_SETTINGS, workerGetSettings);
};

function* workerGetSettings() {
  try {
    const uri = apiUrl + "settings/get_common_settings";
    const result = yield call(Axios.get, uri, fizoheader);

    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_GLOBAL_SETTINGS, value: resultArr });
  } catch (e) {
    console.log(e, "Get Settings Failed");
  }
}
