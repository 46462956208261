import { combineReducers } from "redux";
import settings from "./settings";
import country from "./country";
import productlist from "./productlist";
import productdetails from "./productdetails";
import segmentlist from "./segmentlist";
import taglist from "./taglist";
import creatorlogin from "./creatorlogin";
import fblogin from "./fblogin";
import fblogincreator from "./fblogincreator";
import googlelogin from "./googlelogin";
import googlelogincreator from "./googlelogincreator";
import customerdetail from "./customerdetail";
import creatordetail from "./creatordetail";
import forgetpassword from "./forgetpassword";
import creatorregistration from "./creatorregistration";
import changepassword from "./changepassword";
import updatecustomerprofile from "./updatecustomerprofile";
import customerlogin from "./customerlogin";
import customerregistration from "./customerregistration";
import favourite from "./favourite";
import favouritelist from "./favouritelist";
import bookinghistory from "./bookinghistory";
import funthings from "./funthings";
import searchflight from "./searchflight";
import mystiflyrevalidate from "./mystiflyrevalidate";
import mystiflycheapestrevalidate from "./mystiflycheapestrevalidate";
import wishlist from "./wishlist";
import uploadfiles from "./uploadfiles";

const rootReducer = combineReducers({
  settings: settings,
  country: country,
  productlist: productlist,
  productdetails: productdetails,
  segmentlist: segmentlist,
  taglist: taglist,
  creatorlogin: creatorlogin,
  fblogin: fblogin,
  googlelogin: googlelogin,
  fblogincreator: fblogincreator,
  googlelogincreator: googlelogincreator,
  customerdetail: customerdetail,
  creatordetail: creatordetail,
  forgetpassword: forgetpassword,
  creatorregistration: creatorregistration,
  changepassword: changepassword,
  updatecustomerprofile: updatecustomerprofile,
  customerlogin: customerlogin,
  customerregistration: customerregistration,
  favourite: favourite,
  favouritelist: favouritelist,
  bookinghistory: bookinghistory,
  funthings: funthings,
  searchflight: searchflight,
  mystiflyrevalidate: mystiflyrevalidate,
  mystiflycheapestrevalidate: mystiflycheapestrevalidate,
  wishlist: wishlist,
  uploadfiles: uploadfiles,
});

export default rootReducer;
