/* eslint-disable */
import React, { useEffect } from "react";
import "../Customerpackage/FilterSheet.scss";
import { showPriceValue } from "../Helpers/SettingHelper";

const FilterSheet = (props) => {
  const { state, currentThis, closeFilterSheet } = props;

  useEffect(() => {
    $("#rang-mobile").ionRangeSlider({
      min: 0,
      max: 10000,
      from: 1000,
      to: 9000,
      type: "double",
      grid_num: 10,
      onFinish: function (data) {
        currentThis.setState(
          { startPrice: data.from, endPrice: data.to },
          function () {
            currentThis.applyFilter();
          }
        );
      },
    });
  }, [state]);

  return (
    <div className="filterSheetMain">
      <div className="cp-header textcenter">
        <h3>Filters</h3>
      </div>
      <div className="filter-body slider">
        <div className="filter-row cat-option">
          <h5>categories</h5>
          <ul>{state.displaytag}</ul>
        </div>
        <div className="filter-row pricerange-option">
          <h5>Price range</h5>
          <p>
            {showPriceValue(Math.ceil(state.startPrice))} to{" "}
            {showPriceValue(Math.ceil(state.endPrice))}+
          </p>
          <div>
            <input
              type="text"
              id="rang-mobile"
              name="rang"
              data-min="0"
              data-max="1000"
              data-from="0"
              data-to={state.endPrice}
              data-type="double"
              data-prefix="SGD$"
              data-grid="false"
              data-grid-num="10"
            />
          </div>
        </div>
      </div>

      <div className="filter-action">
        <a
          href="#"
          className="button"
          onClick={() => {
            currentThis.applyTagFilter();
            closeFilterSheet();
          }}
        >
          Apply
        </a>
        <a
          href="#"
          className="button ghost-button"
          onClick={() => {
            currentThis.clearAllFilters();
            closeFilterSheet();
          }}
        >
          Clear All
        </a>
      </div>
    </div>
  );
};

export default React.memo(FilterSheet);
