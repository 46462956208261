import React, { Component } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

export default class Cancellation extends Component {
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <div>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <section className="innersection">
          <div className="container">
            <p>
              <h1>Cancellation and Refund Policy</h1>
              <b>General:</b>
              <br />
              <br />
              Effective 01 May 2023, this cancellation policy applies to all
              bookings made with Fizota. Customers can only cancel the booking
              15 days prior to the departure date. The package cancellation
              window (valid date) will be mentioned on the respective package
              final voucher. Refunds for eligible customers will be processed
              within 90 working days from the cancellation date or when the
              supplier(s) completes the refund process, whichever occurs first.
              If you have any queries or need clarifications, please contact our
              customer support team at{" "}
              <a href="mailto: support@fizota.com"> support@fizota.com</a>
              <br />
              <br />
              Based on international exchange rates, refunds received from
              suppliers, and payment gateway charges, the refund amount may
              vary. We will promptly inform the user of any changes in the
              refund amount via registered email or contact number.
              <br />
              <br />
              <b>Flights</b>
              <br />
              <br />
              <ul>
                <li>
                  Flights with a "Non-Refundable" tag in the final travel
                  vouchers issued by Fizota do not meet the criteria for refund
                  eligibility.
                </li>{" "}
                <br />
                <li>
                  Refunds for flights with a "Refundable" tag in the final
                  travel vouchers issued by Fizota will be processed in
                  accordance with the guidelines mentioned in the "Cancellation
                  Policy" section.
                </li>{" "}
                <br />
                <li>
                  The final refund amount for the eligible flight is subject to
                  international exchange rates, supplier admin fees, and payment
                  gateway charges.
                </li>
                <br />
                <li>
                  In the event of grounded, cancelled, or delayed flights,
                  customers should directly contact the respective airlines for
                  assistance with cancellation requests and potential refunds,
                  as these matters are handled by the airline carriers.
                </li>{" "}
                <br />
                <li>
                  To ensure a smooth travel experience, customers are kindly
                  advised to verify the validity of their passports, ensuring a
                  minimum validity of 6 months and good condition. Fizota cannot
                  facilitate refunds for customers who are denied boarding due
                  to invalid passports (expired validity or damaged passports).
                </li>{" "}
                <br />
                <li>
                  We highly recommend that customers arrive at the airport at
                  least 2 hours prior to boarding time. We regret that we are
                  unable to provide refunds for missed flights resulting from
                  delayed arrival at the airport. Except for the customers who
                  booked airport transfers via the Fizota platform.
                </li>{" "}
                <br />
                <li>
                  Please note that flight cancellations due to delayed transfers
                  caused by unforeseen circumstances specific to a region are
                  beyond the control and responsibility of Fizota.
                </li>{" "}
                <br />
                <li>
                  Baggage information including the cabin and check-in will be
                  specified in the final voucher issued by Fizota. Customers are
                  kindly requested to adhere to these limitations to avoid any
                  additional costs during the check-in process.
                </li>{" "}
                <br />
                <li>
                  Some airlines may have mandatory web check-in policies. We
                  kindly request customers to comply with airline policies to
                  avoid any additional costs at the airport. Fizota regrets that
                  we are unable to provide refunds in such circumstances.
                </li>{" "}
                <br />
                <li>
                  Fizota does not bear responsibility for the other add-on
                  services which are booked directly from the airline platform
                  by the customer.
                </li>{" "}
                <br />
              </ul>
              <br />
              <br />
              <b>Hotels</b>
              <br />
              <br />
              <ul>
                <li>
                  Hotels with a "Non-Refundable" tag in the final travel
                  vouchers issued by Fizota do not meet the criteria for refund
                  eligibility.
                </li>
                <br />
                <li>
                  Refunds for hotels with a "Refundable" tag in the final travel
                  vouchers issued by Fizota will be processed in accordance with
                  the guidelines mentioned in the "Cancellation Policy" section.
                </li>
                <br />
                <li>
                  The final refund amount for the eligible hotel is subject to
                  international exchange rates, supplier admin fees, and payment
                  gateway charges.
                </li>
                <br />
                <li>
                  Fizota is an online marketplace platform that helps users to
                  book travel itineraries. We are always trying to provide
                  excellent hotels with great amenities. However, we would like
                  to underline the fact that factors such as hotel staff
                  behaviour, cleanliness, and quality of accommodation are
                  beyond our control. Any additional costs for on-trip room
                  upgrades and extra amenities will be borne by the customer.
                  Please note that cancelling and rebooking hotels during the
                  trip will incur a 100% cancellation fee.
                </li>
                <br />
                <li>
                  Requests for early check-in or late check-out are subject to
                  the hotel's approval Fizota is unable to process cancellations
                  due to the unavailability of these requests.
                </li>
                <br />
                <li>
                  We highly recommend customers go through the no-show policy
                  and the penalty for the final voucher issued by Fizota. And
                  Fizota doesn’t bear the no-show penalty.
                </li>
              </ul>
              <br />
              <br />
              <b>Activities</b> <br />
              <br />
              <ul>
                <li>
                  Activities with a "Non-Refundable" tag in the final travel
                  vouchers do not offer refund options.
                </li>
                <br />
                <li>
                  Refunds for activities with a "Refundable" tag in the final
                  travel vouchers will be processed as per the guidelines
                  mentioned in the "Cancellation Policy" Or in the voucher
                  issued by Fizota.
                </li>
                <br />
                <li>
                  The final refund amount for the eligible hotel is subject to
                  international exchange rates, supplier admin fees, and payment
                  gateway charges.
                </li>
              </ul>
              <br />
              <br />
              <b>Transfers</b>
              <br />
              <br />
              <ul>
                <li>
                  Refunds for all transfers will be processed as per the
                  guidelines mentioned in the "Cancellation Policy" Or in the
                  voucher issued by Fizota.
                </li>
                <br />
                <li>
                  The final refund amount for the eligible hotel is subject to
                  international exchange rates, supplier admin fees, and payment
                  gateway charges.
                </li>
                <br />
              </ul>
            </p>
          </div>
        </section>
        <Footer {...this.props} />{" "}
      </div>
    );
  }
}
