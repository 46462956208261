/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CUSTOMER_DETAIL, SET_CUSTOMER_DETAIL } from "../actions";
import { apiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCustomerDetail = function* () {
  yield takeEvery(GET_CUSTOMER_DETAIL, workerGetCustomerDetail);
};

function* workerGetCustomerDetail({ params }) {
  try {
    const uri = apiUrl + "customer/customerdetail?" + params;
    const result = yield call(Axios.get, uri, fizoheader);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CUSTOMER_DETAIL, value: resultArr });
  } catch {
    console.log("Get Customer Failed");
  }
}
