/* eslint-disable */
import React, { Component } from "react";
import "./WorkingHours.scss";
import SubHeader from "../SubHeader";
import axios from "axios";
import cookie from "react-cookies";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import CalendarModal from "./CalendarModal/CalendarModal";
import TodayIcon from "@mui/icons-material/Today";
import dayjs from "dayjs";

class WorkingHours extends Component {
  constructor(props) {
    super(props);
    console.log("listPage", this.props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }

    let allowedSection = [];
    let packageID = "";
    let newPackage = true;
    let duration;
    let postedSection = [];
    if (this.props?.location?.state !== undefined) {
      allowedSection = this.props?.location?.state?.allowedSection;
      packageID = this.props?.location?.state?.packageID;
    }
    // if (!allowedSection?.includes("hours")) {
    //   this.props.history.push("/myaccount/general-info");
    // }
    if (this.props?.location?.state?.creator_package_id !== undefined) {
      packageID = this.props?.location?.state?.creator_package_id;
    }
    if (this.props?.location?.state?.newPackage !== undefined) {
      newPackage = this.props?.location?.state?.newPackage;
    }
    if (this.props?.location?.state?.duration !== undefined) {
      duration = this.props?.location?.state?.duration;
    }
    if (this.props?.location?.state?.postedSection !== undefined) {
      postedSection = this.props?.location?.state?.postedSection;
    }
    let days = [
      {
        label: "Sunday",
        start: "",
        end: "",
      },
      {
        label: "Monday",
        start: "",
        end: "",
      },
      {
        label: "Tuesday",
        start: "",
        end: "",
      },
      {
        label: "Wednesday",
        start: "",
        end: "",
      },
      {
        label: "Thursday",
        start: "",
        end: "",
      },
      {
        label: "Friday",
        start: "",
        end: "",
      },
      {
        label: "Saturday",
        start: "",
        end: "",
      },
    ];

    this.state = {
      CUserId: CUserId,
      customerdetail: "",
      isLoading: true,
      allowedSection: allowedSection,
      packageID: packageID,
      openCalendarModal: false,
      days: days,
      nonWorkingDays: [""],
    };
    /*  if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
          props.history.push("/");
        } else {
          var params = "customer_id=" + cookie.load("UserId");
          this.props.getCustomerDetail(params);
        } */
  }

  handleWorkingHours = (index, key, value) => {
    let timeValue = dayjs(value.$d).format("HH:mm");
    let days = [...this.state.days];
    days[index][key] = timeValue;
    this.setState(
      {
        days,
      },
      () => console.log(days)
    );
  };

  handleNonWorkingDays = (index, event) => {
    const { value } = event.target;
    let nonWorkingDays = [...this.state.nonWorkingDays];
  };

  render() {
    const { allowedSection, includedSeason, nonWorkingDays } = this.state;
    let stateValues = this.props?.location?.state || {};

    return (
      <section className="main-blue-bg">
        <div className="container-full">
          <SubHeader
            triggerAction={this.uploadAvailability}
            allowedSection={allowedSection}
            stateValues={stateValues}
          />
          <div className="hours-main">
            <div className="hours-main__title">Working hours</div>
            <div className="hours-main__sub">
              Enter your business working hours
            </div>
            <div>
              {this.state.days?.map((day, index) => (
                <div className="form-group" key={index}>
                  <div className="first-col">
                    <input
                      type="checkbox"
                      // checked={this.state.previousStay}
                      onChange={(e) => this.handleWorkingHours(index, "", e)}
                    />
                    <span>{day?.label}</span>
                  </div>
                  <div>
                    <span>Start</span>{" "}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        onChange={(e) =>
                          this.handleWorkingHours(index, "start", e)
                        }
                        value={day?.start}
                        //   ampm={true}
                        placeholder="Ex: 12:00am"
                      />
                    </LocalizationProvider>
                  </div>
                  &nbsp; &nbsp; &nbsp;
                  <div>
                    <span>End</span>{" "}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        onChange={(e) =>
                          this.handleWorkingHours(index, "end", e)
                        }
                        value={day?.end}
                        //   ampm={true}
                        placeholder="Ex: 12:00am"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              ))}
            </div>
            <div className="hours-main__title">Non-Working days</div>
            <div className="hours-main__sub">
              Enter your business working hours
            </div>
            {nonWorkingDays?.map((item, roomIndex) => (
              <>
                <div className="non-working">
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">
                          <TodayIcon />
                        </InputAdornment>
                      }
                      label="Amount"
                      //   disabled
                      onClick={() => this.setState({ openCalendarModal: true })}
                    />
                  </FormControl>
                  {/* <input
                      placeholder="Ex: Double bedroom(Ac)"
                      type="text"
                      value={item}
                      //   onChange={(e) =>
                      //     this.handleRoomTypes(index, roomIndex, e)
                      //   }
                    /> */}
                  <div>
                    <a
                      className="button soc-btn"
                      onClick={() => this.setState({ openCalendarModal: true })}
                      // onClick={() =>
                      //   this.handleRemove(roomIndex, "room_type", index)
                      // }
                    >
                      -
                    </a>
                    <a
                      className="button soc-btn"
                      // onClick={() => this.handleStayRoom(index)}
                    >
                      +
                    </a>
                  </div>
                </div>
                <br />
              </>
            ))}
          </div>
        </div>
        {this.state.openCalendarModal && (
          <CalendarModal
            open={this.state.openCalendarModal}
            handleClose={() => this.setState({ openCalendarModal: false })}
          />
        )}
      </section>
    );
  }
}

export default WorkingHours;
