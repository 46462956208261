/* eslint-disable */

import React, { Component } from "react";
import "./Terms.scss";
import SubHeader from "../SubHeader";
import CustomEditor from "../CustomEditor/CustomEditor";
import Axios from "axios";
import { UopUrl, apiUrl, fizoheader } from "../../Helpers/Config";
import cookie from "react-cookies";
import ModalPopup from "../ModalPopup/ModalPopup";
var base64 = require("base-64");
import useUnsavedChangesWarning from "../../Help/UnsavedPopup";

class Terms extends Component {
  constructor(props) {
    super(props);
    console.log("terms", this.props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    let packageID = "";
    if (this.props?.location?.state?.packageID !== undefined) {
      packageID = this.props?.location?.state?.packageID;
    }
    if (this.props?.location?.state?.creator_package_id !== undefined) {
      packageID = this.props?.location?.state?.creator_package_id;
    }
    let allowedSection = [];
    if (this.props?.location?.state?.allowedSection !== undefined) {
      allowedSection = this.props.location.state.allowedSection;
    }
    if (!allowedSection?.includes("terms")) {
      this.props.history.push("/myaccount/general-info");
    }
    let postedSection = [];
    if (this.props?.location?.state?.postedSection !== undefined) {
      postedSection = this.props?.location?.state?.postedSection;
    }
    let newPackage = true;
    if (this.props?.location?.state?.newPackage !== undefined) {
      newPackage = this.props?.location?.state?.newPackage;
    }

    this.state = {
      CUserId: CUserId,
      packageID: packageID,
      allowedSection: allowedSection,
      description: "",
      newPackage: newPackage,
      postedSection: postedSection,
      isLoading: false,
      openApprovalModal: false,
      openModal: false,
      popupTitle: "",
      popupMsg: "",
      isFormEdited: false,
      rowID: "",
    };
  }

  componentDidMount() {
    let posted = this.state.postedSection.includes("terms");
    if (!this.state.newPackage || posted) {
      this.getTerms();
    }
  }

  getTerms = async () => {
    const { packageID } = this.state;
    Axios.get(`${UopUrl}/package/getterms?id=${packageID}`, fizoheader)
      .then((res) => {
        if (res) {
          console.log(res);
          this.setState({
            description: res.data[0].description,
            rowID: res.data[0].id,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  handlePost = async (showPopup = true) => {
    try {
      this.setState({ isLoading: true, isFormEdited: false });
      const { rowID, CUserId, packageID, description, newPackage } = this.state;
      const packageDetails = {
        id: rowID,
        creator_id: CUserId,
        creator_package_id: packageID,
        description,
      };

      const url = newPackage
        ? `${UopUrl}/package/termsconditions`
        : `${UopUrl}/package/termsconditions/update`;
      const res = await Axios({
        method: newPackage ? "post" : "put",
        url,
        data: packageDetails,
        headers: fizoheader["headers"],
      });

      if (res.data) {
        if (showPopup) {
          this.setState({ isLoading: false });
          this.setState({
            openModal: true,
            popupTitle: "Success",
            popupMsg: "Posted Successfully",
          });
        }
        return true;
      } else {
        this.setState({
          openModal: true,
          popupTitle: "Failed",
          popupMsg: "Failed to save",
        });
        return false;
      }
    } catch (err) {
      console.error(err);
      this.setState({
        isLoading: false,
        openModal: true,
        popupTitle: "Error",
        popupMsg: "An error occurred",
      });
      return false;
    }
  };

  handleApproval = async () => {
    try {
      this.setState({ isLoading: true });
      const posted = await this.handlePost(false);
      if (posted) {
        const { CUserId, packageID } = this.state;
        const formData = new FormData();
        formData.append("creator_id", CUserId);
        formData.append("package_id", base64.encode(packageID));

        const res = await Axios.post(
          `${apiUrl}package/sendtoapproval`,
          formData,
          fizoheader
        );
        console.log(res);
        this.setState({
          openApprovalModal: true,
          isLoading: false,
          isFormEdited: false,
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        isLoading: false,
        openModal: true,
        popupTitle: "Error",
        popupMsg: "An error occurred during approval",
      });
    }
  };

  handleEditorChange = (event, editor) => {
    var data = editor.getData();
    this.setState({ description: data, isFormEdited: true });
  };

  handleClose = () => {
    var currentpage = localStorage.getItem("currentpage");
    if (currentpage !== "") {
      if (currentpage === "creator-myproducts") {
        this.props.history.push("/myaccount/creator-myproducts");
      } else if (currentpage === "dashboard") {
        this.props.history.push("/myaccount/dashboard");
      }
    }
  };

  render() {
    const { allowedSection } = this.state;

    return (
      <section className="main-blue-bg">
        <div className="container-full">
          {this.state?.isLoading && (
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          )}
          <SubHeader
            triggerAction={this.handlePost}
            allowedSection={allowedSection}
            stateValues={this.props?.location?.state}
            finalPage={true}
            handleApproval={this.handleApproval}
          />
          <div className="terms-main">
            <div className="terms-main__title">
              Write your Terms & Conditions and Cancellation & Refund Policy
            </div>
            <CustomEditor
              value={this.state.description}
              onChange={this.handleEditorChange}
            />
          </div>
        </div>
        <ModalPopup
          type="success"
          title="Congrats"
          message="Your Product has been submitted successfully. please give us time to verify"
          open={this.state.openApprovalModal}
          handleClose={this.handleClose}
          // handleChange={handleChange}
          // handleSubmit={handleAuth}
        />
        <ModalPopup
          type="success"
          title={this.state.popupTitle}
          message={this.state.popupMsg}
          open={this.state.openModal}
          handleClose={() => this.setState({ openModal: false })}
          // handleChange={handleChange}
          // handleSubmit={handleAuth}
        />
        <UnsavedChangesWarning
          message="You have unsaved changes. Are you sure you want to leave?"
          isFormEdited={this.state.isFormEdited}
        />
      </section>
    );
  }
}

const UnsavedChangesWarning = ({ message, isFormEdited }) => {
  useUnsavedChangesWarning(message, isFormEdited);
  return null;
};

export default Terms;
