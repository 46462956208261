import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

const useUnsavedChangesWarning = (message, isFormEdited) => {
  const history = useHistory();
  const isFormEditedRef = useRef(isFormEdited);
  // console.log("isFormEdited", isFormEdited, isFormEditedRef);
  useEffect(() => {
    isFormEditedRef.current = isFormEdited;
  }, [isFormEdited]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormEditedRef.current) {
        const confirmationMessage =
          message ||
          "You have unsaved changes. Are you sure you want to leave?";
        e.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage; // For some browsers
      }
    };

    const unblock = history.block((location, action) => {
      if (isFormEditedRef.current) {
        if (window.confirm(message)) {
          unblock();
          history.push(location.pathname);
        } else {
          return false;
        }
      }
    });

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unblock();
    };
  }, [message, history]);

  return null;
};

export default useUnsavedChangesWarning;
