/* eslint-disable */
import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import {
    siteUrl,
    googleAppId,
    googleAppIduser,
} from "../Helpers/Config";
import {
    GET_GOOGLELOGINDATA,
} from "../../actions";
var qs = require("qs");

class GoogleloginComponent extends Component {
    constructor(props) {
        super(props);
        /*         console.log(this.props, "GoogleloginComponent") */
    }

    componentDidMount() {
        var currentNew = this;
        google.accounts.id.initialize({
            client_id: currentNew.props.logintype === "creator"
                ? googleAppId
                : googleAppIduser,
            callback: (data) => this.handleCallbackResponse(currentNew, data),
        });

        google.accounts.id.renderButton(document.getElementById(currentNew.props.divid), {
            theme: "outline",
            size: "large",
        });
    }

    handleCallbackResponse = (cuttentthis, response) => {
        if (
            response.credential !== "" &&
            typeof response.credential !== undefined &&
            typeof response.credential !== "undefined"
        ) {
            var profile = jwt_decode(response.credential);
            var resultRes = {
                name: profile.given_name,
                email: profile.email,
                picture: profile.picture,
                id: profile.sub,
            };
            if (cuttentthis.props.logintype === "creator") {
                cuttentthis.props.creatorloginGoogle(resultRes);
            } else if (cuttentthis.props.logintype === "user") {
                cuttentthis.props.loginGoogle(resultRes);
            }
        }
    }

    handleGoogle = (response) => {
        if (currentNew.props.logintype === "creator") {
            this.props.creatorloginGoogle(response);
        } else if (currentNew.props.logintype === "user") {
            this.props.loginGoogle(response);
        }
    }




    render() {
        return (

            <GoogleLogin
                clientId={this.props.logintype === "creator"
                    ? googleAppId
                    : googleAppIduser}
                fields="name,email,picture,first_name,last_name,birthday,gender"
                callback={this.handleGoogle}
                scope="public_profile,email,user_birthday"
                cssClassName="fa fa-Google"
                redirectUri={siteUrl}
                icon="fa-google"
                textButton="Sign in with Google"
                onSuccess={(credentialResponse) => {
                    console.log(credentialResponse);
                }}
                onError={() => {
                    console.log("Login Failed");
                }}
            />

        );
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleloginComponent);
