/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MYSTIFLYCHEAPESTREVALIDATE,
  SET_MYSTIFLYCHEAPESTREVALIDATE,
} from "../actions";
import { apiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetMystiflyCheapestRevalidate = function* () {
  yield takeEvery(
    GET_MYSTIFLYCHEAPESTREVALIDATE,
    workerGetMystiflyCheapestRevalidate
  );
};

function* workerGetMystiflyCheapestRevalidate({ formPayload }) {
  try {
    const result = yield call(getMystiflyCheapestRevalidate, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_MYSTIFLYCHEAPESTREVALIDATE, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getMystiflyCheapestRevalidate(formPayload) {
  return Axios.post(
    apiUrl + "package/revalidatePrice",
    formPayload,
    fizoheader
  );
}
