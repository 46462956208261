/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import axios from "axios";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import Select from "react-select";
import actinfo1 from "../../common/images/act-info1.jpg";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heart-fill.svg";
import car from "../../common/images/car-icon.svg";
import ticketact from "../../common/images/ticket-activity.svg";
import hotel from "../../common/images/hotel-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import plus from "../../common/images/plus.svg";
import bpin from "../../common/images/map-pin.svg";
import bprofile from "../../common/images/banner-profile.svg";
import bcalender from "../../common/images/calender-banner.svg";
import edit_pencile from "../../common/images/edit_pencile.png";
var qs = require("qs");
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Topmenu from "../Layout/Topmenu";
import Autocomplete from "../Layout/Autocomplete";
import { apiUrl, fizoheader, siteUrl } from "../Helpers/Config";
import { showLoader, hideLoader, stripslashes } from "../Helpers/SettingHelper";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
var base64 = require("base-64");
var trendoption = {
  items: 3,
  loop: true,
  dots: true,
  nav: false,
  margin: 25,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      margin: 10,
      stagePadding: 20,
    },
    580: {
      items: 2,
      margin: 20,
      stagePadding: 30,
    },
    980: {
      items: 3,
      stagePadding: 40,
    },
  },
};

class Package extends Component {
  constructor(props) {
    super(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    var selectedCities = [];
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.selectedCities !== undefined) {
        selectedCities = this.props.location.state.selectedCities;
      }
    }

    var selectedStatuses = [];
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.selectedStatuses !== undefined) {
        selectedStatuses = this.props.location.state.selectedStatuses;
      }
    }

    this.state = {
      CUserId: CUserId,
      airport: [],
      selectedairport: [],
      numberofdays: "",
      whomgoing: [],
      address: "",
      packagecountry: "",
      packageCity: "",
      pacakgeLat: "",
      packageLon: "",
      error_address: false,
      error_numberofdays: false,
      error_whomgoing: false,
      error_selectedairport: false,
      packageLoading: true,
      cityList: [],
      selectedpackagecity: [],
      airport_id: "",
      isOpen: false,
      packageList: [],
      selectedCities: selectedCities,
      selectedStatuses: selectedStatuses,
      share_url: "",
      country_List: {},
    };
  }
  componentDidMount() {
    this.loadCity();
    this.loadPackage();
  }
  loadPackage() {
    showLoader("package-table", "class");
    axios
      .get(
        apiUrl + "package/listPackage?creator_id=" + this.state.CUserId,
        fizoheader
      )
      .then((res) => {
        hideLoader("package-table", "class");
        this.setState({ packageLoading: false });
        if (res.data.status === "ok") {
          this.setState({
            packageList: res.data.result_set,
          });
          this.displayPackage(
            res.data.result_set,
            this.state.selectedCities,
            this.state.selectedStatuses
          );
        }
      });
  }
  loadAirport() {
    axios
      .get(
        apiUrl + "settings/getAirport?country=" + this.state.packagecountry,
        fizoheader
      )
      .then((res) => {
        var airport = [];
        if (res.data.status === "ok") {
          res.data.result_set.map((item) => {
            airport.push({
              value: item.airport_id,
              label: item.airport_name + " - " + item.airport_code,
            });
          });
          this.setState({ airport: airport });
        }
      });
  }
  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value, [name + "_error"]: "" });
  }
  onChange(name, value) {
    console.log(name, value, "valuevalue");
    if (name === "selectedpackagecity") {
      var splitValue = value.value.split("~");
      console.log(splitValue, "splitValuesplitValue");
      this.setState({
        [name]: value,
        address: splitValue[0],
        packagecountry: splitValue[10],
        pacakgeLat: splitValue[3],
        packageLon: splitValue[4],
        packageCity: splitValue[8],
        airport_id: splitValue[2],
      });
    } else {
      this.setState({ [name]: value });
    }
  }
  openPopup() {
    $.magnificPopup.open({
      items: {
        src: "#createpackage",
      },
      type: "inline",
      midClick: true,
    });
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "address") {
      if (value !== "") {
        var address = $("#pac-input").val();
        var addressdetails = value.split("~~");
        this.setState(
          {
            address: address,
            packagecountry: addressdetails[0],
            pacakgeLat: addressdetails[1],
            packageLon: addressdetails[2],
            packageCity: addressdetails[3],
          },
          function () {
            if (this.state.packagecountry !== "") {
              this.loadAirport();
            }
          }
        );
      }
    }
    if (field === "cityList" && value !== "") {
      var cityList = [];
      if (Object.keys(value.result_set).length > 0) {
        Object.keys(value.result_set).map((item) => {
          var selectedCountry = value.result_set[item];
          selectedCountry.city.map((city) => {
            cityList.push({
              label: city.city_name + ", " + city.country_name,
              value:
                city.airport_address +
                "~" +
                city.airport_code +
                "~" +
                city.airport_id +
                "~" +
                city.airport_latitude +
                "~" +
                city.airport_longitude +
                "~" +
                city.airport_name +
                "~" +
                city.city_code +
                "~" +
                city.city_id +
                "~" +
                city.city_name +
                "~" +
                city.country_code +
                "~" +
                city.country_name +
                "~" +
                city.webbeds_city_code,
            });
          });
        });
      }
      this.setState(
        {
          cityList: cityList,
        },
        function () {}
      );
    }
  };
  displayPackage(packageList, cityFilter, statusFilter) {
    $(".scroll-new").mCustomScrollbar("destroy");
    if (cityFilter.length > 0) {
      packageList = packageList.filter((item) =>
        cityFilter.includes(item.creator_package_city)
      );
    }
    if (statusFilter.length > 0) {
      packageList = packageList.filter((item) =>
        statusFilter.includes(item.creator_package_status)
      );
    }
    var displayPackage = "";
    console.log("dp", packageList);
    if (packageList.length > 0) {
      displayPackage = packageList.map((item, index) => {
        return (
          <div className="inner-ff" key={index}>
            <div className="action-cell">
              <a href={void 0} onClick={() => this.editPackage(item)}>
                <img src={edit_pencile} alt="editpencile" />
              </a>
            </div>
            <div className="package-cell">
              {stripslashes(item.creator_package_name)}
            </div>
            <div className="dest-cell">{item.creator_package_city}</div>
            <div className="clicks-cell">
              {item.creator_package_total_click}
            </div>
            <div className="bookings-count-cell">
              {item.package_booking_count}
            </div>
            <div className="booking-cell">{item.booking_rate}%</div>
            <div className="commission-cell">SGD ${item.booking_revenue}</div>
            <div className="rating-cell">
              {item.creator_package_rating !== "" &&
              item.creator_package_rating !== null
                ? item.creator_package_rating
                : 0}
            </div>
            <div className="status-cell">{item.creator_package_status}</div>
            <div className="action-cell">
              <a
                href={void 0}
                onClick={this.openPopupshare.bind(this, item, "#share-popup")}
              >
                share
              </a>
            </div>
          </div>
        );
      });
    }
    this.setState({ displayPackage: displayPackage }, function () {
      if ($(".scroll-new").length > 0) {
        $(".scroll-new").mCustomScrollbar();
      }
    });
  }

  editPackage = (item) => {
    var filterDetails = {
      selectedCities: this.state.selectedCities,
      selectedStatuses: this.state.selectedStatuses,
    };
    this.props.history.push({
      pathname: "/edit-package/" + item.creator_package_id,
      state: filterDetails,
    });
  };

  continuePackage() {
    var error = 0;
    if (this.state.address === "") {
      error++;
      this.setState({ error_address: true });
    }
    if (
      this.state.numberofdays === "" ||
      !/^[0-9]*$/.test(this.state.numberofdays)
    ) {
      error++;
      this.setState({ error_numberofdays: true });
    } else {
      const numberOfDays = parseInt(this.state.numberofdays, 10);

      if (numberOfDays < 2 || numberOfDays > 15) {
        error++;
        this.setState({ error_numberofdays: true });
      } else {
        this.setState({ error_numberofdays: false });
      }
    }
    if (Object.keys(this.state.whomgoing).length === 0) {
      error++;
      this.setState({ error_whomgoing: true });
    }
    if (error === 0) {
      var packagedetails = {
        address: this.state.address,
        packagecountry: this.state.packagecountry,
        packageCity: this.state.packageCity,
        pacakgeLat: this.state.pacakgeLat,
        packageLon: this.state.packageLon,
        numberofdays: this.state.numberofdays,
        whomgoing: this.state.whomgoing,
        selectedairport: { value: this.state.airport_id },
        newpackage: "newpackage",
      };
      $.magnificPopup.close();
      this.props.history.push({
        pathname: "/myaccount/create-package",
        state: packagedetails,
      });
    }
  }

  openfilter() {
    $.magnificPopup.open({
      items: {
        src: "#filter-stick-popup",
      },
      type: "inline",
    });
  }

  handleCityChange = (event) => {
    const { value } = event.target;
    this.setState(
      {
        selectedCities: [...this.state.selectedCities, value],
      },
      () => {
        console.log("cityFilter", this.state.selectedCities);
      }
    );
  };

  handleStatusChange = (event) => {
    const { value } = event.target;
    this.setState(
      {
        selectedStatuses: [...this.state.selectedStatuses, value],
      },
      () => {
        console.log("statusFilter", this.state.selectedStatuses);
      }
    );
  };

  handleApplyFilter() {
    var cityFilter = this.state.selectedCities;
    var statusFilter = this.state.selectedStatuses;
    var packageList = this.state.packageList;
    this.displayPackage(packageList, cityFilter, statusFilter);
    $.magnificPopup.close();
  }

  handleClearAll = () => {
    var packageList = this.state.packageList;
    this.setState({ selectedCities: [], selectedStatuses: [] }, () => {
      var cityFilter = this.state.selectedCities;
      var statusFilter = this.state.selectedStatuses;
      this.displayPackage(packageList, cityFilter, statusFilter);
      $.magnificPopup.close();
    });
  };
  openPopupshare(item, popupID) {
    console.log(item, "item item");
    var selected_City = "";
    Object.values(this.state.country_List).forEach((country) => {
      var countryDetails = Object.values(country);
      if (countryDetails[0] === item.creator_package_country_name) {
        countryDetails[1].map((city) => {
          if (city.city_name === item.creator_package_city) {
            selected_City = city;
          }
        });
      }
    });
    var packageSlug = item.creator_package_name
      .replace(/[^a-zA-Z0-9]+/g, "-")
      .toLowerCase();

    var postObject = {
      package_slug: packageSlug,
      details: {
        adults: 2,
        airportLat: selected_City.airport_latitude,
        airportLon: selected_City.airport_longitude,
        checkinTime: "",
        child: "",
        destinationCity: selected_City.city_name,
        destinationCountry: selected_City.country_name,
        destinationLat: selected_City.airport_latitude,
        destinationLon: selected_City.airport_longitude,
        destinationWebbedsCity: selected_City.webbeds_city_code,
        destination_airport_address: selected_City.airport_address,
        destination_airport_code: selected_City.airport_code,
        destination_airport_id: selected_City.airport_id,
        destination_airport_lat: selected_City.airport_latitude,
        destination_airport_lon: selected_City.airport_longitude,
        end_date: "",
        images: item.creator_package_gallery,
        infant: "",
        location_destination: item.creator_package_location,
        location_source:
          "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka 560300, India",
        room_count: 1,
        search_days: "2-5",
        sourceCity: "",
        sourceCountry: "India",
        sourceLat: "12.994112",
        sourceLon: "80.1708668",
        source_airport_address: "BLR",
        source_airport_code: "BLR",
        source_airport_id: "2800",
        source_airport_lat: "12.994112",
        source_airport_lon: "80.1708668",
        start_date: new Date(),
        thumbnail: item.creator_package_thumbnail,
        transfer_trip_type: "Return",
        trip_type: "Return",
      },
      share_type: "creator",
      creator_package_id: base64.decode(item.creator_package_id),
    };

    console.log(postObject, "postObject");
    axios
      .post(apiUrl + "customer/userShare", qs.stringify(postObject), fizoheader)
      .then((res) => {
        if (res.data.status === "ok") {
          console.log(res.data, "res.data");
          this.setState(
            {
              share_id: res.data.result_set.share_id,
              share_url: `${siteUrl}search-package/${packageSlug}/creatorshare?share_id=${res.data.result_set.share_id}`,
            },
            function () {
              console.log(this.state.share_url, "share_url");
            }
          );
        }
      });
    $("#close_mobile_menu").trigger("click");
    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
      midClick: true,
    });
  }
  handlePinterestShare() {
    const shareLink = this.state.share_url;
    const pinterestUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      shareLink
    )}`;
    window.open(pinterestUrl, "_blank");
  }
  handleWhatsAppShare() {
    const shareLink = this.state.share_url;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareLink
    )}`;
    window.open(whatsappUrl, "_blank");
  }
  handleCopyToClipboard() {
    const shareLink = this.state.share_url;
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        NotificationManager.success(
          "Your account is logged in as creator, please logout and continue with the copied URL"
        );
      })
      .catch((err) => {
        console.error("Unable to copy link to clipboard", err);
      });
  }
  handleEmailShare() {
    const subject = "Check out this amazing website!";
    const shareLink = this.state.share_url;
    const body = `Hi,\n\nI thought you might be interested in this website I found. It has great content and features. Check it out:\n\n${shareLink}\n\nBest regards, [Your Name]`;
    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = emailUrl;
  }
  handleFacebookShare() {
    console.log("calling");
    var facebookAppId = fbAppId;
    var shareLink = this.state.share_url;
    window.fbAsyncInit = function () {
      FB.init({
        appId: facebookAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v12.0",
      });

      FB.ui({
        method: "share",
        href: shareLink,
      })
        .then((response) => {
          console.log("Facebook share response:", response);
        })
        .catch((error) => {
          console.error("Error during Facebook share:", error);
        });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  handleTwitterShare() {
    const shareText = "Check out this amazing website!";
    const shareLink = this.state.share_url;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareText
    )}&url=${encodeURIComponent(shareLink)}`;
    window.open(twitterUrl, "_blank");
  }
  loadCity = async () => {
    try {
      await axios
        .get(apiUrl + "settings/getCityList", fizoheader)
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({
              country_List: res.data.result_set,
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    var packageList = this.state.packageList;
    const cities = [
      ...new Set(packageList.map((item) => item.creator_package_city)),
    ].filter((city) => city.length > 0);
    return (
      <>
        <div className="creator-board-rhs cx-box">
          <div className="package-tools">
            <div className="pt-inner">
              <div className="pt-dropdown">
                <div>
                  <a
                    href={void 0}
                    onClick={this.openfilter.bind(this)}
                    className="button"
                  >
                    Filters
                  </a>
                </div>
              </div>
            </div>

            <div className="pt-button">
              <a
                href={void 0}
                onClick={this.openPopup.bind(this)}
                className="button"
              >
                <img src={plus} alt="" /> Create new package
              </a>
            </div>
          </div>
          <div className="package-table">
            <div className="ctable-row ctable-row-head">
              <div className="action-cell"></div>
              <div className="package-cell">Package</div>
              <div className="dest-cell">Destination</div>
              <div className="clicks-cell">Clicks</div>
              <div className="clicks-cell">Bookings</div>
              <div className="booking-cell">Booking rate</div>
              <div className="commission-cell">Revenue</div>
              <div className="rating-cell">Rating</div>
              <div className="status-cell">Status</div>
            </div>
            {this.state.displayPackage !== undefined ? (
              <div className="ctable-row ctable-scroll scroll-new">
                {this.state.displayPackage}
              </div>
            ) : (
              <div>
                <p>No Packages Found</p>
              </div>
            )}
          </div>
        </div>
        <div id="createpackage" className="mfp-hide common-popup">
          <div className="step-banner-form">
            <h3 className="textcenter color-header">
              Create unique <span>vacation</span>
            </h3>
            <div className="step-banner-whom step-banner-stay">
              <Select
                value={this.state.selectedpackagecity}
                placeholder="Select Package City"
                onChange={this.onChange.bind(this, "selectedpackagecity")}
                options={this.state.cityList}
              />
              {/*  <Autocomplete
                sateValChange={this.sateValChange}
                placeholder="Enter your perfect destination."
              /> */}
              <img src={bpin} />
              {this.state.error_address === true && (
                <div className="error">This field is required</div>
              )}
            </div>
            <div className="step-banner-whom create-whom">
              <input
                type="text"
                placeholder="Number of days"
                value={this.state.numberofdays}
                name="numberofdays"
                onChange={this.handleChange.bind(this)}
              />
              <img src={bcalender} />
              {this.state.error_numberofdays === true && (
                <div className="error">
                  Please enter a number between 2 to 15
                </div>
              )}
            </div>

            <div className="step-banner-whom">
              <Select
                value={this.state.whomgoing}
                placeholder="Whom you are going with?"
                onChange={this.onChange.bind(this, "whomgoing")}
                options={[
                  {
                    value: "Couple",
                    label: "Couple",
                  },
                  {
                    value: "Family",
                    label: "Family",
                  },
                  {
                    value: "Friends",
                    label: "Friends",
                  },
                  {
                    value: "Solo",
                    label: "Solo",
                  },
                ]}
              />
              <img src={bprofile} />
              {this.state.error_whomgoing === true && (
                <div className="error">This field is required</div>
              )}
            </div>
            <button
              type="submit"
              className="button"
              onClick={this.continuePackage.bind(this)}
            >
              Create Packages
            </button>
          </div>
        </div>
        <div
          id="filter-stick-popup"
          className="mfp-hide common-popup filter-stick-popup"
        >
          <div className="cp-header textcenter">
            <h3>Filters</h3>
            <a
              className="date-package-popup"
              href={void 0}
              // onClick={this.closeDate.bind(this)}
            ></a>
          </div>
          <div className="filter-body">
            <div className="filter-row">
              <h5>Destinations</h5>
              {cities.length > 0 && (
                <ul className="filter-row-ul">
                  {cities.map((item, index) => {
                    return (
                      <li key={index}>
                        <input
                          type="checkbox"
                          className="tag_filter"
                          id={"city" + index}
                          value={item}
                          onChange={(event) => {
                            this.handleCityChange(event);
                          }}
                        />
                        <label htmlFor={"city_" + index}>{item}</label>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="filter-row">
              <h5>Status</h5>

              <ul className="filter-row-ul">
                <li>
                  <input
                    type="checkbox"
                    className="tag_filter"
                    id={"Pending"}
                    value="Pending"
                    onChange={(event) => {
                      this.handleStatusChange(event);
                    }}
                  />
                  <label htmlFor={"status_"}>Pending</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    className="tag_filter"
                    id={"Active"}
                    value="Active"
                    onChange={(event) => {
                      this.handleStatusChange(event);
                    }}
                  />
                  <label htmlFor={"status_"}>Active</label>
                </li>
              </ul>
            </div>
          </div>
          <div className="filter-action">
            <a
              href={void 0}
              className="button"
              onClick={this.handleApplyFilter.bind(this)}
            >
              {" "}
              Apply
            </a>
            <a
              href={void 0}
              className="button ghost-button"
              onClick={this.handleClearAll}
            >
              {" "}
              Clear All
            </a>
          </div>
        </div>

        <div id="share-popup" className="mfp-hide common-popup">
          <p>Share via:</p>{" "}
          <div className="share-options">
            <IconButton onClick={this.handleEmailShare.bind(this)}>
              <EmailIcon />
            </IconButton>
            {/* <IconButton onClick={this.handleFacebookShare.bind(this)}>
            <FacebookIcon />
          </IconButton> */}
            <IconButton onClick={this.handleWhatsAppShare.bind(this)}>
              <WhatsAppIcon />
            </IconButton>
            <IconButton onClick={this.handleTwitterShare.bind(this)}>
              <TwitterIcon />
            </IconButton>
            <IconButton onClick={this.handlePinterestShare.bind(this)}>
              <PinterestIcon />
            </IconButton>
            <IconButton onClick={this.handleCopyToClipboard.bind(this)}>
              <LinkIcon />
            </IconButton>
          </div>
        </div>
        <NotificationContainer />
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Package);
