/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_FORGET_PASSWORD, SET_FORGET_PASSWORD } from "../actions";
import { userapiUrl, fizoheader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetForgetPassword = function* () {
  yield takeEvery(GET_FORGET_PASSWORD, workerGetForgetPassword);
};

function* workerGetForgetPassword({ formPayload, forgottype }) {
  try {
    const result = yield call(getForgetPassword, formPayload, forgottype);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FORGET_PASSWORD, value: resultArr });
  } catch {
    console.log("failed");
  }
}

function getForgetPassword(formPayload, forgottype) {
  return Axios.post(
    userapiUrl + forgottype + "/forgot_password",
    formPayload,
    fizoheader
  );
}
